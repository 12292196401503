import { Err } from "@uuip/unified-ui-platform-sdk";
import { AqmReqs, Res, ResEmpty } from "../../core/aqm-reqs";
import { Service, getRoutingHost } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import Agent = Service.Aqm.Agent;

/*
 * routingAgent
 * @param reqs
 * @category Routing Service
 */
interface ReloadRequestReturnType {
  reload: ResEmpty<Service.Aqm.Agent.ReloginSuccess>;
}

interface LogoutRequestReturnType {
  logout: Res<Service.Aqm.Agent.LogoutSuccess, { data: Agent.Logout }>;
}

interface StationLoginRequestReturnType {
  stationLogin: Res<Service.Aqm.Agent.StationLoginSuccess, { data: Agent.UserStationLogin }>;
}

interface StateChangeRequestReturnType {
  stateChange: Res<Service.Aqm.Agent.StateChangeSuccess, { data: Agent.StateChange }>;
}

type RoutingAgentReturnType = Partial<
  ReloadRequestReturnType & LogoutRequestReturnType & StationLoginRequestReturnType & StateChangeRequestReturnType
>;

export function routingAgent(
  routing: AqmReqs,
  featureFlags: Service.Aqm.Global.agentRouteFeatureFlag
): RoutingAgentReturnType {
  return {
    ...(featureFlags.isESDRoutingApisEnabled
      ? {
          reload: routing.reqEmpty(() => ({
            host: getRoutingHost(),
            url: "/agents/reload",
            data: {},
            err,
            notifSuccess: {
              bind: {
                type: "AgentReloginSuccess",
                data: { type: "AgentReloginSuccess" }
              },
              msg: {} as Agent.ReloginSuccess
            },
            notifFail: {
              bind: {
                type: "AgentReloginFailed",
                data: { type: "AgentReloginFailed" }
              },
              errId: "Service.aqm.agent.reload"
            }
          })),
          logout: routing.req((p: { data: Agent.Logout }) => ({
            url: "/agents/logout",
            host: getRoutingHost(),
            data: p.data,
            err,
            notifSuccess: {
              bind: {
                type: "Logout",
                data: { type: "AgentLogoutSuccess" }
              },
              msg: {} as Agent.LogoutSuccess
            },
            notifFail: {
              bind: {
                type: "Logout",
                data: { type: "AgentLogoutFailed" }
              },
              errId: "Service.aqm.agent.logout"
            }
          })),
          stationLogin: routing.req((p: { data: Agent.UserStationLogin }) => ({
            url: "/agents/login",
            host: getRoutingHost(),
            data: p.data,
            err: /* istanbul ignore next */ (e: any) =>
              new Err.Details("Service.aqm.agent.stationLogin", {
                status: e.response?.status ?? 0,
                type: e.response?.data?.errorType,
                trackingId: e.response?.headers?.trackingid?.split("_")[1]
              }),
            notifSuccess: {
              bind: {
                type: "StationLogin",
                data: { type: "AgentStationLoginSuccess" }
              },
              msg: {} as Agent.StationLoginSuccess
            },
            notifFail: {
              bind: {
                type: "StationLogin",
                data: { type: "AgentStationLoginFailed" }
              },
              errId: "Service.aqm.agent.stationLoginFailed"
            }
          }))
        }
      : {}),
    ...(featureFlags.desktopEOLStateChange
      ? {
          stateChange: routing.req((p: { data: Agent.StateChange }) => ({
            url: "/agents/session/state",
            host: getRoutingHost(),
            data: { ...p.data, auxCodeId: p.data.auxCodeIdArray },
            err,
            method: "put",
            notifSuccess: {
              bind: {
                type: "AgentStateChange",
                data: { type: "AgentStateChangeSuccess" }
              },
              msg: {} as Agent.StateChangeSuccess
            },
            notifFail: {
              bind: {
                type: "AgentStateChange",
                data: { type: "AgentStateChangeFailed" }
              },
              errId: "Service.aqm.agent.stateChange"
            }
          }))
        }
      : {})
  };
}
