/* eslint-disable @typescript-eslint/no-explicit-any */
import { Signal } from "@uuip/unified-ui-platform-sdk";
import {
  CLOSE_SOCKET_TIMEOUT_DURATION,
  CONF,
  KEEPALIVE_WORKER_INTERVAL,
  METHOD_NAME,
  NOTIFS_RESOLVE_DELAY,
  PING_API_URL
} from "../config";
import { SERVICE, Service } from "../index";
import { RtdConstants } from "../services/constant";
import { checkOnlineStatus } from "../services/service-utils";
import { http, logger } from "./sdk";

export class AqmNotifs {
  readonly onMessage: Signal.WithData<string>;
  private readonly onMessageSend: Signal.Send<string>;
  readonly onSocketClose: Signal.Empty;
  private readonly onSocketCloseSend: Signal.SendEmpty;
  private websocket: WebSocket;
  shouldReconnect: boolean;
  isSocketClosed: boolean;
  private isWelcomeReceived: boolean;
  private welcomeTimeout: number;
  private startWelcomeTime: number;
  private endWelcomeTime: number;
  private notifsWelcomeFlag: boolean;
  private url: string | null = null;
  private forceCloseWebSocketOnTimeout: boolean;
  private isConnectionLost: boolean;
  keepaliveWorker = new Worker("/keepalive.worker.js", { type: "module" });

  constructor() {
    const { send, signal } = Signal.create.withData<string>();
    this.onMessage = signal;
    this.onMessageSend = send;

    const socketCloseSignal = Signal.create.empty();
    this.onSocketClose = socketCloseSignal.signal;
    this.onSocketCloseSend = socketCloseSignal.send;
    this.shouldReconnect = true;
    this.websocket = {} as WebSocket;
    this.isSocketClosed = false;
    this.isWelcomeReceived = false;
    this.welcomeTimeout = parseInt(CONF.WELCOME_TIMEOUT ?? 7000); //Adding a wait time of 7sec for Welcome message since CORE has max retry timeout of 6sec today.
    this.startWelcomeTime = 0;
    this.endWelcomeTime = 0;
    this.notifsWelcomeFlag = SERVICE.featureflag.isDesktopConsumeWelcomeEnabled();
    this.forceCloseWebSocketOnTimeout = false;
    this.isConnectionLost = false;
  }

  async init() {
    await this.register();
    await this.connect().catch(() => {
      logger.error(`[WebSocketStatus] | Error in connecting Websocket`);
    });
    SERVICE.aqm.connectionConfig.OnConnectionLost.listen(this.handleConnectionLost);
  }

  private handleConnectionLost = (detail: Service.Aqm.Connection.ConnectionLostDetails) => {
    this.isConnectionLost = detail.isConnectionLost;
  };

  private startTrackWebSocket = () => {
    SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.WEB_SOCKET_DISCONNECT, [
      SERVICE.telemetry.SERVICE_PROVIDERS.prometheus
    ]);
  };
  private dispatchEventForRTD = (isConnected: boolean) => {
    window.dispatchEvent(
      new CustomEvent(RtdConstants.RTD_PING_EVENT, {
        detail: isConnected,
        bubbles: true,
        composed: true
      })
    );
  };

  private trackWebSocketDisconnect = (reason: string): void => {
    SERVICE.telemetry.track(
      SERVICE.telemetry.MIX_EVENT.WEB_SOCKET_DISCONNECT,
      {
        Reason: reason
      },
      [SERVICE.telemetry.SERVICE_PROVIDERS.prometheus]
    );
  };

  close(shouldReconnect: boolean, reason = "Unknown") {
    if (!this.isSocketClosed && this.shouldReconnect) {
      this.shouldReconnect = shouldReconnect;
      this.websocket.close();
      this.keepaliveWorker.postMessage({ type: "terminate" });

      logger.error(`[WebSocketStatus] | event=webSocketClose | WebSocket connection closed manually REASON: ${reason}`);
      this.trackWebSocketDisconnect(`WebSocketCloseManual - ${reason}`);
    }
  }

  private async register() {
    const canaryHeader = { "X-ORGANIZATION-ID": SERVICE.featureflag.orgId };
    try {
      const res = await http.post(
        `${CONF.NOTIF_HOST}${
          SERVICE.featureflag.isDesktopAqmEolCX11920Enabled() ? "/v1/notification/subscribe" : "/register"
        }`,
        {
          force: true,
          webexCcStack: true,
          isKeepAliveEnabled: true
        },
        {
          headers: {
            ...canaryHeader
          }
        }
      );
      this.url = res.data.webSocketUrl as string;
      logger.info("event=socketRegistrationSuccess | WebSocket URL : ", this.url);
    } catch (e) {
      logger.error("Register API Failed", e);
    }
  }

  getOnlineStatus = async () => {
    let onlineStatus;
    if (SERVICE.featureflag.isNetworkCheckPingAPIEnabled()) {
      onlineStatus = await checkOnlineStatus();
    } else {
      onlineStatus = navigator.onLine;
    }
    return onlineStatus;
  };

  webSocketOnCloseHandler = async (event: any, isNetworkCheckPingAPIEnabledFF: boolean) => {
    this.isSocketClosed = true;
    this.keepaliveWorker.postMessage({ type: "terminate" });
    if (isNetworkCheckPingAPIEnabledFF) {
      this.dispatchEventForRTD(false);
    }
    if (this.shouldReconnect) {
      this.onSocketCloseSend();
      let issueReason;
      if (this.forceCloseWebSocketOnTimeout) {
        issueReason = "WebSocket auto close timed out. Forcefully closed websocket.";
      } else {
        const onlineStatus = await this.getOnlineStatus();
        issueReason = !onlineStatus ? "network issue" : "missing keepalive from either desktop or notif service";
      }

      this.trackWebSocketDisconnect(issueReason);
      /* eslint-disable-next-line max-len */
      logger.error(
        `[WebSocketStatus] | event=socketConnectionClosed | WebSocket connection closed due to ${issueReason}`
      );
      logger.error(
        `[WebSocketStatus] | event=socketConnectionClosedDetails | Code: ${event?.code} Reason:${event?.reason} wasClean: ${event?.wasClean}`,
        event
      );
      this.forceCloseWebSocketOnTimeout = false;
    }
  };

  private async connect() {
    if (!this.url) {
      return undefined;
    }
    this.websocket = new WebSocket(this.url);
    const isNetworkCheckPingAPIEnabledFF = SERVICE.featureflag.isNetworkCheckPingAPIEnabled();
    return new Promise((resolve, reject) => {
      this.websocket.onopen = (event: any) => {
        this.isSocketClosed = false;
        this.shouldReconnect = true;

        // Upon successful websocket connection establishment, send an immediate keepalive message to the notification service.
        // Subsequently, keepalive for every 4 seconds will handled in keepalive.worker.js
        this.websocket.send(JSON.stringify({ keepalive: "true" }));

        logger.info(
          `[WebSocketStatus] | event=socketConnectionEstablished | WebSocket connection established and first { keepalive: 'true' } from Desktop to notifs sent:`,
          event
        );
        this.startWelcomeTime = new Date().getTime();
        this.keepaliveWorker.onmessage = (event: { data: any }) => {
          if (event?.data?.type === "keepalive") {
            this.websocket.send(JSON.stringify({ keepalive: "true" }));
            logger.info(
              `Keepalive from Desktop every 4 sec | { keepalive: 'true' } :: WS status: ${
                this.websocket.readyState
              } :: Desktop is online: ${isNetworkCheckPingAPIEnabledFF ? event?.data?.onlineStatus : navigator.onLine}`
            );
            if (isNetworkCheckPingAPIEnabledFF) {
              this.dispatchEventForRTD(event?.data?.onlineStatus);
            }
          }

          // checking isConnectionLost here, so as to cover a scenario when desktop restores
          // back after socket close event is dispatched
          if (event?.data?.type === "closeSocket" && this.isConnectionLost) {
            this.forceCloseWebSocketOnTimeout = true;
            this.close(true, "WebSocket did not auto close within 16 secs");
            logger.error("[webSocketTimeout] | event=webSocketTimeout | WebSocket connection closed forcefully");
          }
        };
        const isSocketClosed = this.isSocketClosed;
        this.keepaliveWorker.postMessage({
          type: "start",
          intervalDuration: KEEPALIVE_WORKER_INTERVAL,
          isNetworkCheckPingAPIEnabledFF,
          isSocketClosed,
          closeSocketTimeout: CLOSE_SOCKET_TIMEOUT_DURATION,
          networkCheckApiUrl: PING_API_URL,
          methodName: METHOD_NAME
        });

        if (this.notifsWelcomeFlag) {
          //If FF is on, wait 7sec for welcome msg before timeout error
          this.startWelcomeTime = new Date().getTime();
          SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.WELCOME_MESSAGE_RECEIVED);
          setTimeout(() => {
            if (!this.isWelcomeReceived) {
              logger.error(
                `WebSocket registration error | Welcome Message from notifs not received within expected time of ${this.welcomeTimeout} ms`
              );
              reject(new Error("Welcome Message from notifs timed out"));
              SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WELCOME_MESSAGE_RECEIVED, {
                Status: SERVICE.telemetry.MIX_EVENT.STATUS.FAILED
              });
            }
          }, this.welcomeTimeout);
        } else {
          setTimeout(() => {
            logger.info(`Resolving notifs registration with a delay of ${NOTIFS_RESOLVE_DELAY} millisecs`);
            resolve({});
          }, NOTIFS_RESOLVE_DELAY); //Resolving socket connection establishment is delayed by 1.2 secs until  WELCOME message is fied by CORE-4207.
        }
      };

      this.websocket.onerror = (event: any) => {
        logger.error(`[WebSocketStatus] | event=socketConnectionFailed | WebSocket connection failed`, event);
        reject(); // and what ?
      };

      this.websocket.onclose = async (event: any) => {
        this.webSocketOnCloseHandler(event, isNetworkCheckPingAPIEnabledFF);
      };

      this.websocket.onmessage = e => {
        this.onMessageSend(e.data);
        if (this.notifsWelcomeFlag && e.data && JSON.parse(e.data)?.type === "Welcome") {
          this.isWelcomeReceived = true;
          this.endWelcomeTime = new Date().getTime();
          logger.info(`Welcome message received after ${this.endWelcomeTime - this.startWelcomeTime} milliseconds`);
          SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WELCOME_MESSAGE_RECEIVED, {
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
          });
          resolve({}); //if FF is on, notifs is resolved upon receiving Welcome msg
        }
        if (e.data && e.data !== "keepalive" && JSON.parse(e.data)?.type === "AGENT_MULTI_LOGIN") {
          this.close(false, "multiLogin"); // closing websocket on multiLogin.
          logger.error("[WebSocketStatus] | event=agentMultiLogin | WebSocket connection closed by agent multiLogin");
        }
      };
    });
  }
}
