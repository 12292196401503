/* eslint-disable @typescript-eslint/no-explicit-any */
import { Err } from "@uuip/unified-ui-platform-sdk";
import { AxiosError, Method } from "axios";
import { SERVICE } from "..";
import { http, logger } from "./sdk";

const CLIENT_NAME_HEADER = { "x-client-name": "desktop" };
const HTTP_TIMEOUT_REQ = 10000; // default timeout req time in ms
http.interceptors.request.use(config => {
  (config as any).metadata = { startTime: Date.now() };
  return config;
});

http.interceptors.response.use(response => {
  const start = (response.config as any).metadata.startTime;
  logger.info(
    `Http request roundtrip time: ${response.config.url} ${response.config.method?.toUpperCase()} ${Date.now() -
      start}ms`
  );
  return response;
});
export class HttpReqs {
  private readonly host: string;
  private readonly removeDefaultHeader: boolean;

  constructor(host: string, removeDefaultHeader = false) {
    this.host = host;
    this.removeDefaultHeader = removeDefaultHeader;
  }

  req<TRes, TReq>(c: Conf<TRes, TReq>): Res<TRes, TReq> {
    return (p: TReq, cbRes?: CbRes<TRes>) => this.createPromise(c(p), cbRes);
  }

  reqEmpty<TRes>(c: ConfEmpty<TRes>): ResEmpty<TRes> {
    return (cbRes?: CbRes<TRes>) => this.createPromise(c(), cbRes);
  }

  private createPromise<TRes>(c: Req<TRes>, cbRes?: CbRes<TRes>) {
    const canaryHeader = { "X-ORGANIZATION-ID": SERVICE.featureflag.orgId };
    return new Promise<TRes>((resolve, reject) => {
      const headerValue = this.removeDefaultHeader
        ? {
            ...c.headers
          }
        : {
            ...canaryHeader,
            ...c.headers,
            ...CLIENT_NAME_HEADER
          };
      const req = http.request({
        url: this.host + c.url,
        method: c.method ?? (c.data ? "post" : "get"),
        headers: headerValue,
        data: c.data,
        responseType: c.responseType
      });
      let isTimeoutRequired = true;
      /* istanbul ignore next */
      req
        .then((res: any) => {
          if (cbRes) {
            cbRes(res);
          }
          resolve(res.data);
        })
        .catch((axiosErr: AxiosError) => {
          if (axiosErr?.config?.headers) {
            axiosErr.config.headers.Authorization = "*";
          }
          if (axiosErr?.response?.headers) {
            axiosErr.response.headers.Authorization = "*";
          }
          if (typeof c.err == "function") {
            reject(c.err(axiosErr));
          } else if (typeof c.err == "string") {
            reject(new Err.Message(c.err));
          } else {
            reject(new Err.Message("Service.http.reqs.GenericRequestError"));
          }
        })
        .finally(() => {
          isTimeoutRequired = false;
        });
      if (c.timeout !== "disabled") {
        setTimeout(
          () => {
            if (!isTimeoutRequired) {
              return;
            }
            logger.error(`https request timeout for ${c.url} with errId ${c.errId}`);
            reject(new Err.Details("Service.http.reqs.Timeout", { key: c.errId ?? c.url })); //If the errId is not available the request will be rejected with the url info
          },
          c.timeout && c.timeout > 0 ? c.timeout : HTTP_TIMEOUT_REQ
        );
      }
    });
  }
}

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.http.reqs": ReqError;
    }
  }
}

type ReqError = "Service.http.reqs.GenericRequestError" | { "Service.http.reqs.Timeout": { key: string } };

type Req<TRes> = {
  url: string;
  res: TRes;
  err?:
    | ((errObj: AxiosError<any>) => Err.Details<"Service.reqs.generic.failure">)
    | Err.IdsMessage
    | ((e: AxiosError) => Err.Message | Err.Details<Err.IdsDetails>);
  errId?: string;
  data?: any;
  headers?: Record<string, string>;
  method?: Method;
  responseType?: respType;
  timeout?: Timeout;
};

type respType = "arraybuffer" | "stream" | "blob" | "json" | "document" | "text";
type Conf<TRes, TReq> = (p: TReq) => Req<TRes>;
type ConfEmpty<TRes> = () => Req<TRes>;

type Res<TRes, TReq> = (p: TReq, cb?: CbRes<TRes>) => Promise<TRes>;
type ResEmpty<TRes> = (cb?: CbRes<TRes>) => Promise<TRes>;

type CbRes<TRes> = (res: any) => void | TRes;
type Timeout = number | "disabled";
