import { Err } from "@uuip/unified-ui-platform-sdk";
import { AxiosError } from "axios";
import { DateTime } from "luxon";
import { METHOD_NAME, PING_API_URL } from "../config";

const getCommonErrorDetails = (errObj: AxiosError<string>) => {
  return {
    trackingId: errObj?.response?.headers?.["trackingid"] || errObj?.config?.headers?.["TrackingID"],
    msg: errObj?.response?.data
  };
};

export const createErrDetailsObject = (errObj: AxiosError<string>) => {
  const details = getCommonErrorDetails(errObj);
  return new Err.Details("Service.reqs.generic.failure", details);
};

export const handleExternalServiceErrorDetails = (errObj: AxiosError<string>) => {
  const details: { trackingId: string; status?: number } = getCommonErrorDetails(errObj);
  return new Err.Details<any>("Service.reqs.externalService.generic.failure", {
    ...details,
    status: errObj?.response?.status ?? ""
  });
};

export const getCanaryFlagFromSessionStorage = (): boolean => {
  const flag = sessionStorage.getItem("canary");
  return flag === "true" ? true : false;
};

/**
 * Pings an API and checks if API is returning 200 or not
 * In localhost pings GET method and HEAD method otherwise
 * @returns onlineStatus
 */
export const checkOnlineStatus = async function() {
  try {
    const online = await fetch(PING_API_URL, {
      method: METHOD_NAME,
      headers: {
        "cache-control": "no-cache, no-store, must-revalidate"
      }
    });
    return online.status === 200;
  } catch (error) {
    return false;
  }
};
export const generateUUID = (): string => {
  let d = DateTime.utc().toMillis();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
  });
};

export const RETRY_INTERVAL = 200;
export const sleep = (interval: number) => new Promise(resolve => setTimeout(() => resolve(true), interval));
