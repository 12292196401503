import { SERVICE } from "../..";
import { cacheKeys, eventConstants, propsConstants } from "../mixpanel/constants";
import { trackingUtils } from "../mixpanel/mix-panel-tracking";
import { PrometheusConstant } from "../prometheus/constants";

const SERVICE_PROVIDERS = () => {
  return {
    mixpanel: "mixpanel",
    prometheus: "prometheus"
  };
};

export class TelemetryService {
  MIX_EVENT: ReturnType<typeof eventConstants> = eventConstants();
  MIX_PROPS: ReturnType<typeof propsConstants> = propsConstants();
  MIX_CACHE_KEYS: ReturnType<typeof cacheKeys> = cacheKeys();
  MIX_util: ReturnType<typeof trackingUtils> = trackingUtils();
  SERVICE_PROVIDERS: ReturnType<typeof SERVICE_PROVIDERS> = SERVICE_PROVIDERS();
  PRO_EVENT: ReturnType<typeof PrometheusConstant> = PrometheusConstant();

  init(token: string): TelemetryService {
    SERVICE.mixpanel.init(token);
    return this;
  }

  track(name: string, options?: any, services: string[] = []) {
    for (const service of services) {
      switch (service) {
        case this.SERVICE_PROVIDERS.mixpanel: {
          SERVICE.mixpanel.track(name, options);
          break;
        }
        case this.SERVICE_PROVIDERS.prometheus: {
          SERVICE.prometheus.endEvent(name, {
            timestamp: new Date(),
            interaction_id: options.interactionId,
            status: options.Status === SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
            old_state: options.PreviousState,
            state: options.CurrentState,
            reason_code: options.Reason,
            media_type: options.media_type,
            media_channel: options.media_channel,
            error_code: options.error_code
          });
          break;
        }
        default: {
          SERVICE.mixpanel.track(name, options);
        }
      }
    }
    if (services.length === 0) {
      SERVICE.mixpanel.track(name, options);
    }
  }

  timeEvent(name: string, services: string[] = []) {
    for (const service of services) {
      switch (service) {
        case this.SERVICE_PROVIDERS.mixpanel: {
          SERVICE.mixpanel.timeEvent(name);
          break;
        }
        case this.SERVICE_PROVIDERS.prometheus: {
          SERVICE.prometheus.startEvent(name, {
            timestamp: new Date()
          });
          break;
        }
        default: {
          SERVICE.mixpanel.timeEvent(name);
        }
      }
    }
    if (services.length === 0) {
      SERVICE.mixpanel.timeEvent(name);
    }
  }

  register(superProps: Record<string, any>) {
    SERVICE.mixpanel.register(superProps);
  }

  setPeople(options: Record<string, string | number>) {
    SERVICE.mixpanel.setPeople(options);
  }

  setIdentity(agentId: string) {
    SERVICE.mixpanel.setIdentity(agentId);
  }

  setGroup(name: string, groups: string[]) {
    SERVICE.mixpanel.setGroup(name, groups);
  }

  setUserProfile(agentId: string, profile: Record<string, string>) {
    SERVICE.mixpanel.setUserProfile(agentId, profile);
  }

  optOutTracking(): void {
    SERVICE.mixpanel.optOutTracking();
  }

  optInTracking(): void {
    SERVICE.mixpanel.optInTracking();
  }

  setOptOut(featureFlagOff: boolean) {
    SERVICE.mixpanel.setOptOut(featureFlagOff);
  }

  hasOptedOutTracking(): boolean {
    return SERVICE.mixpanel.hasOptedOutTracking();
  }

  getInstance(): any {
    return SERVICE.mixpanel.getInstance();
  }

  setValueInCache(key: string, value: string | number | boolean) {
    // record the time when the user navigates to the particular page
    SERVICE.mixpanel.setValueInCache(key, value);
  }

  getValueFromCache(key: string): any {
    return SERVICE.mixpanel.getValueFromCache(key);
  }

  getDataCenterDetails(): string {
    return SERVICE.mixpanel.getDataCenterDetails();
  }

  removeValueFromCache(key: string) {
    SERVICE.mixpanel.removeValueFromCache(key);
  }
  reset() {
    SERVICE.mixpanel.reset();
  }
}
