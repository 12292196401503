import { CONF } from "../../config";
import { AqmReqs, Msg } from "../../core/aqm-reqs";
import { HttpReqs } from "../../core/http-reqs";
import { Service, getRoutingHost } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import "./aqm-service-contact-types";
import Supervisor = Service.Aqm.Supervisor;

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };

export function aqmSupervisor(aqm: AqmReqs) {
  const httpApi = new HttpReqs(CONF.API_GATEWAY);
  return {
    startMonitoring: aqm.req((p: { data: Supervisor.MonitoringRequest }) => ({
      url: "/monitor",
      host: getRoutingHost(),
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringRequestCreated" }
        },
        msg: {} as any
      },
      notifFail: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringRequestCreateFailed" }
        },
        errId: "Service.aqm.supervisor.monitoringFailed"
      }
    })),

    endMonitoring: aqm.req((p: { interactionId: string }) => ({
      url: `/monitor/${p.interactionId}/end`,
      host: getRoutingHost(),
      data: p.interactionId,
      err,
      notifSuccess: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringEnded" }
        },
        msg: {} as any
      },
      notifFail: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringEndFailed" }
        },
        errId: "Service.aqm.supervisor.monitoringEndFailed"
      }
    })),

    holdMonitoring: aqm.req((p: { interactionId: string }) => ({
      url: `/monitor/${p.interactionId}/hold`,
      host: getRoutingHost(),
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringHeld" }
        },
        msg: {} as any
      },
      notifFail: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringHoldFailed" }
        },
        errId: "Service.aqm.supervisor.monitoringHoldFailed"
      }
    })),

    unHoldMonitoring: aqm.req((p: { interactionId: string }) => ({
      url: `/monitor/${p.interactionId}/unhold`,
      host: getRoutingHost(),
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringUnHeld" }
        },
        msg: {} as any
      },
      notifFail: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "MonitoringUnHoldFailed" }
        },
        errId: "Service.aqm.supervisor.monitoringUnHoldFailed"
      }
    })),

    bargeIn: aqm.req((p: { interactionId: string }) => ({
      url: `/v1/monitor/${p.interactionId}/bargeIn`,
      host: CONF.API_GATEWAY,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "AgentMonitorStateChanged",
          data: { type: "AgentMonitorStateChanged" }
        },
        msg: {} as any
      },
      notifFail: {
        bind: {
          type: "MonitoringRequest",
          data: { type: "AgentMonitorStateChangeFailed" }
        },
        errId: "Service.aqm.supervisor.bargeInFailed"
      }
    })),

    changeAgentState: httpApi.req((p: { orgId: string; data: any }) => ({
      url: "/v1/agents/session/state",
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: p.data,
      err,
      method: "PUT",
      errId: "Service.aqm.supervisor.changeAgentStateFailed",
      res: {} as any
    })),

    eMonitoringOffered: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringOffered" }
      },
      msg: {} as Service.Aqm.Contact.AgentContact
    }),
    eMonitoringStarted: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringStarted" }
      },
      msg: {} as Service.Aqm.Contact.AgentContact
    }),
    eMonitoringRequestCreateFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringRequestCreateFailed" }
      },
      msg: {} as any
    }),

    eMonitoringFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringFailed" }
      },
      msg: {} as Supervisor.MonitoringFailed
    }),

    eMonitoringEnded: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringEnded" }
      },
      msg: {} as any
    }),

    eMonitoringEndFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringEndFailed" }
      },
      msg: {} as Supervisor.MonitoringEndFailed
    }),

    eMonitoringHeld: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringHeld" }
      },
      msg: {} as Supervisor.MonitoringHeld
    }),

    eMonitoringHoldFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringHoldFailed" }
      },
      msg: {} as Supervisor.MonitoringFailed
    }),

    eMonitoringUnHeld: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringUnHeld" }
      },
      msg: {} as Supervisor.MonitoringHeld
    }),

    eMonitoringUnHoldFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "MonitoringUnHoldFailed" }
      },
      msg: {} as Supervisor.MonitoringFailed
    }),

    eChangeAgentState: aqm.evt({
      bind: {
        type: "ChangeAgentStateRequest",
        data: { type: "ChangeAgentState" }
      },
      msg: {} as Supervisor.Failure
    }),
    eAgentMonitorStateChanged: aqm.evt({
      bind: {
        type: "AgentMonitorStateChanged",
        data: { type: "AgentMonitorStateChanged" }
      },
      msg: {} as Service.Aqm.Contact.AgentMonitorStateChanged
    }),

    eAgentMonitorStateChangeFailed: aqm.evt({
      bind: {
        type: "MonitoringRequest",
        data: { type: "AgentMonitorStateChangeFailed" }
      },
      msg: {} as Service.Aqm.Contact.AgentMonitorStateChangeFailed
    }),

    eAgentMonitoringEnded: aqm.evt({
      bind: {
        type: "MonitoringEnded",
        data: { type: "MonitoringEnded" }
      },
      msg: {} as Service.Aqm.Contact.AgentMonitorEnded
    })
  };
}

declare module "../../index" {
  export namespace Service.Aqm.Supervisor {
    type MonitoringRequest = {
      id: string; // UUID from REST
      monitorType: string; // adhoc, continuous, midcall
      queueIds?: Set<string>;
      teams?: Set<string>;
      sites?: Set<string>;
      agents?: Set<string>;
      startUTCTimestamp?: number;
      continuousDuration?: number;
      taskId?: string;
      trackingId: string;
      invisibleMode?: boolean;
    };

    type MonitoringFailed = Msg<{
      orgId: string;
      id: string;
      monitorType: string;
      supervisorId: string;
      queueId: string;
      queueName: string;
      supervisorDN: string;
      taskId?: string;
      reason: string;
      reasonCode: number;
      trackingId: string;
    }>;

    type MonitoringEndFailed = Msg<{
      orgId: string;
      id: string;
      monitorType: string;
      supervisorId: string;
      taskId?: string;
      agentId?: string;
      reason: string;
      reasonCode: number;
      trackingId: string;
    }>;

    type MonitoringHeld = Msg<{
      orgId: string;
      id: string;
      monitorType: string;
      supervisorId: string;
      supervisorDN: string;
      taskId?: string;
      trackingId: string;
      agentId: string;
      eventType: string;
    }>;

    type BargedIn = Msg<{
      orgId: string;
      id: string;
      monitorType: string;
      supervisorId: string;
      taskId?: string;
      trackingId: string;
      agentId: string;
      eventType: string;
      type: string;
    }>;

    type Failure = Msg<{
      agentId: string;
      trackingId: string;
      reasonCode: number;
      orgId: string;
      reason: string;
    }>;
  }
}

type supervisorErrorIds = {
  "Service.aqm.supervisor.monitoringFailed": Supervisor.MonitoringFailed;
  "Service.aqm.supervisor.monitoringEndFailed": Supervisor.MonitoringEndFailed;
  "Service.aqm.supervisor.monitoringHoldFailed": Supervisor.MonitoringFailed;
  "Service.aqm.supervisor.monitoringUnHoldFailed": Supervisor.MonitoringFailed;
  "Service.aqm.supervisor.bargeInFailed": Supervisor.MonitoringFailed;
  "Service.aqm.supervisor.changeAgentStateFailed": Supervisor.Failure;
};

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.aqm.supervisor": supervisorErrorIds;
    }
  }
}
