import { Signal } from "@uuip/unified-ui-platform-sdk";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { Service } from "../..";
import { AqmNotifs } from "../../core/aqm-notifs";
import { logger } from "../../core/sdk";
import { CONNECTIVITY_CHECK_INTERVAL, LOST_CONNECTION_RECOVERY_TIMEOUT, WS_DISCONNECT_ALLOWED } from "./constants";
import ConnectionLostDetails = Service.Aqm.Connection.ConnectionLostDetails;

/**
 * aqmContact
 * @param reqs
 * @category AQM Service
 */
export function ConnectionService(notifs: AqmNotifs) {
  let connectionProp: connectionProp = { lostConnectionRecoveryTimeout: LOST_CONNECTION_RECOVERY_TIMEOUT };
  const wsDisconnectAllowed = WS_DISCONNECT_ALLOWED;
  let reconnectingTimer: ReturnType<typeof window.setTimeout>;
  let restoreTimer: ReturnType<typeof window.setTimeout>;
  let isConnectionLost: boolean;
  let isRestoreFailed: boolean;
  let isSocketReconnected: boolean;
  const { send, signal } = Signal.create.withData<ConnectionLostDetails>();
  const OnConnectionLost: Signal.WithData<ConnectionLostDetails> = signal;
  const OnConnectionLostSend: Signal.Send<ConnectionLostDetails> = send;
  let isKeepAlive: boolean;
  let reconnectInterval: ReturnType<typeof setIntervalAsync>;

  const dispatchEvent = (_socketReconnected = false): void => {
    OnConnectionLostSend({
      isConnectionLost,
      isRestoreFailed,
      isSocketReconnected: !notifs.isSocketClosed && (_socketReconnected ?? isSocketReconnected),
      isKeepAlive
    });
  };

  const handleConnectionLost = function(): void {
    isConnectionLost = true;
    dispatchEvent();
  };
  const clearTimerOnRestoreFailed = async () => {
    if (reconnectInterval) {
      clearIntervalAsync(reconnectInterval);
    }
  };
  const handleRestoreFailed = async function() {
    isRestoreFailed = true;
    notifs.shouldReconnect = false;
    dispatchEvent();
    clearTimerOnRestoreFailed();
  };

  const updateConnectionData = () => {
    isRestoreFailed = false;
    isConnectionLost = false;
    isSocketReconnected = false;
  };

  const setConnectionProp = function(prop: connectionProp): void {
    connectionProp = prop;
  };

  const onPing = function(msg: string): void {
    const event = JSON.parse(msg);
    if (reconnectingTimer) {
      clearInterval(reconnectingTimer);
    }
    if (restoreTimer) {
      clearInterval(restoreTimer);
    }
    isKeepAlive = event["keepalive"] === "true";
    if (((isConnectionLost && !isRestoreFailed) || isKeepAlive) && !isSocketReconnected) {
      updateConnectionData();
      dispatchEvent();
    } else if (isSocketReconnected && isKeepAlive) {
      updateConnectionData();
      dispatchEvent(true);
    }
    /** if there is no message coming from web socket for 8 seconds, then show the connection lost message*/
    reconnectingTimer = setTimeout(handleConnectionLost, wsDisconnectAllowed);
    restoreTimer = setTimeout(handleRestoreFailed, connectionProp && connectionProp.lostConnectionRecoveryTimeout);
  };

  const handleSocketClose = async function() {
    logger.info("event=socketConnectionRetry | Trying to reconnect to notifs socket");
    const onlineStatus = await notifs.getOnlineStatus();
    if (onlineStatus) {
      notifs.init();
      clearTimerOnRestoreFailed();
      isSocketReconnected = true;
    } else {
      logger.error("event=socketConnectionRetry | browser network not available");
    }
  };

  const onSocketClose = function(): void {
    logger.info(`event=socketConnectionRetry | Retry to reconnect is going to begin`);
    clearTimerOnRestoreFailed();

    reconnectInterval = setIntervalAsync(async () => {
      await handleSocketClose();
    }, CONNECTIVITY_CHECK_INTERVAL);
  };

  notifs.onMessage.listen(onPing);
  notifs.onSocketClose.listen(onSocketClose);

  return {
    setConnectionProp,
    OnConnectionLost,
    handleSocketClose
  };
}

type connectionProp = {
  lostConnectionRecoveryTimeout: number;
};

declare module "../../index" {
  export namespace Service.Aqm.Connection {
    export type ConnectionLostDetails = {
      isConnectionLost: boolean;
      isRestoreFailed: boolean;
      isSocketReconnected: boolean;
      isKeepAlive?: boolean;
    };
  }
}
