import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { logger } from "../../core/sdk";
import { Service, SERVICE } from "../../index";
import { KEY_PATH } from "../constant";
import { createErrDetailsObject as err, RETRY_INTERVAL, sleep } from "../service-utils";
import Cms = Service.Cms;

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };
export function cmsService() {
  const httpApi = new HttpReqs(CONF.API_GATEWAY);

  const fetchAllTeamsByPages = async (orgId: string, filter: false | { dbId: string } = false) => {
    const result: Service.Cms.AllTeamResponse = [];
    let url = `/organization/${orgId}/v2/team?page=0&pageSize=500`;

    if (filter && filter?.dbId) {
      url = `${url}&filter=dbId=="${filter.dbId}"`;
    }

    try {
      while (url) {
        const response: Service.Cms.TeamResponse = await SERVICE.cms.fetchTeamsByPage({ orgId, url });
        const { data, meta } = response;
        url = meta.links.next;
        result.push(...data);
      }
    } catch (err) {
      logger.error(`event=fetchTeamsFailed for page url: ${url} for org ${orgId}`, err);
    }
    return result;
  };
  const fetchAllManagedTeamsByPages = async (orgId: string) => {
    const result: Service.Cms.ManagedAllTeamResponse = [];
    let url = `/organization/${orgId}/v2/team?agentView=true&page=0&pageSize=500&attributes=id,name,dbId,siteId`;
    try {
      while (url) {
        const response: Service.Cms.ManagedTeamResponse = await SERVICE.cms.fetchTeamsByPage({ orgId, url });
        const { data, meta } = response;
        url = meta.links.next;
        result.push(...data);
      }
    } catch (err) {
      logger.error(`event=fetchManagedTeamsFailed for page url: ${url} for org ${orgId}`, err);
      throw err;
    }
    return result;
  };
  const fetchAllManagedQueuesByPages = async (orgId: string) => {
    const result: Service.Cms.ManagedAllQueueResponse = [];
    let url = `/organization/${orgId}/v2/contact-service-queue?agentView=true&page=0&pageSize=500&attributes=id,name`;
    try {
      while (url) {
        const response: Service.Cms.ManagedQueueResponse = await SERVICE.cms.fetchQueuesByPage({ orgId, url });
        const { data, meta } = response;
        url = meta.links.next;
        result.push(...data);
      }
    } catch (err) {
      logger.error(`event=fetchManagedQueueFailed for page url: ${url} for org ${orgId}`, err);
      throw err;
    }
    return result;
  };
  const fetchAllEntryPointsByPages = async (orgId: string) => {
    const result: Service.Cms.AllEntryPointResponse = [];
    let url = `/organization/${orgId}/v2/entry-point?agentView=true&pageSize=1000`;
    try {
      while (url) {
        const response: Service.Cms.EntryPointsResponse = await SERVICE.cms.fetchEntryPointsByPage({ orgId, url });
        const { data, meta } = response;
        url = meta.links.next;
        result.push(...data);
      }
    } catch (err) {
      logger.error(`event=fetchEntryPointsFailed for page url: ${url} for org ${orgId}`, err);
      throw err;
    }
    return result;
  };
  const fetchAllAddressBookByPages = async (p: {
    orgId: string;
    addressBookId: string;
    allowRetry: boolean;
  }): Promise<any> => {
    const result: Service.Cms.AllAddressBookResponse = [];
    const promises: Promise<Cms.AddressBookResponse>[] = [];
    const orgId: string = p.orgId;
    const addressBookId = p.addressBookId;
    const pageSize = 1000;
    const url = `/organization/${p.orgId}/v2/address-book/${p.addressBookId}/entry?pageSize=${pageSize}&attributes=name,number&sort=name,ASC`;
    try {
      const response: Service.Cms.AddressBookResponse = await SERVICE.cms.fetchAddressBookByPage({ orgId, url });
      const { data, meta } = response;
      result.push(...data);
      if (meta?.totalPages > 1) {
        for (let i = 1; i < meta.totalPages; i++) {
          const nextUrl = `${url}&page=${i}`;
          promises.push(SERVICE.cms.fetchAddressBookByPage({ orgId, url: nextUrl }));
        }
        const allResponse: Service.Cms.AddressBookResponse[] = await Promise.all(promises);
        allResponse.forEach((item: Service.Cms.AddressBookResponse) => {
          const { data } = item;
          result.push(...data);
        });
      }
    } catch (err) {
      logger.error(`event=fetchAddressBookFailed for page url: ${url} for org ${p.orgId}`, err);
      if ((err as any)?.details?.msg?.status === 429 && p.allowRetry) {
        return fetchAllAddressBookByPages({ orgId, addressBookId, allowRetry: false });
      } else {
        throw err;
      }
    }
    return result;
  };
  const fetchMicrosoftConfigHandler = async (
    p: { orgId: string },
    allowRetry = true
  ): Promise<Cms.MicrosoftOrgConfig> => {
    try {
      return await SERVICE.cms.fetchMicrosoftConfigData(p);
    } catch (err) {
      logger.error(`event=fetchMicrosoftConfig Failed to fetch the microsoft org config`, err);
      if ((err as any)?.details?.msg?.status === 429 && allowRetry) {
        await sleep(RETRY_INTERVAL);
        return fetchMicrosoftConfigHandler(p, false);
      } else {
        throw err;
      }
    }
  };
  return {
    fetchChatResponses: httpApi.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/chat-template`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchChatResponses",
      res: {} as Cms.ChatTemplateResponse
    })),
    fetchTeamsById: httpApi.req((p: { orgId: string; teamId: string }) => ({
      url: `/organization/${p.orgId}/team/${p.teamId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId, "x-ignore-internal-data": "false" },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchTeamsById",
      res: {} as Cms.TeamResponseData
    })),
    fetchTeamsByPage: httpApi.req((p: { orgId: string; url: string }) => ({
      url: p.url,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId, "x-ignore-internal-data": "false" },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchAllTeams",
      res: {} as Cms.TeamResponse & Cms.ManagedTeamResponse
    })),
    fetchQueuesByPage: httpApi.req((p: { orgId: string; url: string }) => ({
      url: p.url,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId, "x-ignore-internal-data": "false" },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchAllQueues",
      res: {} as Cms.ManagedQueueResponse
    })),
    fetchDesktopLayout: httpApi.req((p: { orgId: string; desktopLayoutId?: string }) => ({
      url: `/organization/${p.orgId}/desktop-layout/${p.desktopLayoutId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchDesktopLayout",
      res: {} as Cms.DesktopLayoutResponse
    })),
    fetchGlobalDesktopLayoutV2: httpApi.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/desktop-layout?filter=global==true`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchDefaultDesktopLayout",
      res: {} as Cms.GlobalDesktopLayoutResponseMappingV2
    })),
    fetchUserRecord: httpApi.req((p: { userId: string; orgId: string }) => ({
      url: `/organization/${p.orgId}/user/by-ci-user-id/${p.userId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchUserRecord",
      res: {} as Cms.UserRecordResponse
    })),
    fetchUserProfile: httpApi.req((p: { profileId: string; orgId: string }) => ({
      url: `/organization/${p.orgId}/user-profile/${p.profileId}?agentView=true`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchUserProfile",
      res: {} as Cms.UserProfileResponse
    })),
    fetchEntryPointsByPage: httpApi.req((p: { orgId: any; url: string }) => ({
      url: p.url,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchEntryPointsList",
      res: {} as Cms.EntryPointsResponse
    })),
    fetchDialNumberList: httpApi.req((p: { orgId: string; url: string }) => ({
      url: p.url,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchDialNumberList",
      res: {} as Cms.DialNumberResponse
    })),
    fetchAddressBookByPage: httpApi.req((p: { orgId: string; url: string }) => ({
      // after testing change http to httpApi
      url: p.url,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchAddressBookList",
      res: {} as Cms.AddressBookResponse
    })),
    fetchOrgUrlMappingByNameV2: httpApi.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/org-url-mapping?filter=name=in=(${KEY_PATH})`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchOrgUrlMappingByNameV2",
      res: {} as Cms.OrgUrlMappingResponse
    })),
    fetchMicrosoftConfigData: httpApi.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/v2/microsoft-config`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchMicrosoftConfig",
      res: {} as Cms.MicrosoftOrgConfig
    })),
    fetchWebexConfigData: httpApi.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/webex-config`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.cms.fetchWebexConfig",
      res: {} as Cms.WebexOrgConfig
    })),
    fetchMicrosoftConfig: fetchMicrosoftConfigHandler,
    fetchAllTeams: fetchAllTeamsByPages,
    fetchAllManagedTeams: fetchAllManagedTeamsByPages,
    fetchAllManagedQueues: fetchAllManagedQueuesByPages,
    fetchAllEntryPoints: fetchAllEntryPointsByPages,
    fetchAllAddressBook: fetchAllAddressBookByPages
  };
}

type cmsErrorIds =
  | "Service.cms.fetchChatResponses"
  | "Service.cms.fetchAllTeams"
  | "Service.cms.fetchTeamsById"
  | "Service.cms.fetchAllQueues"
  | "Service.cms.fetchDesktopLayout"
  | "Service.cms.fetchDefaultDesktopLayout"
  | "Service.cms.fetchTeamsByAgent"
  | "Service.cms.fetchManagedTeams"
  | "Service.cms.fetchUserRecord"
  | "Service.cms.fetchUserProfile"
  | "Service.cms.fetchEntryPointsList"
  | "Service.cms.fetchDialNumberList"
  | "Service.cms.fetchAddressBookList"
  | "Service.cms.fetchMicrosoftConfig";
declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.cms": cmsErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Cms {
    type ChatTemplateResponse = ChatTemplate[];

    type ChatTemplate = {
      id: string;
      name: string;
      language: string;
      status: boolean;
      content: string;
      queueIds: string[];
      links: any;
      createdTime: number;
      lastUpdatedTime: number;
      allQueues?: boolean;
    };

    type SplitIOKey = {
      key: string;
    };

    type AllTeamResponse = TeamResponseData[];
    type ManagedAllTeamResponse = ManagedTeamResponseData[];
    type ManagedAllQueueResponse = ManagedQueueResponseData[];
    type AllEntryPointResponse = EntryPointsResponseData[];
    type AllAddressBookResponse = AddressBookResponseData[];
    type AllDialNumberResponse = DialNumberResponseData[];

    type TeamResponse = { meta: { links: { next: string } }; data: AllTeamResponse };

    type ManagedTeamResponse = { meta: { links: { next: string } }; data: ManagedAllTeamResponse };
    type ManagedQueueResponse = { meta: { links: { next: string } }; data: ManagedAllQueueResponse };
    type EntryPointsResponse = { meta: { links: { next: string } }; data: AllEntryPointResponse };
    type AddressBookResponse = { meta: { links: { next: string }; totalPages: number }; data: AllAddressBookResponse };
    type DialNumberResponse = { meta: { links: { next: string }; totalPages: number }; data: AllDialNumberResponse };

    type fetchTeamsByAgentResponse = {
      details: {
        user: TeamsByAgentMap;
      };
    };
    type TeamsByAgentMap = {
      id: string;
      type: "user";
      attributes: TeamByAgentAuxiliaryDataAttributes;
    };
    type EpDnListMap = {
      name: string;
      dialledNumber: string;
      id?: string;
    };
    type AgentIdleCodes = {
      idleCodes: string[];
      accessIdleCode: "ALL" | "SPECIFIC";
    };
    type AgentDataResponse = {
      meta: {
        totalRecords: number;
      };
      data: Agent[];
    };
    type Agent = {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
    type IdleCode = Record<string, any>;
    type OrgIdleCodes = {
      idleCodes: Service.Aqm.Configs.Entity[];
    };
    type DesktopLayoutResponseMapping = {
      attributes: DesktopLayoutAuxiliaryDataAttributes;
      auxiliaryMetadata: any;
    };
    type GlobalDesktopLayoutResponseMapping = {
      auxiliaryDataList: DesktopLayoutAuxiliaryData[];
      auxiliaryMetadata: any;
    };
    type GlobalDesktopLayoutResponseMappingV2 = DesktopLayoutResponse[];
    type TeamResponseData = {
      id: string;
      dbId: string;
      active: boolean;
      createdTime: number;
      desktopLayoutId: string;
      lastUpdatedTime: number;
      multiMediaProfileId: string;
      name: string;
      siteId: string;
      skillProfileId: string;
      teamStatus: string;
      teamType: string;
      userIds: string[];
    };
    type ManagedTeamResponseData = {
      id: string;
      links: [];
      name: string;
      dbId?: string;
      siteId: string;
    };
    type ManagedQueueResponseData = {
      id: string;
      links: [];
      name: string;
    };
    type DesktopLayoutAuxiliaryData = {
      id: string;
      type: "desktop-layout";
      attributes: DesktopLayoutAuxiliaryDataAttributes;
      auxiliaryDataType: string;
    };

    type TeamByAgentAuxiliaryDataAttributes = {
      teamInfo__s: any;
    };

    type DesktopLayoutResponse = {
      createdTime: number;
      defaultJsonModified: boolean;
      defaultJsonModifiedTime: number;
      description: string;
      editedBy: string;
      global: boolean;
      id: string;
      jsonFileContent: string;
      jsonFileName: string;
      lastUpdatedTime: number;
      modifiedTime: number;
      name: string;
      status: boolean;
      teamIds: string[];
      validated: boolean;
      validatedTime: number;
    };

    type DesktopLayoutAuxiliaryDataAttributes = {
      description__s: string;
      jsonFileContent__s: string;
      global__i: number;
      assigned__i: number;
      editedBy__s: string;
      name__s: string;
      cstts: number;
      status__i: number;
      tid: string;
      validatedTime__l: number;
      sid: string;
    };

    type UserRecordResponse = {
      organizationId: string;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      workPhone: string;
      mobile: string;
      ciUserId: string;
      xspVersion: string;
      subscriptionId: string;
      userProfileId: string;
      contactCenterEnabled: boolean;
      siteId: string;
      teamIds: string[];
      skillProfileId: string;
      agentProfileId: string;
      multimediaProfileId: string;
      deafultDialledNumber: string;
      externalIdentifier: string;
      active: boolean;
      imiUserCreated: boolean;
      links: any;
      createdTime: number;
      lastUpdatedTime: number;
    };

    type UserProfileResponse = {
      accessAllEntryPoints: string;
      accessAllModules: string;
      accessAllQueues: string;
      accessAllSites: string;
      accessAllTeams: string;
      active: boolean;
      createdTime: number;
      description: string;
      editableFolderIds: string;
      entryPoints: Array<string>;
      id: string;
      lastUpdatedTime: number;
      name: string;
      nonViewableFolderIds: Array<string>;
      organizationId: string;
      profileType: string;
      queues: Array<string>;
      sites: Array<string>;
      teams: Array<string>;
      userProfileAppModules: Array<any>;
      version: number;
      viewableFolderIds: Array<string>;
    };

    type EntryPointsResponseData = {
      active: boolean;
      channelType: string;
      createdTime: number;
      description: string;
      entryPointType: string;
      id: string;
      lastUpdatedTime: number;
      links: any;
      maximumActiveContacts: 0;
      name: string;
      routePointId: string;
      serviceLevelThreshold: number;
      subscriptionId: string;
      xspVersion: string;
      version: number;
      ccOneQueue: boolean;
    };
    type DialNumberResponseData = {
      organizationId: string;
      id: string;
      version: number;
      dialledNumber: string;
      routePointId: string;
      entryPointId: string;
      defaultAni: boolean;
      links: Array<any>;
      createdTime: number;
      lastUpdatedTime: number;
    };
    type AddressBookResponseData = {
      id: string;
      version: number;
      name: string;
      number: string;
      links: Array<any>;
    };
    type MicrosoftOrgConfig = {
      meta: { links: { next: string }; totalPages: number };
      data: MicrosoftConfigResponse[];
    };
    type MicrosoftConfigResponse = {
      id: string;
      organizationId: string;
      active: boolean;
      accountDetails: MicrosoftAccountDetails;
      showUserDetails: boolean;
      idleCodes: MicrosoftIdleCodes;
      stateSynchronization: boolean;
      createdTime: number;
      lastUpdatedTime: number;
    };

    type MicrosoftAccountDetails = {
      name: string;
      tenantId: string;
      userName: string;
    };

    type MicrosoftIdleCodes = {
      doNotDisturbId: string;
      onACallId: string;
      presentingId: string;
    };

    type OrgUrlMappingResponse = OrgUrlMappingResponseData[];

    type OrgUrlMappingResponseData = {
      organizationId: string;
      id: string;
      version: number;
      name: string;
      url: string;
      links: Array<any>;
      createdTime: number;
      lastUpdatedTime: number;
    };

    type WebexOrgConfig = {
      data: WebexConfigResponse[];
    };

    type WebexConfigResponse = {
      id: string;
      organizationId: string;
      showUserDetails: boolean;
      stateSynchronization: boolean;
      idleCodes: WebexIdleCodes;
      createdTime: number;
      lastUpdatedTime: number;
    };

    type WebexIdleCodes = {
      busyId: string;
      doNotDisturbId: string;
      inACalendarMeetingId: string;
      inAMeetingId: string;
      onACallId: string;
      outOfOfficeId: string;
      presentingId: string;
      quietHoursId: string;
    };
  }
}
