import { AqmReqs, TIMEOUT_REQ } from "../../core/aqm-reqs";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import "./aqm-service-contact-types";
import Contact = Service.Aqm.Contact;

/*
 * aqmContact
 * @param reqs
 * @category AQM Service
 */
// eslint-disable-next-line max-lines-per-function
export function aqmContact(aqm: AqmReqs, http: HttpReqs) {
  return {
    /*
     * Accept incoming contact
     */
    accept: aqm.req((p: { interactionId: string }) => ({
      url: `/contact/${p.interactionId}/accept`,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactAssigned", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactAssignFailed", interactionId: p.interactionId }
        },
        errId: "Service.aqm.contact.accept"
      }
    })),
    /*
     * Consult Accept contact
     */
    consultAccept: aqm.req((p: { interactionId: string }) => ({
      url: `/contact/${p.interactionId}/consultAccept`,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentConsulting", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactAssignFailed" }
        },
        errId: "Service.aqm.contact.consultAccept"
      }
    })),
    /*
     * Get list of Agents
     */
    buddyAgents: aqm.req((p: { data: Contact.BuddyAgents }) => ({
      url: `/buddyagents`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "BuddyAgents",
          data: { type: "BuddyAgents" }
        },
        msg: {} as Contact.BuddyAgentsSuccess
      },
      notifFail: {
        bind: {
          type: "BuddyAgents",
          data: { type: "BuddyAgentsRetrieveFailed" }
        },
        errId: "Service.aqm.contact.BuddyAgentsRetrieveFailed"
      }
    })),

    /*
     * End contact
     */
    end: aqm.req((p: { interactionId: string }) => ({
      url: `/contact/${p.interactionId}/end`,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentWrapup", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactEndFailed" }
        },
        errId: "Service.aqm.contact.end"
      }
    })),

    /*
     * Cancel popover
     */
    cancelTask: aqm.req((p: { interactionId: string }) => ({
      url: `/contact/${p.interactionId}/end`,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "ContactEnded", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactEndFailed" }
        },
        errId: "Service.aqm.contact.end"
      }
    })),

    /*
     * Consult End contact
     */
    consultEnd: aqm.req((p: { interactionId: string; isConsult: boolean; isSecondaryEpDnAgent?: boolean }) => {
      // Setting false value for optional attribute
      const { interactionId, isConsult, isSecondaryEpDnAgent = false } = p;
      return {
        url: `/contact/${interactionId}/consultEnd`,
        data: {},
        err,
        notifSuccess: {
          bind: {
            type: "RoutingMessage",
            data: {
              type: isSecondaryEpDnAgent
                ? "ContactEnded"
                : isConsult
                ? "AgentConsultEnded"
                : "AgentConsultConferenceEnded",
              interactionId: p.interactionId
            }
          },
          msg: {} as Contact.AgentContact
        },
        notifFail: {
          bind: {
            type: "RoutingMessage",
            data: { type: "AgentConsultEndFailed" }
          },
          errId: "Service.aqm.contact.consultEnd"
        }
      };
    }),

    /*
     * Cancel Ctq request
     */
    cancelCtq: aqm.req((p: { interactionId: string; data: Contact.cancelCtq }) => ({
      url: `/contact/${p.interactionId}/cancelCtq`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentCtqCancelled", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentCtqCancelFailed" }
        },
        errId: "Service.aqm.contact.cancelCtq"
      }
    })),

    /*
     * Wrapup contact
     */
    wrapup: aqm.req((p: { interactionId: string; data: Contact.WrapupPayLoad }) => ({
      url: `/contact/${p.interactionId}/wrapup`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentWrappedUp", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentWrapupFailed" }
        },
        errId: "Service.aqm.contact.wrapup"
      }
    })),
    /*
     * VteamTransfer contact
     */
    vteamTransfer: aqm.req((p: { interactionId: string; data: Contact.vteamTransferPayLoad }) => ({
      url: `/contact/${p.interactionId}/vteamTransfer`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentVteamTransferred", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentVteamTransferFailed" }
        },
        errId: "Service.aqm.contact.AgentVteamTransferFailed"
      }
    })),
    /*
     * BlindTransfer contact
     */
    blindTransfer: aqm.req((p: { interactionId: string; data: Contact.blindTransferPayLoad }) => ({
      url: `/contact/${p.interactionId}/blindTransfer`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentBlindTransferred", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentBlindTransferFailedEvent" }
        },
        errId: "Service.aqm.contact.AgentBlindTransferFailedEvent"
      }
    })),
    /*
     * Hold contact
     */
    hold: aqm.req((p: { interactionId: string; data: { mediaResourceId: string } }) => ({
      url: `/contact/${p.interactionId}/hold`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactHeld", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactHoldFailed" }
        },
        errId: "Service.aqm.contact.hold"
      }
    })),

    /*
     * Unhold contact
     */
    unHold: aqm.req((p: { interactionId: string; data: { mediaResourceId: string } }) => ({
      url: `/contact/${p.interactionId}/unhold`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactUnheld", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentContactUnHoldFailed" }
        },
        errId: "Service.aqm.contact.unHold"
      }
    })),

    /*
     * Consult contact
     */
    consult: aqm.req((p: { interactionId: string; data: Contact.consultData; url: string }) => ({
      url: p.url ? `/contact/${p.interactionId}/${p.url}` : `/contact/${p.interactionId}/consult`,
      data: p.data,
      timeout: p.url === "ctq" ? "disabled" : TIMEOUT_REQ,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentConsultCreated", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: p.url === "ctq" ? "AgentCtqFailed" : "AgentConsultFailed" }
        },
        errId: "Service.aqm.contact.consult"
      },
      notifCancel: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentCtqCancelled", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      }
    })),
    /*
     * Consult Conference contact
     */
    consultConference: aqm.req((p: { interactionId: string; data: Contact.ConsultPayload | Contact.ConsultDN }) => ({
      url: `/contact/${p.interactionId}/consultConference`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentConsultConferenced", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentConsultConferenceFailed" }
        },
        errId: "Service.aqm.contact.consultConference"
      }
    })),

    /*
     * Decline contact
     */
    decline: aqm.req((p: { interactionId: string; data: Contact.declinePayload; isConsult: boolean }) => ({
      url: `/contact/${p.interactionId}/decline`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: p.isConsult ? "AgentConsultFailed" : "AgentOfferContactRona", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      }
    })),
    /*
     * Consult/Conference Transfer contact
     */
    consultTransfer: aqm.req((p: { interactionId: string; data: Contact.consultTransferPayLoad }) => ({
      url: `/contact/${p.interactionId}/consultTransfer`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: {
            type: "AgentConsultTransferred",
            interactionId: p.interactionId
          }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentConsultTransferFailed" }
        },
        errId: "Service.aqm.contact.AgentConsultTransferFailed"
      }
    })),
    /*
     * Get contact history by ani
     */
    fetchHistoryByANI: http.req((p: { ani: string; startTime: number; endTime: number }) => ({
      url: `/contactHistoryByANI/${p.ani}?startTime=${p.startTime}&endTime=${p.endTime}`,
      errId: "Service.aqm.contact.fetchHistoryByANI",
      err,
      res: {} as Contact.HistoryByANI
    })),

    /*
     * Get contact history by agent
     */
    fetchHistoryByAgent: http.req((p: { startTime: number; endTime: number }) => ({
      url: `/contactHistoryByAgent?startTime=${p.startTime}&endTime=${p.endTime}`,
      errId: "Service.aqm.contact.fetchHistoryByAgent",
      err,
      res: {} as Contact.HistoryByAgent
    })),

    /*
     * Get (inbound/outbound) transcripts for selected contact
     */
    fetchTranscript: http.req((p: { transcriptId: string }) => ({
      url: `/transcript/${p.transcriptId}`,
      errId: "Service.aqm.contact.fetchTranscript",
      err,
      res: ""
    })),

    /*
     * Get list of queues available.
     */
    vteamList: aqm.req((p: { data: Contact.VTeam }) => ({
      url: `/vteams`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "VteamList",
          data: { jsMethod: "vteamListChanged" }
        },
        msg: {} as Contact.VTeamSuccess
      },
      notifFail: {
        bind: {
          type: "VteamListFailed",
          data: { statusCode: 500 }
        },
        errId: "Service.aqm.contact.VteamListFailed"
      }
    })),

    /*
     * Pause Recording
     */
    pauseRecording: aqm.req((p: { interactionId: string }) => ({
      url: `/contact/${p.interactionId}/record/pause`,
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "ContactRecordingPaused", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "ContactRecordingPauseFailed" }
        },
        errId: "Service.aqm.contact.pauseRecording"
      }
    })),

    /*
     * Resume Recording
     */
    resumeRecording: aqm.req((p: { interactionId: string; data: { autoResumed: boolean } }) => ({
      url: `/contact/${p.interactionId}/record/resume`,
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "ContactRecordingResumed", interactionId: p.interactionId }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "ContactRecordingResumeFailed" }
        },
        errId: "Service.aqm.contact.resumeRecording"
      }
    })),
    // EVENTS
    // ------------------------

    eAgentContact: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContact" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactAssigned: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactAssigned" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactAssignFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactAssignFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactEnded: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactEnded" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactWrappedUp: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentWrappedUp" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentOfferContact: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentOfferContact" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentOfferContactRona: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentOfferContactRona" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactReserved: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactReserved" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentInviteFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentInviteFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentOfferConsult: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentOfferConsult" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentWrapup: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentWrapup" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentOfferCampaignReserved: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentOfferCampaignReservation" }
      },
      msg: {} as Contact.AgentOfferCampaign
    }),

    eAgentAddCampaignReserved: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentAddCampaignReservation" }
      },
      msg: {} as Contact.AgentAddCampaign
    }),

    eAgentContactHeld: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactHeld" }
      },
      msg: {} as Contact.AgentContact
    }),
    eResumeRecording: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingResumed" }
      },
      msg: {} as Contact.AgentContact
    }),

    ePauseRecording: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingPaused" }
      },
      msg: {} as Contact.AgentContact
    }),

    eConsultTransfer: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultTransferred" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultTransferFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultTransferFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eContactMerged: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactMerged" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentblindTransferred: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentBlindTransferred" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentvteamTransfer: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentVteamTransferred" }
      },
      msg: {} as Contact.AgentContact
    }),

    eCallRecordingStarted: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingStarted" }
      },
      msg: {} as Contact.AgentContact
    }),
    eAgentContactUnHeld: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactUnheld" }
      },
      msg: {} as Contact.AgentContact
    }),
    eAgentConsultCreated: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultCreated" }
      },
      msg: {} as Contact.AgentContact
    }),
    eAgentConsultConferenced: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultConferenced" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultEnded: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultEnded" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentCtqCancelled: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentCtqCancelled" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultConferenceEnded: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultConferenceEnded" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsulting: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsulting" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultEndFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultEndFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentCtqFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentCtqFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentCtqCancelFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentCtqCancelFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentConsultConferenceEndFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultConferenceEndFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eAgentContactCleanup: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactCleanup" }
      },
      msg: {} as Contact.ContactCleanupData
    }),

    eContactCallbackFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactCallbackFailed" }
      },
      msg: {} as Contact.ContactCallbackFailed
    }),

    eContactOwnerChanged: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactOwnerChanged" }
      },
      msg: {} as Contact.AgentContact
    }),

    eParticipantJoinedConference: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ParticipantJoinedConference" }
      },
      msg: {} as Contact.AgentContact
    }),

    eParticipantLeftConference: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ParticipantLeftConference" }
      },
      msg: {} as Contact.AgentContact
    })
  };
}
