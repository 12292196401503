import { Err } from "@uuip/unified-ui-platform-sdk";
import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import Emm = Service.Emm;

export function emmService() {
  const http = new HttpReqs(CONF.EMM_HOST);
  const fetchContentErrId = "Service.emm.fetchContent";

  return {
    fetchContent: http.req((p: { orgId: string; taskId: string }) => ({
      url: `/organization/${p.orgId}/task/${p.taskId}/emailcontent`,
      err,
      errId: fetchContentErrId,
      res: {} as Emm.EmailContent
    })),

    fetchEmailContentFromEmm: http.req((url: string) => ({
      url: `/${url.substring(url.indexOf("organization"))}`,
      err,
      errId: fetchContentErrId,
      res: {} as Emm.EmailStorageContent
    })),

    fetchReplyTemplate: http.req((p: { orgId: string }) => ({
      url: `/organization/${p.orgId}/emailtemplate`,
      err,
      errId: "Service.emm.fetchReplyContent",
      res: {} as Emm.EmailTemplate
    })),

    downloadAttachment: http.req((p: { resourceUrl: string }) => ({
      url: `/${p.resourceUrl.substring(p.resourceUrl.indexOf("organization"))}`,
      err,
      errId: "Service.emm.downloadAttachment",
      responseType: "arraybuffer",
      res: {}
    })),

    addAttachment: http.req((p: { orgId: string; taskId: string; data: any }) => ({
      url: `/organization/${p.orgId}/task/${p.taskId}/blob`,
      headers: { "Content-Type": `multipart/form-data; boundary=` },
      data: p.data,
      err: (e: any) => new Err.Details("Service.emm.addAttachment", { axiosError: e.response }),
      res: {} as Emm.OutBoundAttachments
    })),

    deleteAttachment: http.req((p: { resourceUrl: string }) => ({
      url: `/${p.resourceUrl.substring(p.resourceUrl.indexOf("organization"))}`,
      err,
      method: "DELETE",
      errId: "Service.emm.deleteAttachment",
      res: {} as Emm.EmailContent
    })),

    sendEmail: http.req((p: { orgId: string; taskId: string; data: any }) => ({
      url: `/organization/${p.orgId}/task/${p.taskId}/sendemail`,
      data: p.data,
      err,
      errId: "Service.emm.sendEmail",
      res: {} as Emm.EmailContent
    }))
  };
}

type emmErrorIds =
  | "Service.emm.fetchContent"
  | "Service.emm.sendEmail"
  | "Service.emm.downloadAttachment"
  | { "Service.emm.addAttachment": Emm.Failure }
  | "Service.emm.deleteAttachment"
  | "Service.emm.fetchReplyContent";

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.emm": emmErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Emm {
    // TODO - wrap to Msg<> ???

    type Content = {
      emailContent: string;
      subject: string;
      toAddress: Array<string>;
      ccAddress: Array<string>;
      userEmailId: string;
      contentType: string;
      date: string;
      replyToAddress: string;
      messageId: string;
      from: string;
      inboundTranscript: string;
      inReplyTo?: string;
      references?: string;
    };

    type Failure = {
      axiosError: any;
    };

    type OutboundContent = {
      body: string;
      outboundReplyToAddress: string;
      outboundToAddress: string;
      outboundSubject: string;
      outboundFromAddress: string;
      outboundCcAddress: string;
      outboundContentType: string;
      outboundTranscript: string;
    };

    type AttachmentInfo = {
      maxAttachmentSizeInMB: string;
      attachmentLimit: string;
    };
    type InteractionContext = {
      content: Content;
      incomingAttachments?: IncomingAttachment[];
      outboundAttachments?: OutBoundAttachments[];
      outboundContent?: OutboundContent;
    };

    type EmailContent = {
      interactionContext: InteractionContext;
      attachmentInfo?: AttachmentInfo;
      storageName: StorageName;
    };

    type EmailTemplate = {
      defaultValues: DefaultTemplateValues;
      emailTemplate: string;
    };

    type DefaultTemplateValues = {
      defaultTypeCustomer: string;
      defaultTypeForCustomer: boolean;
      defaultTypeAgent: string;
      defaultTypeForAgent: boolean;
    };

    type IncomingAttachment = {
      size?: number;
      fileName: string;
      contentType: string;
      messageId?: string;
      resourceUrl: string;
      isCompliant?: boolean;
      storageName?: StorageName;
    };

    type OutBoundAttachments = {
      size?: number;
      fileName?: string;
      contentType?: string;
      resourceUrl?: string;
      location?: string;
    };

    type EmailStorageContent = {
      metaInfo: string;
      body: string;
    };

    type StorageName = "emm" | "s3";
  }
}
