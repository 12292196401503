import EventEmitter from "event-emitter";
import { Service } from "../../index";

class ActionChannelSource<A extends string, P extends unknown[]> {
  private readonly emitter: EventEmitter.Emitter;
  private readonly getSourcesCount: () => number;
  private readonly getDestinationsCount: () => number;

  private readonly options = {
    throttleMs: 0,
    tag: "" // Mark service/widget that sending action
  };

  private lastTimeoutRef: number | undefined = undefined;
  private last: number = Date.now();
  private throttledDelayMs = 0;
  private throttledDropCount = 0;

  private initOptions(options?: Service.ActionChannel.SourceOptions) {
    if (options) {
      this.options.throttleMs = options.rps && options.rps > 0 ? 1000 / options.rps : this.options.throttleMs;
      this.options.tag = options.tag ? options.tag : this.options.tag;
    }
  }

  private createSourceMeta(opts: {
    argsCount: number;
    throttledDelayMs: number;
    throttledDropCount: number;
  }): Service.ActionChannel.SourceMeta {
    return {
      argsCount: opts.argsCount,
      sourcesCount: this.getSourcesCount(),
      destinationsCount: this.getDestinationsCount(),
      ...(this.options.tag ? { sentTag: this.options.tag } : null),
      ...{ throttledDelayMs: opts.throttledDelayMs },
      ...{ throttledDropCount: opts.throttledDropCount }
    };
  }

  constructor(config: {
    actionName: A;
    inject: {
      emitter: EventEmitter.Emitter;
      getSourcesCount: () => number;
      getDestinationsCount: () => number;
    };
    options?: Service.ActionChannel.SourceOptions;
  }) {
    this.actionName = config.actionName;

    this.emitter = config.inject.emitter;
    this.getSourcesCount = config.inject.getSourcesCount;
    this.getDestinationsCount = config.inject.getDestinationsCount;

    //
    this.initOptions(config.options || {});
  }

  public readonly actionName: A;

  send(...args: P) {
    // Throttling
    const now = Date.now();
    const deltaMs = now - this.last;

    if (this.lastTimeoutRef !== undefined) {
      this.throttledDropCount++;
    }
    window.clearTimeout(this.lastTimeoutRef);
    this.lastTimeoutRef = undefined;

    if (!this.last || !this.options.throttleMs || deltaMs >= this.options.throttleMs) {
      // NO Throttle (large deltaMs OR rps === 0)
      this.last = now;
      const meta = this.createSourceMeta({
        argsCount: args.length,
        throttledDelayMs: this.throttledDelayMs,
        throttledDropCount: this.throttledDropCount
      });
      const payload: Service.ActionChannel.ListenerPayload<P> = {
        meta,
        args
      };
      this.emitter.emit(this.actionName, payload);
      this.throttledDelayMs = 0;
      this.throttledDropCount = 0;
    } else {
      // Do Throttle
      this.throttledDelayMs = this.options.throttleMs - deltaMs;
      this.lastTimeoutRef = window.setTimeout(() => this.send(...args), this.throttledDelayMs);
    }
  }
}

class ActionChannelDestination<A extends string, P extends unknown[]> {
  private readonly emitter: EventEmitter.Emitter;
  private readonly listenersSet: Set<Service.ActionChannel.Listener<P>> = new Set();

  private readonly options = {
    tag: "" // Mark service/store that receiving action
  };

  private initOptions(options?: Service.ActionChannel.DestinationOptions) {
    if (options) {
      this.options.tag = options.tag ? options.tag : this.options.tag;
    }
  }

  constructor(config: {
    actionName: A;
    inject: { emitter: EventEmitter.Emitter };
    options?: Service.ActionChannel.DestinationOptions;
  }) {
    this.actionName = config.actionName;
    this.emitter = config.inject.emitter;

    //
    this.initOptions(config.options || {});
  }

  public readonly actionName: A;

  get listenersCount() {
    return this.listenersSet.size;
  }

  addListener(listener: Service.ActionChannel.Listener<P>) {
    if (!this.listenersSet.has(listener)) {
      this.listenersSet.add(listener);
    }
    this.emitter.on(this.actionName, listener);
  }

  removeListener(listener: Service.ActionChannel.Listener<P>) {
    if (this.listenersSet.has(listener)) {
      this.listenersSet.delete(listener);
    }
    this.emitter.off(this.actionName, listener);
  }

  removeAllListeners() {
    this.listenersSet.forEach(l => this.removeListener(l));
    this.listenersSet.clear();
  }
}

export class ActionsChannelsService {
  private readonly channelsSources: Map<string, Set<ActionChannelSource<string, never>>> = new Map();
  private readonly channelsDestinations: Map<string, Set<ActionChannelDestination<string, never>>> = new Map();

  private readonly emitter = EventEmitter();

  private getChannelsCount(actionName: string, map: Map<string, Set<unknown>>) {
    let count = 0;
    for (const [key, value] of map) {
      if (key === actionName) {
        count = value.size;
        break;
      }
    }
    return count;
  }

  getSourceChannelsCount(actionName: string) {
    return this.getChannelsCount(actionName, this.channelsSources);
  }

  getDestinationChannelsCount(actionName: string) {
    return this.getChannelsCount(actionName, this.channelsDestinations);
  }

  createSource<A extends string, P extends unknown[]>(actionName: A, options?: Service.ActionChannel.SourceOptions) {
    const channelSources = this.channelsSources.get(actionName) || new Set<ActionChannelSource<A, P>>();

    if (!this.channelsSources.has(actionName)) {
      this.channelsSources.set(actionName, channelSources);
    }

    const channelSource = new ActionChannelSource<A, P>({
      actionName,
      inject: {
        emitter: this.emitter,
        getSourcesCount: () => this.getSourceChannelsCount(actionName),
        getDestinationsCount: () => this.getDestinationChannelsCount(actionName)
      },
      options
    });

    channelSources.add(channelSource);

    return channelSource;
  }

  createDestination<A extends string, P extends unknown[]>(
    actionName: A,
    options?: Service.ActionChannel.DestinationOptions
  ) {
    const channelDestinations = this.channelsDestinations.get(actionName) || new Set<ActionChannelDestination<A, P>>();

    if (!this.channelsDestinations.has(actionName)) {
      this.channelsDestinations.set(actionName, channelDestinations);
    }

    const channelDestination = new ActionChannelDestination<A, P>({
      actionName,
      inject: { emitter: this.emitter },
      options
    });

    channelDestinations.add(channelDestination);

    return channelDestination;
  }
}

declare module "../../index" {
  export namespace Service.ActionChannel {
    export type SourceMeta = {
      argsCount: number;
      sourcesCount: number; // For actionName
      destinationsCount: number; // For actionName
      sourceTag?: string;
      throttledDelayMs?: number;
      throttledDropCount?: number;
    };

    export type ListenerPayload<P extends unknown[]> = { args: P; meta: SourceMeta };

    export type Listener<P extends unknown[]> = P extends Parameters<EventEmitter.EventListener>
      ? (p: ListenerPayload<P>) => void
      : never;

    export type SourceOptions = {
      rps?: number; // Requests Per Second
      tag?: string; // Mark service/widget that sending action
    };
    export type DestinationOptions = {
      tag?: string; // Mark service/store that receiving action
    };
  }
}
