/* eslint-disable @typescript-eslint/no-explicit-any */
import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import Pci = Service.Pci;

export function pciService() {
  const http = new HttpReqs(CONF.COMPLIANCE_SERVICE_URL);

  return {
    pciCheck: http.req((p: { data: any }) => ({
      url: `/content/pci/`,
      res: {} as Pci.pciCheckResponse,
      data: p.data,
      err,
      errId: "Service.pci.pciCheck"
    })),
    fetchPciSettings: http.req((p: { orgId: string }) => ({
      url: `/digital-media-pci/organization/${p.orgId}`,
      res: {} as Pci.pciSettingsResponse,
      err,
      errId: "Service.pci.fetchPciSettings"
    })),
    fetchPciAllowedAttachmentTypes: http.req(() => ({
      url: `/validate/pci/supportedFormats`,
      res: {} as Pci.pciAllowedAttachmentTypes,
      err,
      errId: "Service.pci.fetchPciAllowedAttachmentTypes"
    }))
  };
}

declare module "../../index" {
  export namespace Service.Pci {
    type pciCheckResponse = {
      data: {
        content: string;
      };
      status: number;
    };
    type pciCheckRequest = {
      content: string;
    };
    type pciSettingsResponse = {
      action: string;
      isAttachmentEnabled: boolean;
      isChatBannerEnabled: boolean;
    };
    type pciAllowedAttachmentTypes = {
      data: Array<string>;
    };
  }
}

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.pci":
        | "Service.pci.pciCheck"
        | "Service.pci.fetchPciSettings"
        | "Service.pci.fetchPciAllowedAttachmentTypes";
    }
  }
}
