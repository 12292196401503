import { http, logger } from "../core/sdk";
import { shortcutKeyService } from "../services/shortcutKey/shortcutkey-service";
import { ActionsChannelsService } from "./actions-channels/actions-channels-service";
import { absService } from "./agentx-backend-proxy/agentx-backend-proxy-service";
import { AqmService } from "./aqm/aqm-service";
import { cmsService } from "./cms/cms-service";
import { ConfService } from "./conf/conf-service";
import { constant } from "./constant";
import { emmService } from "./emm/emm-service";
import { FeatureFlagService } from "./featureFlag/feature-flag-service";
import { i18nService } from "./i18n/i18n-service";
import { MicrosoftService } from "./integration/microsoft/microsoft-authentication";
import { LogRocketService } from "./logrocket/log-rocket-service";
import { MediaStorage } from "./mediaStorage/media-storage-service";
import { MixPanelService } from "./mixpanel/mix-panel-service";
import { pciService } from "./pci/pci-service";
import { PrometheusService } from "./prometheus/prometheus-tracking";
import { qmwService } from "./qmw/qmw-service";
import { tahoeService } from "./tahoe/tahoe-service";
import { TelemetryService } from "./telemetry/telemetry-service";
import { userPrefsService } from "./userPreferences/user-preferences-service";
import { WebCallingService } from "./webex/web-calling-service";
import { WebexService } from "./webex/webex-service";

export class AgentxServices {
  aqm!: AqmService;
  emm!: ReturnType<typeof emmService>;
  cms!: ReturnType<typeof cmsService>;
  abs!: ReturnType<typeof absService>;
  tahoe!: ReturnType<typeof tahoeService>;
  conf!: ConfService;
  pci!: ReturnType<typeof pciService>;
  mediaStorage!: MediaStorage;
  shortcut!: ReturnType<typeof shortcutKeyService>;
  constants!: ReturnType<typeof constant>;
  actionsChannels!: ActionsChannelsService;
  mixpanel!: MixPanelService;
  webex!: WebexService;
  prometheus!: PrometheusService;
  telemetry!: TelemetryService;
  featureflag!: FeatureFlagService;
  logrocket!: LogRocketService;
  userPrefs!: ReturnType<typeof userPrefsService>;
  webCalling!: WebCallingService;
  qmw!: ReturnType<typeof qmwService>;
  microsoft!: MicrosoftService;

  // Predefined services that are not require init (like i18n - we have to show texts even before login is done)
  i18n = i18nService();

  isIniting = false;
  isInited = false;

  async init(accessToken: string) {
    this.isIniting = true;

    http.accessToken = accessToken;

    if (this.aqm !== undefined) {
      logger.info("aqm service already initialized");
      return;
    }

    const promises: (() => Promise<void>)[] = [];
    this.aqm = new AqmService(i => promises.push(i));
    this.emm = emmService();
    this.cms = cmsService();
    this.abs = absService();
    this.conf = new ConfService(this.aqm, this.abs);
    this.tahoe = tahoeService();
    this.pci = pciService();
    this.mediaStorage = new MediaStorage();
    this.shortcut = shortcutKeyService();
    this.constants = constant();
    this.actionsChannels = new ActionsChannelsService();
    this.webex = new WebexService(accessToken);
    this.userPrefs = userPrefsService();
    this.webCalling = new WebCallingService();
    this.qmw = qmwService();
    this.microsoft = new MicrosoftService(accessToken);

    // fake token is a special case for Jest environment
    if (accessToken !== "fake-token") {
      await Promise.all(promises.map(f => f()));
    }

    this.isIniting = false;
    this.isInited = true;
  }
}
