import { logger } from "../../core/sdk";
import { SERVICE, Service } from "../../index";
import { cacheKeys, MIX_PANEL_ERR_MSG } from "./constants";

const {
  CONSULT_START_TIME,
  CONTACT_ASSIGNED_TIME,
  CUMULATIVE_REC_PAUSED_TIME,
  HOLD_START_TIME,
  PAUSE_REC_COUNT,
  TOTAL_NUMBER_OF_CONSULT,
  CUMULATIVE_CONSULT_TIME,
  CONSULT_TYPE,
  HOLD_COUNT,
  CUMULATIVE_HOLD_TIME,
  PAUSE_REC_START_TIME
} = cacheKeys();

const MEDIA_TYPES = {
  VOICE: "telephony"
};

type DeviceType = null | Service.Conf.LoginOption;
type TrackEvent = {
  eventName: string;
  interactionId: string;
  ani?: string;
  contactDirection: string;
  status?: string;
  mediaType: string;
  timeEvent?: string;
  autoResumed?: boolean;
  reasonForFailure?: string;
  reasonCode?: string | number;
  customerDn?: string;
  ivrConversation?: string;
  outboundType?: string;
  deviceType?: DeviceType;
  dialNumberType?: string;
  mediaChannel?: string;
};

const getCommonProps = (evt: TrackEvent) => {
  return {
    InteractionId: evt.interactionId,
    [SERVICE.telemetry.MIX_PROPS.CALL_DIRECTION]: evt.contactDirection,
    Status: evt.status ? evt.status : SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
    [SERVICE.telemetry.MIX_PROPS.MEDIA_TYPE]: evt.mediaType,
    [SERVICE.telemetry.MIX_PROPS.MEDIA_CHANNEL]: evt.mediaChannel,
    ...(evt.reasonForFailure && { Reason: evt.reasonForFailure }),
    ...(evt.reasonCode && { Reason_Code: evt.reasonCode }),
    ...(evt.ani && { Ani: evt.ani }),
    ...(evt.customerDn && { Customer_DN: evt.customerDn }),
    ...(evt.ivrConversation && { Ivr_Conversation: evt.ivrConversation }),
    ...(evt.outboundType && { Outbound_Type: evt.outboundType }),
    ...(evt.deviceType && { DeviceType: evt.deviceType }),
    ...(evt.dialNumberType && { [SERVICE.telemetry.MIX_PROPS.DIAL_NUMBER_TYPE]: evt.dialNumberType })
  };
};

const getConsultDuration = (): number => {
  let consultStartTime = SERVICE.telemetry.getValueFromCache(CONSULT_START_TIME);
  let currentConsultTm = 0;
  if (consultStartTime) {
    consultStartTime = consultStartTime ? Number(consultStartTime) : new Date().getTime();
    currentConsultTm = (new Date().getTime() - consultStartTime) / 1000;

    const cumulativeConsultStartTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_CONSULT_TIME);
    const totalNumberOfConsult = SERVICE.telemetry.getValueFromCache(TOTAL_NUMBER_OF_CONSULT);

    if (cumulativeConsultStartTime) {
      SERVICE.telemetry.setValueInCache(
        CUMULATIVE_CONSULT_TIME,
        Number(cumulativeConsultStartTime) + Number(currentConsultTm)
      );
      SERVICE.telemetry.setValueInCache(TOTAL_NUMBER_OF_CONSULT, Number(totalNumberOfConsult) + 1);
    } else {
      SERVICE.telemetry.setValueInCache(TOTAL_NUMBER_OF_CONSULT, 1);
      SERVICE.telemetry.setValueInCache(CUMULATIVE_CONSULT_TIME, currentConsultTm);
    }
  }
  return Number(currentConsultTm);
};

export function trackingUtils() {
  return {
    trackCallTransferEvent: (evt: TrackEvent) => {
      try {
        SERVICE.telemetry.track(evt.eventName, {
          ...getCommonProps(evt),
          [SERVICE.telemetry.MIX_PROPS.TRANSFER_TYPE]: SERVICE.telemetry.getValueFromCache(CONSULT_TYPE)
        });
        if (evt.timeEvent) {
          SERVICE.telemetry.timeEvent(evt.timeEvent);
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt, e);
      }
    },

    trackCallEndedEvent: (evt: TrackEvent) => {
      try {
        const contactAssignedTime = SERVICE.telemetry.getValueFromCache(CONTACT_ASSIGNED_TIME);
        let totalCallTime = {};
        let totalConsultTime = {};
        let totalConsult = {};
        if (contactAssignedTime) {
          totalCallTime = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_CALL_TIME]: Number(
              ((new Date().getTime() - Number(contactAssignedTime)) / 1000).toFixed(2)
            )
          };
        }
        const cumulativeConsultStartTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_CONSULT_TIME);
        if (cumulativeConsultStartTime) {
          totalConsultTime = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_CONSULT_TIME]: Number(parseFloat(cumulativeConsultStartTime).toFixed(2))
          };
        }
        const totalNumberOfConsult = SERVICE.telemetry.getValueFromCache(TOTAL_NUMBER_OF_CONSULT);
        if (totalNumberOfConsult) {
          totalConsult = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_CONSULT]: Number(totalNumberOfConsult)
          };
        }

        SERVICE.telemetry.removeValueFromCache(CONTACT_ASSIGNED_TIME);

        const cumulativeHoldTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_HOLD_TIME);
        let cumulativeHoldProps = {};
        let totalNumberOfHold = {};
        if (cumulativeHoldTime) {
          cumulativeHoldProps = { [SERVICE.telemetry.MIX_PROPS.TOTAL_HOLD_TIME]: Number(cumulativeHoldTime) };
          totalNumberOfHold = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_TIMES_IN_HOLD]: Number(SERVICE.telemetry.getValueFromCache(HOLD_COUNT))
          };
        }

        const cumulativeRecPausedTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_REC_PAUSED_TIME);
        let cumulativePauseReqProps = {};
        let totalNumberOfRecResume = {};
        if (cumulativeRecPausedTime) {
          cumulativePauseReqProps = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_RECORDING_PAUSE_TIME]: Number(cumulativeRecPausedTime)
          };
          totalNumberOfRecResume = {
            [SERVICE.telemetry.MIX_PROPS.TOTAL_TIMES_IN_RECORDING_PAUSE]: Number(
              SERVICE.telemetry.getValueFromCache(PAUSE_REC_COUNT)
            )
          };
        }

        SERVICE.telemetry.track(evt.eventName, {
          ...getCommonProps(evt),
          ...totalCallTime,
          ...totalConsultTime,
          ...totalConsult,
          ...cumulativeHoldProps,
          ...totalNumberOfHold,
          ...cumulativePauseReqProps,
          ...totalNumberOfRecResume
        });
        if (evt.timeEvent) {
          SERVICE.telemetry.timeEvent(evt.timeEvent);
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt, e);
      }
    },

    trackCallRecordingEvent: (evt: TrackEvent) => {
      try {
        if (evt.mediaType === MEDIA_TYPES.VOICE) {
          let pauseTime = {};
          let autoResumed = {};
          if (evt.eventName === SERVICE.telemetry.MIX_EVENT.CALL_PAUSE_RECORDING_CLICKED) {
            const pauseRecCount = SERVICE.telemetry.getValueFromCache(PAUSE_REC_COUNT);
            SERVICE.telemetry.setValueInCache(PAUSE_REC_COUNT, pauseRecCount ? Number(pauseRecCount) + 1 : 1);
          } else if (evt.eventName === SERVICE.telemetry.MIX_EVENT.CALL_RESUME_RECORDING_ACTIVATED) {
            const startTime = SERVICE.telemetry.getValueFromCache(PAUSE_REC_START_TIME);

            if (startTime) {
              const totalPausedTime = ((new Date().getTime() - Number(startTime)) / 1000).toFixed(2);
              const cumulativeRecPausedTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_REC_PAUSED_TIME);
              if (cumulativeRecPausedTime) {
                SERVICE.telemetry.setValueInCache(
                  CUMULATIVE_REC_PAUSED_TIME,
                  Number(cumulativeRecPausedTime) + Number(totalPausedTime)
                );
              } else {
                SERVICE.telemetry.setValueInCache(CUMULATIVE_REC_PAUSED_TIME, totalPausedTime);
              }
              pauseTime = {
                [SERVICE.telemetry.MIX_PROPS.PAUSE_RECORDING_TIME]: Number(totalPausedTime)
              };
            }

            autoResumed = { AutoResumed: evt.autoResumed };
          }

          SERVICE.telemetry.track(evt.eventName, {
            ...getCommonProps(evt),
            ...pauseTime,
            ...autoResumed
          });

          if (evt.timeEvent) {
            SERVICE.telemetry.timeEvent(evt.timeEvent);
          }
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt, e);
      }
    },

    trackWrapUpAppliedEvent: (evt: TrackEvent) => {
      try {
        SERVICE.telemetry.track(evt.eventName, {
          ...getCommonProps(evt)
        });
        if (evt.timeEvent) {
          SERVICE.telemetry.timeEvent(evt.timeEvent);
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt, e);
      }
    },

    trackInitialCallEvents: (evt: TrackEvent) => {
      try {
        SERVICE.telemetry.track(evt.eventName, {
          ...getCommonProps(evt)
        });
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt.eventName, e);
      }
    },

    trackHoldEvents: (evt: TrackEvent) => {
      try {
        if (evt.mediaType === MEDIA_TYPES.VOICE) {
          let totalHoldTimeProps = {};
          if (evt.eventName === SERVICE.telemetry.MIX_EVENT.CALL_HOLD_CLICKED) {
            SERVICE.telemetry.setValueInCache(HOLD_START_TIME, new Date().getTime());
            const holdCount = SERVICE.telemetry.getValueFromCache(HOLD_COUNT);
            SERVICE.telemetry.setValueInCache(HOLD_COUNT, holdCount ? Number(holdCount) + 1 : 1);
          } else if (evt.eventName === SERVICE.telemetry.MIX_EVENT.CALL_RESUME_ACTIVATED) {
            const holdStartTime = SERVICE.telemetry.getValueFromCache(HOLD_START_TIME);

            if (holdStartTime) {
              const totalHoldTime = ((new Date().getTime() - holdStartTime) / 1000).toFixed(2);
              totalHoldTimeProps = {
                [SERVICE.telemetry.MIX_PROPS.TOTAL_HOLD_TIME]: Number(totalHoldTime)
              };
              const cumulativeHoldTime = SERVICE.telemetry.getValueFromCache(CUMULATIVE_HOLD_TIME);
              if (cumulativeHoldTime) {
                SERVICE.telemetry.setValueInCache(
                  CUMULATIVE_HOLD_TIME,
                  Number(cumulativeHoldTime) + Number(totalHoldTime)
                );
              } else {
                SERVICE.telemetry.setValueInCache(CUMULATIVE_HOLD_TIME, totalHoldTime);
              }
              SERVICE.telemetry.removeValueFromCache(HOLD_START_TIME);
            }
          }

          SERVICE.telemetry.track(evt.eventName, {
            ...getCommonProps(evt),
            ...totalHoldTimeProps
          });

          if (evt.timeEvent) {
            SERVICE.telemetry.timeEvent(evt.timeEvent);
          }
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, evt.eventName, e);
      }
    },

    trackConsultEvent: (event: TrackEvent) => {
      try {
        let consultDuration = {};
        if (event.eventName === SERVICE.telemetry.MIX_EVENT.CALL_CONSULT_END_ACTIVATED) {
          const consultDur = getConsultDuration();
          if (consultDur > 0) {
            consultDuration = { [SERVICE.telemetry.MIX_PROPS.CONSULT_DURATION]: consultDur };
          }
        }
        SERVICE.telemetry.track(event.eventName, {
          ...getCommonProps(event),
          [SERVICE.telemetry.MIX_PROPS.CONSULT_TYPE]: SERVICE.telemetry.getValueFromCache(CONSULT_TYPE),
          ...consultDuration
        });

        if (event.timeEvent) {
          SERVICE.telemetry.timeEvent(event.timeEvent);
        }
      } catch (e) {
        logger.warn(MIX_PANEL_ERR_MSG, event, e);
      }
    },
    clearCache: () => {
      SERVICE.telemetry.removeValueFromCache(CUMULATIVE_CONSULT_TIME);
      SERVICE.telemetry.removeValueFromCache(TOTAL_NUMBER_OF_CONSULT);
      SERVICE.telemetry.removeValueFromCache(CONSULT_START_TIME);
      SERVICE.telemetry.removeValueFromCache(CUMULATIVE_HOLD_TIME);
      SERVICE.telemetry.removeValueFromCache(CUMULATIVE_REC_PAUSED_TIME);
      SERVICE.telemetry.removeValueFromCache(HOLD_COUNT);
      SERVICE.telemetry.removeValueFromCache(CONSULT_TYPE);
      SERVICE.telemetry.removeValueFromCache(PAUSE_REC_START_TIME);
    }
  };
}
