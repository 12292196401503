import { Err } from "@uuip/unified-ui-platform-sdk";
import { AqmReqs, Msg } from "../../core/aqm-reqs";
import { logger } from "../../core/sdk";
import { Service } from "../../index";
import Configs = Service.Aqm.Configs;

/*
 * aqmConfigs
 * @param reqs
 * @category AQM Service
 */
export function aqmConfigs(aqm: AqmReqs) {
  const applicationErrorID = "Service.aqm.configs.application";
  return {
    application: aqm.reqEmpty(() => ({
      url: "/application",
      notifSuccess: {
        bind: {
          type: "AgentPropertyChanged",
          data: { jsMethod: "agentPropertyChanged" }
        },
        msg: {} as Configs.AgentPropertyChanged
      },
      notifFail: {
        bind: {
          type: "AgentConfigFailed"
        },
        errId: applicationErrorID
      },
      err: /* istanbul ignore next */ (e: any) => {
        logger.error("Error in making /application request:", e);
        return new Err.Details(applicationErrorID, {
          status: e.response?.status ?? 0,
          type: e.response?.data?.errorType,
          trackingId: e.response?.headers?.trackingid?.split("_")[1]
        });
      }
    })),

    eUpdateAgentProfile: aqm.evt({
      bind: {
        type: "UpdateAgentProfile",
        data: { jsMethod: "updateAgentProfile" }
      },
      msg: {} as Configs.UpdateAgentProfile
    })
  };
}

type ErrDetails = { status: number; type: string; trackingId: string };

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.aqm.configs": { "Service.aqm.configs.application": ErrDetails; "Service.aqm.configs.teams": ErrDetails };
    }
  }
}

declare module "../../index" {
  export namespace Service.Aqm.Configs {
    type WrapupData = {
      wrapUpProps: {
        autoWrapup: boolean | undefined;
        autoWrapupInterval: number | undefined;
        lastAgentRoute: boolean | undefined;
        wrapUpReasonList: Array<WrapUpReason>;
        wrapUpCodesList?: Array<string>;
        idleCodesAccess?: "ALL" | "SPECIFIC";
        interactionId: string;
        allowCancelAutoWrapup: boolean | undefined;
      };
    };

    type AgentPropertyChanged = Msg<{
      data: {
        "std.teamIdsCSV": string;
        protocolVersion: string;
        "std.siteName": string;
        "std.enterpriseId": string;
        "std.status": string;
        "std.defaultDn": string;
        "std.userRealName": string;
        "std.teamNamesCSV": string;
        "std.agentName": string;
        skillProfileId: string;
        agentProfileIDsCSV: string;
        attribute: { name: string; value: string }[];
        "std.agentId": string;
        "std.siteId": string;
        "std.subStatus": string;
        lostConnectionRecoveryTimeout: string;
        cadVariables: Configs.CadVariables[];
        callVariablesSuppressed: Configs.CallVariablesSuppressed;
        agentAnalyzerId: string;
      };
      jsMethod: "agentPropertyChanged";
      callData: string;
      agentSessionId: string;
    }>;

    type UpdateAgentProfile = Msg<{
      data: {
        "agent-profile": {
          xmlns: string;
          entityList: { type: string; entity: Entity[] }[];
          dialPlan: DialPlan;
          outDialEp: string;
          "profile-attribute": { name: string; value: string }[];
          wrapUpData: Configs.WrapupData;
        };
      };
      jsMethod: "updateAgentProfile";
      agentSessionId: string;
    }>;

    type WrapUpReason = {
      isSystem: boolean;
      name: string;
      id: string;
      isDefault: boolean;
    };

    type CadVariables = {
      name: string;
      status: number;
      agentEditable: boolean;
      agentViewable?: boolean;
    };

    type CallVariablesSuppressed = string[];

    // ----------

    type Entity = { isSystem: boolean; name: string; id: string; isDefault: boolean };
    type AuxCodeType = "IDLE_CODE" | "WRAP_UP_CODE";
    type DefaultAuxCodes = Entity & {
      workTypeCode: AuxCodeType;
    };
    type agentStateIdleCodes = {
      data: Entity[];
      meta: {
        links: { first: string; last: string; next: string; self: string };
        orgid: string;
        page: number;
        pageSize: number;
        totalPages: number;
        totalRecords: number;
      };
    };
    type DialPlan = {
      type: "adhocDial";
      dialPlanEntity: { regex: string; prefix: string; strippedChars: string; name: string }[];
    };

    type Channels = "telephony" | "chat" | "email" | "social";
    type ChannelList = { name: string; value: Channels }[];
  }
}

// JS DOC Comments - Type Definitions

/**
 * @typedef {Object} Service_Aqm_Configs_Entity
 * @summary Service.Aqm.Configs.Entity
 * @property {Boolean} isSystem Determines whether the entity is generated by the system  or configured by the administrator.
 * @property {String} name Name of the entity.
 * @property {String} id Unique identifier for the entity.
 * @property {Boolean} isDefault Determines whether the entity (idle code) is default or not.
 * The default entity (idle code) will be used as the global state of the agent on initial login.
 */

/**
 * @typedef {Object} Service_Aqm_Configs_WrapupData
 * @summary Service.Aqm.Configs.WrapupData
 * @property {Object} wrapUpProps Wrap up properties.
 * @property {Boolean|Undefined} wrapUpProps.autoWrapup=false Determines whether auto wrap up is enabled or not.
 * @property {Number|Undefined} wrapUpProps.autoWrapupInterval=0 Auto wrap up interval.
 * @property {Array<Service_Aqm_Configs_WrapUpReason>} wrapUpProps.wrapUpReasonList=[]] Wrap up reason list.
 * @property {String} wrapUpProps.interactionId="" Unique identifier of the user interaction.
 * @property {Boolean|Undefined} wrapUpProps.allowCancelAutoWrapup=false Determines whether auto wrap up cancellation is allowed or not.
 */

/**
 * @typedef {Object} Service_Aqm_Configs_WrapUpReason
 * @summary Service.Aqm.Configs.WrapUpReason
 * @property {Boolean} isSystem Determines whether the wrap up reason is generated by the system  or configured by the administrator.
 * @property {String} name Name of the wrap up reason.
 * @property {String} id Unique Identifier for the wrap up reason.
 * @property {Boolean} isDefault Determines whether the wrap up reason is default or not.
 * The default wrap up reason is used in the case of the auto wrap up.
 */
