import { Msg } from "../../core/aqm-reqs";
import { Service } from "../../index";
import { DESTINATION_TYPE } from "./constants";
import Failure = Service.Aqm.Global.Failure;

type MEDIA_CHANNEL =
  | "email"
  | "chat"
  | "telephony"
  | "social"
  | "sms"
  | "facebook"
  | "whatsapp"
  | "appleMessages"
  | "googleMessages"
  | string;

type destinationTypeV2 =
  | DESTINATION_TYPE.AGENT
  | DESTINATION_TYPE.QUEUE
  | DESTINATION_TYPE.DIALNUMBER
  | DESTINATION_TYPE.EPDN
  | DESTINATION_TYPE.ENTRYPOINT;

type vteamType = "inboundqueue" | "inboundentrypoint" | string;

type contactErrorIds =
  | { "Service.aqm.contact.accept": Failure }
  | { "Service.aqm.contact.end": Failure }
  | { "Service.aqm.contact.wrapup": Failure }
  | { "Service.aqm.contact.AgentVteamTransferFailed": Failure }
  | { "Service.aqm.contact.AgentBlindTransferFailedEvent": Failure }
  | { "Service.aqm.contact.AgentConsultTransferFailed": Failure }
  | { "Service.aqm.contact.consult": Failure }
  | { "Service.aqm.err.trackingId": { trackingId: string } }
  | { "Service.aqm.contact.consultAccept": Failure }
  | { "Service.aqm.contact.consultConference": Failure }
  | { "Service.aqm.contact.consultEnd": Failure }
  | { "Service.aqm.contact.cancelCtq": Failure }
  | { "Service.aqm.contact.hold": Failure }
  | { "Service.aqm.contact.unHold": Failure }
  | "Service.aqm.contact.fetchTranscript"
  | "Service.aqm.contact.fetchHistoryByANI"
  | "Service.aqm.contact.fetchHistoryByAgent"
  | { "Service.aqm.contact.BuddyAgentsRetrieveFailed": Failure }
  | { "Service.aqm.contact.VteamListFailed": Failure }
  | { "Service.aqm.contact.pauseRecording": Failure }
  | { "Service.aqm.contact.resumeRecording": Failure }
  | { "Service.reqs.generic.failure": { trackingId: string } };

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.aqm.contact": contactErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Aqm.Contact {
    type HistoryByANI = {
      contacts: Contact[];
    };

    type HistoryByAgent = {
      contacts: Contact[];
    };

    type ContactCallbackFailed = Msg<{
      orgId: string;
      queueId: string;
      reason: string;
      reasonCode: number;
      trackingId: string;
      interactionId: string;
      interaction: Interaction;
      agentId: string;
      terminatingParty: string;
    }>;

    type AgentOfferCampaign = Msg<{
      eventType: string;
      interactionId: string;
      orgId: string;
      queueMgr: string;
      mediaResourceId: string;
      campaignId: string;
      agentId: string;
      ronaTimeout?: number;
      agentChannelId: string;
      interaction: Interaction;
      trackingId: string;
      eventTime: number;
      type: string;
    }>;

    type AgentAddCampaign = Msg<{
      eventType: string;
      interactionId: string;
      orgId: string;
      queueMgr: string;
      campaignId: string;
      agentId: string;
      agentChannelId: string;
      interaction: Interaction;
      trackingId: string;
      mediaType: string;
      eventTime: number;
      type: string;
    }>;

    type AgentContact = Msg<{
      mediaResourceId: string;
      eventType: string;
      eventTime?: number;
      agentId: string;
      destAgentId: string;
      trackingId: string;
      consultMediaResourceId: string;
      interaction: Interaction;
      participantId?: string;
      fromOwner?: boolean;
      toOwner?: boolean;
      childInteractionId?: string;
      interactionId: string;
      orgId: string;
      owner: string;
      queueMgr: string;
      queueName?: string;
      type: string;
      ronaTimeout?: number;
      isConsulted?: boolean;
      isConferencing: boolean;
      updatedBy?: string;
      destinationType?: string;
      autoResumed?: boolean;
      reasonCode?: string | number;
      reason?: string;
      consultingAgentId?: string;
      taskId?: string;
      task?: Interaction;
      supervisorId?: string;
      monitorType?: string;
      supervisorDN?: string;
      id?: string; // unique id in monitoring offered event
      isWebCallMute?: boolean;
      reservationInteractionId?: string;
      reservedAgentChannelId?: string;
      monitoringState?: {
        type: string;
      };
      supervisorName?: string;
    }>;

    // -----------------

    type Contact = {
      /** Contact start time in timestamp */
      cstts: string;
      /** Contact end time in timestamp */
      cetts: string;
      /** talk duration in timestamp */
      talkDuration: number;
      agentName: string;
      /** entry point of the contact */
      entrypointName?: string;
      /** Channel type pof the contact e.g. email|chat|telephony */
      channelType: string;
      /** ani of the customer */
      ani: string;
      displayAni: string;
      sid: string;
      /** transcript id */
      transcript?: string;
      /** outbound transcript id */
      outboundTranscript?: string;
      terminationType?: string;
      /** Contact Subject */
      subject?: string;
      customerName: string;
      dnis: string;
      callDirection: string;
      subChannelType?: string;
      wrapUpCode: string;
      isCallback?: boolean;
      outdialType?: string;
    };

    type BuddyDetails = {
      agentId: string;
      state: string;
      teamId: string;
      dn: string;
      agentName: string;
      siteId: string;
    };

    type BuddyAgents = {
      agentProfileId: string;
      /** Channel type of the contact e.g. email|chat|telephony */
      channelName: string;
      /** Filter for agent state eg : Available | Idle  */
      state?: string;
      /** Optional id  for tracking purpose */
      trackingId?: string;
    };

    type BuddyAgentsV2 = {
      agentProfileId: string;
      mediaType: string;
      /** Filter for agent state eg : Available | Idle  */
      state?: string;
    };

    type BuddyAgentsSuccess = Msg<{
      eventType: string;
      agentId: string;
      /** Optional id  for tracking purpose */
      trackingId: string;
      agentSessionId: string;
      orgId: string;
      /** Current state of agents for eg: Available | Idle */
      type: string;
      agentList: Array<BuddyDetails>;
    }>;

    type VTeam = {
      agentProfileId: string;
      agentSessionId: string;
      channelType: string;
      type: string;
      trackingId?: string;
    };

    type VteamDetails = {
      name: string;
      channelType: string;
      id: string;
      type: string;
      analyzerId: string;
    };

    type VTeamSuccess = Msg<{
      data: {
        vteamList: Array<VteamDetails>;
        allowConsultToQueue: boolean;
      };
      jsMethod: string;
      callData: string;
      agentSessionId: string;
    }>;

    type Interaction = {
      isFcManaged: boolean;
      isTerminated: boolean;
      mediaType: MEDIA_CHANNEL;
      previousVTeams: string[];
      state: string;
      currentVTeam: string;
      participants: any; // todo
      interactionId: string;
      orgId: string;
      createdTimestamp?: number;
      isWrapUpAssist?: boolean;

      callProcessingDetails: {
        QMgrName: string;
        taskToBeSelfServiced: string;
        ani: string;
        displayAni: string;
        dnis: string;
        tenantId: string;
        QueueId: string;
        vteamId: string;
        pauseResumeEnabled?: string;
        pauseDuration?: string;
        isPaused?: string;
        recordInProgress?: string;
        recordingStarted?: string;
        ctqInProgress?: string;
        outdialTransferToQueueEnabled?: string;
        convIvrTranscript?: string;
        customerName: string;
        virtualTeamName: string;
        ronaTimeout: string;
        category: string;
        reason: string;
        sourceNumber: string;
        sourcePage: string;
        appUser: string;
        customerNumber: string;
        reasonCode: string;
        IvrPath: string;
        pathId: string;
        fromAddress: string;
        parentInteractionId?: string;
        childInteractionId?: string;
        relationshipType?: string;
        parent_ANI?: string;
        parent_DNIS?: string;
        consultDestinationAgentJoined?: boolean | string;
        consultDestinationAgentName?: string;
        parent_Agent_DN?: string;
        parent_Agent_Name?: string;
        parent_Agent_TeamName?: string;
        isConferencing?: string;
        monitorType?: string;
        workflowName?: string;
        workflowId?: string;
        monitoringInvisibleMode?: string;
        monitoringRequestId?: string;
        participantInviteTimeout?: string;
        mohFileName?: string;
        CONTINUE_RECORDING_ON_TRANSFER?: string;
        EP_ID?: string;
        ROUTING_TYPE?: string;
        fceRegisteredEvents?: string;
        isParked?: string;
        priority?: string;
        routingStrategyId?: string;
        monitoringState?: string;
        BLIND_TRANSFER_IN_PROGRESS?: boolean;
        fcDesktopView?: string;
      };
      mainInteractionId?: string;
      media: Record<
        string,
        {
          mediaResourceId: string;
          mediaType: MEDIA_CHANNEL;
          mediaMgr: string;
          participants: string[];
          mType: string;
          isHold: boolean;
          holdTimestamp: number | null;
        }
      >;
      owner: string;
      mediaChannel: MEDIA_CHANNEL;
      contactDirection: { type: string };
      outboundType?: string;
      callFlowParams: Record<
        string,
        {
          name: string;
          qualifier: string;
          description: string;
          valueDataType: string;
          value: string;
        }
      >;
      callAssociatedData?: Record<string, callAssociatedData>;
    };

    type WrapupPayLoad = {
      wrapUpReason: string;
      auxCodeId: string;
      isAutoWrapup: string;
    };

    type WrapupV2PayLoad = {
      wrapUpReason: string;
      auxCodeId: string;
    };

    type ConsultPayload = {
      agentId: string;
      destAgentId: string | undefined;
      mediaType?: string;
      holdParticipants?: boolean;
    };

    type ConsultAgent = {
      agentId: string;
      destAgentId: string;
      destAgentDN?: string;
      destAgentTeamId?: string;
      destSiteId?: string;
      mediaType: string;
      trackingId: string;
      holdParticipants?: boolean;
    };

    type ConsultQueue = {
      agentId: string;
      queueId: string;
      trackingId: string;
      holdParticipants?: boolean;
    };

    type ConsultDN = {
      destAgentId: string;
      destinationType: string;
      mediaType: string;
      trackingId?: string;
      holdParticipants?: boolean;
    };

    type vteamTransferPayLoad = {
      vteamId: string;
      vteamType: vteamType;
    };

    type blindTransferPayLoad = {
      agentId: string;
      destAgentId: string;
      mediaType: MEDIA_CHANNEL;
      destAgentTeamId: string;
      destAgentDN: string;
      destSiteId: string;
    };

    type transferV2Payload = {
      to: string;
      destinationType: destinationTypeV2;
    };

    type cancelCtq = {
      agentId: string;
      queueId: string;
    };

    type consultTransferPayLoad = {
      agentId?: string;
      destAgentId: string;
      mediaType: MEDIA_CHANNEL;
      mediaResourceId: string;
      destinationType?: string;
    };

    type declinePayload = {
      mediaResourceId: string;
    };

    type callAssociatedData = {
      displayName: string;
      name: string;
      agentEditable: boolean;
      agentViewable: boolean;
      value: string | cadOrderData;
      type: string;
      isSecure: boolean;
      secureKeyId: string;
      secureKeyVersion: number;
    };

    type cadOrderData = {
      "pop-over": variableSeqObject[];
      "interaction-panel": variableSeqObject[];
    };

    type variableSeqObject = { name: string; variableSeq: string };

    type warnOnNavigation = {
      [key: string]: boolean;
    };

    type consultData = Contact.ConsultPayload | Contact.ConsultDN | Contact.ConsultAgent | Contact.ConsultQueue;

    type consultDataV2 = {
      to: string | undefined;
      destinationType: string;
      holdParticipants?: boolean;
    };

    type consultConferenceDataV2 = {
      agentId?: string;
      to: string | undefined;
      destinationType: string;
    };

    type consultConferenceData = {
      agentId: string;
      destinationType: string;
      destAgentId: string;
      mediaType: string;
    };

    type ContactCleanupData = {
      type: string;
      orgId: string;
      agentId: string;
      data: {
        eventType: string;
        interactionId: string;
        orgId: string;
        mediaMgr: string;
        trackingId: string;
        mediaType: string;
        destination?: string;
        broadcast: boolean;
        type: string;
      };
    };

    type AgentMonitorStateChanged = Msg<{
      orgId: string;
      interactionId: string;
      supervisorId: string;
      supervisorName: string;
      agentId: string;
      type: string;
      interaction: Interaction;
      trackingId: string;
      eventType: string;
      eventTime: number;
      monitoringState: {
        type: string;
      };
    }>;

    type AgentMonitorStateChangeFailed = Msg<{
      orgId: string;
      interactionId: string;
      supervisorId: string;
      agentId: string;
      trackingId: string;
      type: string;
      eventType: string;
      eventTime: number;
      monitoringState: {
        type: string;
      };
      reason: string;
      reasonCode: number;
    }>;

    type AgentMonitorEnded = {
      trackingId: string;
      type: string;
      orgId: string;
      agentId: string;
      data: {
        agentId: string;
        eventTime: string;
        eventType: string;
        id: string;
        monitorType: string;
        monitoringState: {
          type: string;
        };
        orgId: string;
        supervisorId: string;
        taskId: string;
        trackingId: string;
        type: string;
      };
    };
  }
}

// JS DOC Comments - Type Definitions

/**
 * @typedef  {Object} Service_Aqm_Contact_AgentContact_Data
 * @summary Service.Aqm.Contact.AgentContact.data
 * @property {String} mediaResourceId Unique identifier of the digital media.
 * @property {String} agentId Unique identifier of the agent.
 * @property {String} destAgentId Unique identifier of the destination/secondary agent.
 * @property {String} trackingId Unique identifier for tracking the API request.
 * @property {String} consultMediaResourceId Unique identifier for the digital media.
 * @property {Service_Aqm_Contact_Interaction} interaction Details for the user interaction.
 * @property {String} interactionId Unique identifier of the user interaction.
 * @property {String} orgId Unique identifier of the organization.
 * @property {String} owner Unique identifier for the agent who owns the task.
 * @property {String} queueMgr Queue Manager.
 * @property {Number} ronaTimeout RONA Timeout.
 * @property {String} type EventName which describes the unique notifs response that are used to the attach listener for web socket response.
 * @property {Boolean} isConsulted Determines whether the agent is consulted or not.
 * @property {Boolean} isConferencing Determines whether the agent is in a conference call or not.
 * @property {String} updatedBy Unique identifier of the agent who updated CAD variables of the task.
 * @property {String} destinationType The type of the request to be placed. The destination type can be Agent, Queue, or DN.
 * @property {Boolean} autoResumed Determines whether the recording resumed automatically or not.
 * @property {String} reasonCode Reason code for the error.
 * @property {String} reason Reason for the error.
 */

/**
 * @typedef {Object} Service_Aqm_Contact_Interaction
 * @summary Service.Aqm.Contact.Interaction
 * @property {MEDIA_CHANNEL} mediaType Determines the type of media. The possible values are email, chat, telephony, social, sms, whatsapp , appleMessages or facebook.
 * @property {String} state Determines the current contact state.
 * @property {Any} participants Provides list of participants with details for each participant.
 * @property {String} interactionId Unique identifier of the user interaction.
 * @property {String} orgId Unique identifier of the organization.
 * @property {Object} callProcessingDetails Call processing details.
 * @property {String} callProcessingDetails.ani
 * @property {String} callProcessingDetails.displayAni
 * In case of inbound for telephony it (Automatic Number Identification) determines number of the customer while for chat and email it determines the email of the customer.
 * @property {String} callProcessingDetails.dnis In case of outbound it (Dialed Number Identification) determines the number of the customer care.
 * @property {String} callProcessingDetails.tenantId Database unique identifier of the organization.
 * @property {String} callProcessingDetails.QueueId Unique identifier of the routing queue.
 * @property {String} callProcessingDetails.vteamId Unique identifier of the virtual team.
 * @property {String} callProcessingDetails.pauseResumeEnabled
 * Determines if you want the agent to have access to the Privacy Shield icon during call recording so that the agent can pause and resume recording.
 * @property {String} callProcessingDetails.pauseDuration
 * Provides the number of seconds that recording will be paused if the user does not click the Turn Off Privacy Shield button.
 * @property {String} callProcessingDetails.isPaused Determines whether call is paused or not.
 * @property {String} callProcessingDetails.recordInProgress Determines whether recording is in progress or not.
 * @property {String} callProcessingDetails.ctqInProgress Determines where consult to queue call is in progress or not.
 * @property {String} callProcessingDetails.outdialTransferToQueueEnabled Determines whether outdial transfer to queue is in enabled or not.
 * @property {String} callProcessingDetails.convIvrTranscript Determines whether IVR transcript is available for current user interaction or not.
 * @property {String} callProcessingDetails.customerName Name of the customer.
 * @property {String} callProcessingDetails.virtualTeamName Name of the virtual team.
 * @property {String} callProcessingDetails.ronaTimeout RONA timeout.
 * @property {String} callProcessingDetails.category The value selected by the customer while initiating a chat.
 * @property {String} callProcessingDetails.reason The reason for the customer contact.
 * @property {String} callProcessingDetails.sourceNumber Source number.
 * @property {String} callProcessingDetails.sourcePage Source page.
 * @property {String} callProcessingDetails.appUser Name of the customer for the social channel.
 * @property {String} callProcessingDetails.customerNumber Number of the customer.
 * @property {String} callProcessingDetails.reasonCode The reason code for the customer contact.
 * @property {String} callProcessingDetails.IvrPath IVR path.
 * @property {String} callProcessingDetails.fromAddress Email ID of the customer (inbound email).
 * @property {String} callProcessingDetails.parentInteractionId Parent interactionId for EP-DN secondary interacion.
 * @property {String} callProcessingDetails.childInteractionId Child InteracionId of secondary interaction.
 * @property {String} callProcessingDetails.relationshipType parent relationship type ex: consult.
 * @property {String} callProcessingDetails.parent_ANI Parent ani for secondary agent for EP-DN Consult.
 * @property {String} callProcessingDetails.parent_DNIS parent dnis for secondary agent for EP-DN Consult.
 * @property {Record<String>} media
 * @property {String} media.mediaResourceId Unique identifier of the digital media.
 * @property {MEDIA_CHANNEL} media.mediaType Determines the type of media. The possible values are email, chat, telephony, social, sms, whatsapp or facebook.
 * @property {String} media.mediaMgr Media manager.
 * @property {Array<String>} media.participants List of the participants in the digital media.
 * @property {String} media.mType Identifies whether the media is coming from mail call or consult call.
 * @property {Boolean} media.isHold Determines whether the call is on hold or not.
 * @property {Number|null} media.holdTimestamp The timestamp when the call is put on hold.
 * @property {String} owner Unique identifier for the agent who owns the interaction.
 * @property {MEDIA_CHANNEL} mediaChannel Determines the type of media. The possible values are email, chat, telephony, social, sms, whatsapp, appleMessages or facebook.
 * @property {Object} contactDirection Direction of call from a dialer to recipient.
 * @property {String} contactDirection.type Type of the direction of call from a dialer to recipient. Possible values are OUTBOUND or INBOUND.
 * @property {String} outboundType  Tells if the outbound call is normal outdial call or preview campaign call or courtesy call back call.
 * @property {Record<String>} callFlowParams Call flow parameters.
 * @property {String} callFlowParams.name Name of the call flow parameter.
 * @property {String} callFlowParams.description Description of the call flow parameter.
 * @property {String} callFlowParams.valueDataType Data type for the value of the call flow parameter.
 * @property {String} callFlowParams.value Value for the call flow parameter.
 * @property {Record.<string, Service_Aqm_Contact_callAssociatedData>} callAssociatedData Record of Call - Associated Data (CAD) variables.
 */

/**
 * @typedef {Object} Service_Aqm_Contact_callAssociatedData
 * @summary Service.Aqm.Contact.callAssociatedData
 * @description Call - Associated Data (CAD) variables.
 * @property {String} displayName Label for the CAD variable.
 * @property {String} name Key for the CAD variable.
 * @property {Boolean} agentEditable Determines whether the value of the CAD variable can be edited by the agent or not.
 * @property {Boolean} agentViewable Determines whether the CAD variable can be displayed to the agent or not.
 * @property {String} value Value of the CAD variable.
 * @property {String} type Determines the type of the value for the CAD variable. It can be boolean, string, integer, decimal, or date.
 */

/**
 * @typedef {String} MEDIA_CHANNEL
 * @summary Private: aqm.service.contact
 * @description Determines the type of media. The possible values are email, chat, telephony, social, sms, whatsapp, appleMessages or facebook.
 */
