import "@webex/internal-plugin-dss";

import { Signal } from "@uuip/unified-ui-platform-sdk";
import { DateTime } from "luxon";
import Webex from "webex";
import { logger } from "../../core/sdk";
import { SERVICE, Service, getTrackingIdFromErrorObject } from "../../index";
import { NON_PROD_WEBEX_URL, PRESENCE_MERCURY_REG, RETRY_TIMEOUT } from "../constant";

export class WebexService {
  private readonly accessToken: string = "";
  private readonly newMessageSignal = Signal.create.withData<Service.Webex.NewMessage>();
  private readonly newMeetingEventSignal = Signal.create.withData<Service.Webex.NewMeetingEvent>();
  private readonly newPresenceEventSignal = Signal.create.withData<Service.Webex.NewPresenceEvent>();
  private readonly newWebexChatActionDone = Signal.create.withData<Service.Webex.WebexChatEvent>();
  public onNewMessage: Signal.WithData<Service.Webex.NewMessage> = this.newMessageSignal.signal;
  public onNewMeetingEvent: Signal.WithData<Service.Webex.NewMeetingEvent> = this.newMeetingEventSignal.signal;
  public onNewPresenceEvent: Signal.WithData<Service.Webex.NewPresenceEvent> = this.newPresenceEventSignal.signal;
  public onWebexChatActionDone: Signal.WithData<Service.Webex.WebexChatEvent> = this.newWebexChatActionDone.signal;
  private readonly notificationCountUpdateSignal = Signal.create.withData<number>();
  public onNotificationCountUpdate: Signal.WithData<number> = this.notificationCountUpdateSignal.signal;
  private readonly onNewPresenceStateSyncEventSignal = Signal.create.withData<
    Service.Webex.NewPresenceStateSyncEvent
  >();
  public onNewPresenceStateSyncEvent: Signal.WithData<Service.Webex.NewPresenceStateSyncEvent> = this
    .onNewPresenceStateSyncEventSignal.signal;

  private readonly presenceUpdateSignal = Signal.create.withData<Service.Webex.Presence[]>();
  public onPresenceUpdateEvent: Signal.WithData<Service.Webex.Presence[]> = this.presenceUpdateSignal.signal;

  private webex: any;
  private mercury: any;
  private device: any;
  private dss: any;
  private presence: any;
  private timeout = 0;
  private webexInitialized = false;
  private deviceRegisterRetry = true;
  private mercuryConnectRetry = true;

  private webexNotificationErrorCount = 0;

  private presences: Service.Webex.PresenceData = {};

  public webexInitializedStatus() {
    return this.webexInitialized;
  }

  private readonly maxWebexNotificationErrorCount = 3;
  async registerWebex(isProd: boolean): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.webexInitialized) {
          logger.info("[Webex] : Webex already initialized!");
          resolve();
          return;
        }
        const webexConfig: any = {
          config: {
            meetings: {
              reconnection: {
                enabled: true
              },
              enableRtx: true
            },
            presence: { initializeWorker: true }
          },
          credentials: {
            access_token: this.accessToken
          }
        };

        if (!isProd) {
          logger.info("[Webex]: event=webexConfigForBts | webex configured for bts env");
          webexConfig.config = {
            services: {
              discovery: {
                u2c: NON_PROD_WEBEX_URL.U2C,
                hydra: NON_PROD_WEBEX_URL.HYDRA
              }
            },
            presence: { initializeWorker: true }
          };
        }

        this.webex = (window as any).webexService = Webex.init(webexConfig);
        this.mercury = this.webex.internal.mercury;
        this.device = this.webex.internal.device;
        this.dss = this.webex.internal.dss;
        this.presence = this.webex.internal.presence;
        this.webex.once("ready", () => {
          this.webexInitialized = true; //To avoid multiple call to webex ready
          logger.info("[Webex] : event=webexReady | Webex Ready");
          resolve();
        });
      } catch (error) {
        this.webexInitialized = false;
        logger.error("[Webex] : Error while initializing", error);
        reject(error);
      }
    });
  }

  public registerWebexEvents() {
    logger.info("[Webex] : event=registerWebexEvents | Registering Webex Events");
    this.listenForIncomingMeetings();
    this.handleWebexMessageEvents();
    this.handleUnreadConversations();
    this.registerWebexMeeting();
  }

  private registerWebexMeeting() {
    if (!this.webex.meetings.registered) {
      this.webex.meetings
        .register()
        .then(() => {
          this.webex.meetings.syncMeetings().then(() => {
            logger.info("event=webexMeetingSyncSuccess | Webex Meeting Sync Success");
          });
        })
        .catch((err: any) => {
          logger.error("event=webexMeetingSyncError | Webex Meeting Sync Error", err);
        });
    }
  }

  public async unregisterWebex() {
    try {
      logger.info("[Webex] : event=device.unregister called to delete the device registered with webex-js-sdk!");
      await this.device.unregister();
      await this.dss.unregister();
      await this.webex.logout({ noRedirect: true });
      this.webexInitialized = false;
    } catch (error) {
      logger.error(
        "[Webex] : Error while Logout is initiated, to delete the device registered with webex-js-sdk!",
        error
      );
    }
  }

  // polling logic for webex dnd
  private poleWebexStatus(data: Service.Webex.WebexUserStatusResponse) {
    clearTimeout(this.timeout);
    if (data.body.status === "dnd" && data.body.expiresTTL > 0) {
      this.timeout = window.setTimeout(() => {
        this.getUserStatusForWebex(SERVICE.conf?.profile?.agentId);
      }, data.body.expiresTTL * 1000);
    }
  }

  public webexStatusRequest(agentId: string): Promise<Service.Webex.WebexUserStatusResponse> {
    return this.webex.request({
      method: "GET",
      service: "apheleia",
      resource: `compositions?userId=${agentId}`
    });
  }

  // get dnd/active status for the webex user
  public getUserStatusForWebex(agentId: string | undefined) {
    if (agentId) {
      this.webexStatusRequest(agentId)
        .then((response: Service.Webex.WebexUserStatusResponse) => {
          const eventData: Service.Webex.NewPresenceEvent = {
            eventType: SERVICE.constants.WebexConstants.WEBEX_DND,
            status: response?.body?.status
          };
          this.newPresenceEventSignal.send(eventData);

          this.poleWebexStatus(response);
          logger.info(
            `event=webexGetPresenceSuccess | Webex Get Presence Success with status, ${response.body.status}`
          );
        })
        .catch((err: any) => {
          logger.error(`event=webexGetPresenceFailed | Webex Get Presence Failed, ${err}`, err);
        });
    }
  }

  public async getPresenceStatus(agentId: string | undefined) {
    if (agentId) {
      try {
        const response: Service.Webex.WebexUserStatusResponse = await this.webexStatusRequest(agentId);
        logger.info(`event=webexGetPresenceSuccess | Webex Get Presence Success with status, ${response.body.status}`);
        this.onNewPresenceStateSyncEventSignal.send({
          data: {
            eventType: SERVICE.constants.WebexConstants.WEBEX_PRESENCE_UPDATE,
            status: response?.body?.status,
            category: "status",
            meetingType: response?.body?.meetingType
          }
        });
      } catch (err) {
        logger.error(`event=webexGetPresenceFailed | Webex Get Presence Failed`, err);
      }
    }
  }

  // handle onload/reload
  public updateWebexMeeting() {
    if (!this.getCurrentMeeting()) {
      const eventData: Service.Webex.NewMeetingEvent = {
        id: "",
        timeStamp: 0,
        eventType: SERVICE.constants.WebexConstants.CALL_INACTIVE,
        participantName: "",
        meetingType: SERVICE.constants.WebexConstants.CALL
      };
      this.newMeetingEventSignal.send(eventData);
    }
    this.getUserStatusForWebex(SERVICE.conf?.profile?.agentId);
  }

  private getCurrentMeeting() {
    const meetings = this.webex.meetings.getAllMeetings();
    return meetings[Object.keys(meetings)[0]];
  }

  private parseMessage(message: any, person: any): Service.Webex.NewMessage {
    return {
      sentBy: message.data.personEmail,
      text: message.data.text,
      html: message.data.html,
      time: message.data.created,
      roomType: message.data.roomType,
      displayName: person.displayName,
      hasAttachments: message.data.files,
      id: message.data.id
    };
  }

  private async fetchPersonData(personId: string) {
    return this.webex.people.get(personId);
  }

  public async fetchPersonDataByEmail(personEmail: string) {
    return this.webex.people.list({ email: personEmail });
  }
  public handleWebexMessageEvents() {
    this.webex.messages
      .listen()
      .then(() => {
        logger.info("event=webexMessages | listening to message events");
        this.webex.messages.on("created", async (message: any) => {
          const person = await this.fetchPersonData(message.data.personId);
          this.newMessageSignal.send(this.parseMessage(message, person));
        });
      })
      .catch((err: any) => {
        logger.error(`event=webexMessagesError | error listening to messages: ${err}`, err);
      });
  }

  // returns feature ISO date in minutes left
  private readonly getStartTimeInMinute = (startTime: string): any => {
    if (startTime) {
      return Math.ceil(
        DateTime.fromISO(startTime)
          .toLocal()
          .diff(DateTime.local(), ["minutes"])?.minutes
      );
    }
    return 0;
  };

  private listenForIncomingMeetings() {
    this.webex.meetings.on("meeting:added", (addedMeetingEvent: Service.Webex.AddedMeetingEvent) => {
      if (
        addedMeetingEvent.type === SERVICE.constants.WebexConstants.INCOMING ||
        addedMeetingEvent.type === SERVICE.constants.WebexConstants.JOIN
      ) {
        const addedMeeting = addedMeetingEvent.meeting;

        if (addedMeeting) {
          const isScheduledMeeting = addedMeeting.locusInfo.scheduledMeeting ? true : false;
          const timeStamp: number = DateTime.fromISO(addedMeeting.locusInfo.fullState.lastActive)?.toMillis();
          const name: string =
            addedMeeting.type === SERVICE.constants.WebexConstants.CALL
              ? addedMeeting.locusInfo.host.name
              : addedMeeting.locusInfo.info.webExMeetingName;

          const meetingData: Service.Webex.NewMeetingEvent = {
            id: addedMeeting.id,
            eventType: addedMeetingEvent.type,
            timeStamp,
            participantName: name,
            meetingType: addedMeeting.type,
            isScheduledMeeting,
            meetingInMinutes: isScheduledMeeting
              ? this.getStartTimeInMinute(addedMeetingEvent.meeting.locusInfo.scheduledMeeting.startTime)
              : 0
          };
          this.newMeetingEventSignal.send(meetingData);
        }
      }
    });

    this.webex.meetings.on("meeting:removed", (removeMeetingEvent: Service.Webex.RemovedEvent) => {
      const eventData: Service.Webex.NewMeetingEvent = {
        id: removeMeetingEvent.meetingId,
        timeStamp: 0,
        eventType: removeMeetingEvent.reason,
        participantName: "",
        meetingType: SERVICE.constants.WebexConstants.CALL
      };

      this.newMeetingEventSignal.send(eventData);
      setTimeout(() => {
        //For missed calls, conversation is updating late so adding delay of 10 seconds
        this.fetchUnreadConversationCount();
      }, 10000);
    });
  }

  public fetchUnreadConversationCount() {
    logger.info("event=webexNotificationCount | Fetching unread conversation count");
    this.webex.rooms
      .listWithReadStatus()
      .then((data: any) => {
        this.webexNotificationErrorCount = 0; // to reset the count after successful fetch
        let unreadConversationCount = 0;
        data.items.forEach((item: any) => {
          const lastActivity = new Date(item.lastActivityDate).getTime();
          const lastSeen = new Date(item.lastSeenActivityDate).getTime();
          if (lastSeen < lastActivity) {
            unreadConversationCount++;
          }
        });
        this.notificationCountUpdateSignal.send(unreadConversationCount);
      })
      .catch((e: any) => {
        // To remove the spamming of the logs for failing to fetch the unread conversation count
        if (this.webexNotificationErrorCount < this.maxWebexNotificationErrorCount) {
          logger.error(`event=webexNotificationCountError | Failed to fetch unread conversation count: ${e}`, e);
          this.webexNotificationErrorCount++;
        }
      });
  }

  public async sendMessage(toPersonEmail: string, text: string): Promise<boolean> {
    try {
      await this.webex.messages.create({ toPersonEmail, text });
      this.newWebexChatActionDone.send({ status: true });
      return true;
    } catch (e) {
      this.newWebexChatActionDone.send({ status: false });
      return false;
    }
  }

  public handleUnreadConversations() {
    this.fetchUnreadConversationCount();
    this.webex.memberships
      .listen()
      .then(() => {
        logger.info(
          "event=webexNotificationListener | Listening to membership events to update unread conversation count"
        );
        this.webex.memberships.on("created", () => this.fetchUnreadConversationCount());
        this.webex.memberships.on("updated", () => this.fetchUnreadConversationCount());
        this.webex.memberships.on("seen", () => this.fetchUnreadConversationCount());
        this.webex.memberships.on("deleted", () => this.fetchUnreadConversationCount());
      })
      .catch((e: any) =>
        logger.error(
          `event=webexNotificationListenerFail | Failed Listening to membership events to update unread conversation count ${e}`,
          e
        )
      );
  }

  public stopListeningForPresenceUpdates(contactIds: string[]) {
    contactIds.forEach(contactId => {
      this.presence.dequeue(contactId);
    });
  }

  public initialisePresencePlugin() {
    logger.info("[Presence]: initialised");
    this.presence.initialize();
    this.listenForPresenceUpdates();
  }

  public subscribeForPresenceUpdates(contactIds: string[]) {
    logger.info("[Presence]: subscribeForPresenceUpdates for ", contactIds);
    const updates: Service.Webex.Presence[] = [];
    contactIds.forEach(contactId => {
      if (this.hasPresenceStatus(contactId)) {
        updates.push(this.presences[contactId]);
      } else {
        this.presence.enqueue(contactId);
      }
    });

    if (updates.length > 0) {
      this.presenceUpdateSignal.send(updates);
    }
  }

  private hasPresenceStatus(contactId: string): boolean {
    const currentPresence = this.presences[contactId];
    const presenceExpiresTime = currentPresence?.expiresTime ?? "";
    const now = DateTime.now();
    if (currentPresence?.expiresTime) {
      const expiresTime = DateTime.fromISO(presenceExpiresTime);
      if (currentPresence?.expiresTime && expiresTime < now) {
        logger.info("[Presence]: presence is expired removing cached data for: ", contactId);
        delete this.presences[contactId];
      }
      return expiresTime > now;
    }

    return currentPresence !== undefined;
  }

  private parsePresenceUpdate(update: any): Service.Webex.Presence {
    return {
      contactId: update.subject,
      status: update.status,
      customStatus: update?.customStatusMessage,
      lastActiveTime: update.lastActive,
      expiresTime: update?.expiresTime
    };
  }

  private listenForPresenceUpdates() {
    this.presence.on(SERVICE.constants.WebexConstants.WEBEX_SDK_PRESENCE_UPDATE, (envelope: any) => {
      logger.info("Presence update from webex ", envelope);
      const updates: Service.Webex.Presence[] = [];

      if (envelope.type === "presence") {
        envelope.payload.statusList.forEach((update: any) => {
          updates.push(this.parsePresenceUpdate(update));
        });
      }
      if (envelope.type === "subscription") {
        updates.push(this.parsePresenceUpdate(envelope.payload));
      }

      updates.forEach(update => {
        this.presences[update.contactId] = update;
      });

      this.presenceUpdateSignal.send(updates);
    });
  }

  private readonly dispatchMercuryEvent = (eventName: string, trackingID?: string) => {
    window.dispatchEvent(
      new CustomEvent(eventName, {
        detail: { trackingId: trackingID },
        bubbles: true,
        composed: true
      })
    );
  };

  //function to setTimeout
  private retryMercuryRegister() {
    this.dispatchMercuryEvent(PRESENCE_MERCURY_REG.AX_MERCURY_RETRYING); //dispatch mercury retry event
    setTimeout(() => {
      this.registerToMercury();
    }, RETRY_TIMEOUT);
  }

  public async registerToDSS() {
    if (!this.dss.registered) {
      try {
        await this.dss.register();
        logger.info(`[Webex] successfully registered to DSS`);
      } catch (e) {
        logger.info(`[Webex] failed to register DSS: ${e}`);
      }
    }
  }

  private registerToMercury() {
    logger.info("[PresenceStateSync]: | Webex : initializing webex.internal.device.register");
    return this.device
      .register()
      .then(() => {
        logger.info(
          "[PresenceStateSync]: event=WebexDeviceRegistrationSuccess | Webex : webex.internal.device.register successful"
        );
        logger.info("[PresenceStateSync]: | Webex : initializing webex.internal.mercury.connect");
        return this.mercury
          .connect()
          .then(() => {
            logger.info("[PresenceStateSync]: | Webex : webex.internal.mercury.connect successful");
            this.subscribeStateSync();
          })
          .catch((error: any) => {
            logger.error(
              "[PresenceStateSync]: event=WebexDeviceRegistrationFailed | Webex : Error occurred during mercury.connect()",
              error
            );
            if (this.mercuryConnectRetry) {
              this.mercuryConnectRetry = false; //after first retry
              this.retryMercuryRegister();
            } else {
              this.dispatchMercuryEvent(PRESENCE_MERCURY_REG.AX_MERCURY_FAILURE, getTrackingIdFromErrorObject(error)); //dispatch mercury failure event
            }
          });
      })
      .catch((error: any) => {
        logger.error("[PresenceStateSync]: Webex : Error occurred during webex.internal.device.register()", error);
        if (this.deviceRegisterRetry) {
          this.deviceRegisterRetry = false; //after first retry
          this.retryMercuryRegister();
        } else {
          this.dispatchMercuryEvent(PRESENCE_MERCURY_REG.AX_MERCURY_FAILURE, getTrackingIdFromErrorObject(error)); //dispatch mercury failure event
        }
      });
  }

  private subscribeStateSync() {
    this.dispatchMercuryEvent(PRESENCE_MERCURY_REG.AX_MERCURY_SUCCESSFUL); //dispatch mercury success event
    this.mercury.on(
      SERVICE.constants.WebexConstants.WEBEX_PRESENCE_UPDATE,
      (status: Service.Webex.NewPresenceStateSyncEvent) => {
        logger.info("[PresenceStateSync] : presence sync state updated", status);
        this.onNewPresenceStateSyncEventSignal.send(status);
      }
    );
  }

  public async registerPresenceSync() {
    if (this.device.registered && this.mercury.connected) {
      logger.info("[PresenceStateSync]: | Webex : Found mercury connection!");
      this.subscribeStateSync();
    } else {
      logger.info("[PresenceStateSync]: | Webex : mercury connection not found retrying ...");
      await this.registerToMercury();
    }
  }

  public async setStatus(status: Service.Webex.PresenceStatus, ttl: number) {
    this.webex.internal.presence
      .setStatus(status, ttl)
      .then(() => {
        logger.info("[PresenceStateSync]: webex.internal.presence.setStatus successful");
      })
      .catch((err: any) => {
        logger.error("[PresenceStateSync]: Error occurred during webex.internal.presence.setStatus():", err);
        throw err;
      });
  }

  private parseUser(user: any, source: Service.Webex.UserSource): Service.Webex.User {
    const avatarUrl = (() => {
      if (Array.isArray(user.photos)) {
        for (const photo of user.photos) {
          if (photo?.type === "thumbnail") {
            return photo?.value;
          }
        }
      }
      return "";
    })();

    return {
      source,
      userId: user.identity,
      displayName: user.displayName,
      email: user.emails?.[0]?.value ?? "",
      phoneNumber: user.phoneNumbers?.[0]?.value ?? "",
      department: user.additionalInfo?.department ?? "",
      jobTitle: user.additionalInfo?.jobTitle ?? "",
      avatarUrl
    };
  }

  public async lookupUsers(ids: string[]): Promise<Service.Webex.User[]> {
    const res = await this.dss.lookup({ ids });
    return res.map((user: any) => this.parseUser(user, "lookup"));
  }

  public async searchUsers(query: string, resultSize: number): Promise<Service.Webex.User[]> {
    const res = await this.dss.search({
      requestedTypes: ["PERSON"],
      queryString: query.trim(),
      resultSize
    });
    return res.map((user: any) => this.parseUser(user, "search"));
  }

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }
}

declare module "../../index" {
  export namespace Service.Webex {
    type NewMessage = {
      sentBy: string;
      text: string;
      html: string;
      time: string;
      roomType: string;
      displayName: string;
      hasAttachments: boolean;
      id: string;
    };

    type NewMeetingEvent = {
      id: string;
      timeStamp: number;
      eventType: string;
      participantName: string;
      meetingType: "CALL" | "MEETING";
      isScheduledMeeting?: boolean;
      meetingInMinutes?: number;
    };

    type AddedMeetingEvent = {
      id: string;
      meeting: Meeting;
      type: string;
    };

    type RemovedEvent = {
      meetingId: string;
      reason: string;
    };

    type Meeting = {
      id: string;
      type: "CALL" | "MEETING";
      locusInfo: {
        fullState: {
          lastActive: string;
        };
        host: {
          name: string;
        };
        info: {
          webExMeetingName: string;
        };
        scheduledMeeting: {
          durationMinutes: number;
          startTime: string;
        };
      };
      partner: {
        person: {
          name: string;
        };
      };
    };

    type NewPresenceEvent = {
      eventType: string;
      status: string;
      meetingType?: string;
    };

    type NewPresenceStateSyncEvent = {
      data: {
        category: string;
        status: string;
        eventType: string;
        meetingType?: string;
      };
    };

    type PresenceData = {
      [id: string]: Service.Webex.Presence;
    };

    type Presence = {
      contactId: string;
      status: string;
      customStatus?: string;
      lastActiveTime: string;
      expiresTime?: string;
    };

    type WebexChatEvent = {
      status: boolean;
    };

    type WebexUserStatusResponse = {
      body: {
        status: string; // active | dnd
        expiresTTL: number; //expiring current dnd status in seconds.
        meetingType?: string; // calendarItem
      };
    };

    // apheleia update status
    type PresenceStatus =
      | "call"
      | "meeting"
      | "presenting"
      | "dnd"
      | "active"
      | "unknown"
      | "inactive"
      | "busy"
      | "ooo"
      | string;

    type UserSource = "search" | "lookup";

    type User = {
      source: UserSource;
      displayName: string;
      userId: string;
      email: string;
      phoneNumber: string;
      avatarUrl: string;
      department: string;
      jobTitle: string;
      presence?: Presence;
    };
  }
}
