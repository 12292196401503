import { AqmReqs } from "../../core/aqm-reqs";
import { getRoutingHost, SERVICE as AgentxService, Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import { encryptSecureCAD, validateSharedCADVariable } from "./aqm-service-dialer-utils";

import Contact = Service.Aqm.Contact;
import Failure = Service.Aqm.Global.Failure;

export function aqmDialer(aqm: AqmReqs) {
  const onUpdateCADVariables = aqm.req((p: { interactionId: string; data: Service.Aqm.Dialer.updateCadPayLoad }) => ({
    url: `/tasks/${p.interactionId}`,
    host: getRoutingHost(),
    data: p.data,
    method: "patch",
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactUpdated", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactUpdateFailed" }
      },
      errId: "Service.aqm.dialer.ContactUpdateFailed"
    }
  }));
  return {
    startOutdial: aqm.req((p: { data: Service.Aqm.Dialer.tasks }) => ({
      url: "/tasks",
      host: getRoutingHost(),
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentOfferContact" }
        },
        msg: {} as Contact.AgentContact
      },
      notifFail: {
        bind: {
          type: "RoutingMessage",
          data: { type: "AgentOutboundFailed" }
        },
        errId: "Service.aqm.dialer.startOutdial"
      }
    })),

    /**
     * CAD Variable Update
     */
    updateCadVariables: async (args: {
      interactionId: string;
      data: Service.Aqm.Dialer.updateCadPayLoad;
      secureCad?: Array<string>;
      keyId: string | null;
      keyVersion: number | null;
    }): Promise<any> => {
      try {
        await validateSharedCADVariable(args.data.attributes);
        // encrypt only secure CAD Variables
        if (AgentxService.featureflag.isSecureCADVariablesEnabled() && args.secureCad && args.secureCad.length > 0) {
          try {
            await encryptSecureCAD({
              secureCad: [],
              ...args
            });
            return onUpdateCADVariables(args);
          } catch (e) {
            return Promise.reject(e);
          }
        }
        return onUpdateCADVariables(args);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    eOutdialFailed: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentOutboundFailed" }
      },
      msg: {} as Contact.AgentContact
    }),

    eContactUpdated: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactUpdated" }
      },
      msg: {} as Contact.AgentContact
    })
  };
}
declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.aqm.dialer":
        | { "Service.aqm.dialer.startOutdial": Failure }
        | { "Service.aqm.dialer.ContactUpdateFailed": Failure }
        | { "Service.reqs.generic.failure": { trackingId: string } };
    }
  }
}

declare module "../../index" {
  export namespace Service.Aqm.Dialer {
    type tasks = {
      id?: string;
      entryPointId: string;
      destination: string;
      direction: string;
      origin?: string;
      attributes: { [key: string]: string };
      mediaType: string;
      outboundType: string;
    };
    type updateCadPayLoad = {
      attributes: { [key: string]: string };
    };
  }
}
