import { Service } from "../../..";
import { logger } from "../../../core/sdk";
import { generateUUID } from "../../service-utils";
import { MSFT_TOKEN, loginPromptDimension } from "./utils";

const NotInitializedError = new Error("[Microsoft OAuth Handler]: Microsoft OAuth config service is not initialized.");
const PopupTarget = "about:blank";

export class MicrosoftAuthCodeHandler {
  public isInitialized = false;
  private isPopUpActive = false;
  private msConfig: Service.Microsoft.OAuthService.Config | undefined;
  private currentWindow: Window | null = null;
  private readonly DEFAULT_AUTHORITY_HOST = "https://login.microsoftonline.com";
  private readonly defaultTimeoutOptions = {
    windowTimeout: 60000,
    iframeTimeout: 10000,
    pollInterval: 30
  };

  constructor() {
    this.unloadWindow = this.unloadWindow.bind(this);
  }

  /**
   * Method to initialize the microsoft OAuth service required config values based on tenantId and clientId
   * @param clientId - Azure App ClientID
   * @param tenantId - Customer Id
   * @returns Window
   */
  public initializeOAuthService(clientId: string, tenantId: string) {
    this.msConfig = {
      clientId,
      tenantId,
      authority: `${this.DEFAULT_AUTHORITY_HOST}/${tenantId}`,
      endPoints: {
        authorizationURL: `${this.DEFAULT_AUTHORITY_HOST}/${tenantId}/oauth2/v2.0/authorize`,
        logoutURL: `${this.DEFAULT_AUTHORITY_HOST}/${tenantId}/oauth2/v2.0/logout`
      }
    };
    this.isInitialized = true;
    logger.info("[Microsoft OAuth Handler]: Initializing Microsoft OAuth Handler Service SUCCESS");
    window.addEventListener("beforeunload", this.unloadWindow);
    return true;
  }

  /**
   * Helper function to open popup window dimensions and position
   * @param urlNavigate - URL to open in window
   * @param popupName - Name of the popup window
   * @returns Window
   */
  private promptUserLogin(urlNavigate: string, popupName: string): Window | null {
    const { width, height, top, left } = loginPromptDimension();
    this.currentWindow = window.open(
      urlNavigate,
      popupName,
      `width=${width}, height=${height}, top=${top}, left=${left}, scrollbars=yes`
    );
    return this.currentWindow;
  }

  /**
   * Helper function to open authorization URL inside the hidden iframe
   * @param redirectUri - URL to open in iframe
   * @param scope - Microsoft authorization scopes
   * @returns Promise<string>
   */
  public async invokeSilentSSOLogin(props: Service.Microsoft.OAuthService.LoginRequest): Promise<string> {
    if (!this.isInitialized || !this.msConfig) {
      throw NotInitializedError;
    }

    const urlNavigate = this.getAuthenticationUrl({ ...props, prompt: "none" });
    const iframe = this.loadIframeSync(urlNavigate);
    if (iframe) {
      const urlString = await this.monitorIframeResponse(iframe);
      const response = this.getAuthCodeFromUrl(urlString);
      logger.info("[Microsoft OAuth Handler]: Fetching the auth code from microsoft Silent SSO is successful.");
      return response;
    } else {
      throw new Error("[Microsoft OAuth Handler]: Error on creating the silent SSO Iframe.");
    }
  }

  /**
   * Use when we extract the auth code from the microsoft auth response URL
   * @param urlString - response url received from the microsoft oauth authorize service.
   * @returns string
   */
  private getAuthCodeFromUrl(urlString: string): string {
    const url = new URL(urlString);
    const codeValue = url.searchParams.get("code");
    if (codeValue && codeValue.length > 0) {
      return codeValue;
    } else {
      throw new Error(
        `[Microsoft OAuth Handler]: Error on retrieving the code from microsoft auth response URL: ${urlString}`
      );
    }
  }

  /**
   * Helper function to generate the microsoft authorization URL
   * @param redirectUri - URL to open in iframe
   * @param scope - Microsoft authorization scopes
   * @returns string
   */
  private getAuthenticationUrl(props: Service.Microsoft.OAuthService.LoginRequest) {
    if (!this.isInitialized || !this.msConfig) {
      throw NotInitializedError;
    }

    const { scope, redirectUri, clientId, nonce, state } = props;
    const queryParams = {
      client_id: clientId ?? this.msConfig?.clientId,
      response_type: "code",
      response_mode: "query",
      scope,
      redirect_uri: redirectUri,
      nonce: nonce ?? generateUUID(),
      state: state ?? generateUUID()
    };

    if (props.prompt) {
      (queryParams as any).prompt = props.prompt;
    }

    const params = new URLSearchParams(queryParams).toString();
    const authorizeUrl = new URL(this.msConfig.endPoints.authorizationURL);
    return `${authorizeUrl}?${params}`;
  }

  unloadWindow(e: Event): void {
    if (this.currentWindow) {
      this.currentWindow.close();
    }
    // Guarantees browser unload will happen, so no other errors will be thrown.
    e.preventDefault();
  }

  /**
   * Use when initiating the login process via opening a popup window in the user's browser
   * @param request
   * @returns A promise that is fulfilled when this function has completed, or rejected if an error was raised.
   */
  public async invokeLoginPopUp(props: Service.Microsoft.OAuthService.LoginRequest): Promise<string> {
    if (!this.isInitialized || !this.msConfig) {
      throw NotInitializedError;
    }

    const popUpName = this.generatePopupName(this.msConfig?.clientId);
    const urlNavigate = this.getAuthenticationUrl({ ...props });

    // Invoke the blank popup window to invoke the popup immediately.
    // Later asap we can update the URL in same popup window.
    const popupWindow = this.promptUserLogin(PopupTarget, popUpName);
    if (popupWindow) {
      this.isPopUpActive = true;
      // Interaction In Progress - set interaction status.
      popupWindow.location.href = urlNavigate; // Load the microsoft URL in the popup window.
      logger.info("[Microsoft OAuth Handler]: Invoking the popup window for microsoft login is successful.");

      // Monitor the popup window authentication is successful.
      const urlString = await this.monitorPopupResponse(popupWindow);
      const response = this.getAuthCodeFromUrl(urlString);
      logger.info("[Microsoft OAuth Handler]: Fetching the auth code from microsoft login popup window is successful.");
      return response;
    } else {
      throw new Error("[Microsoft OAuth Handler]: Error on opening popup window.");
    }
  }

  /**
   * Use when initiating the logout process via opening a popup window in the user's browser
   *
   * @param request
   *
   * @returns A promise that is fulfilled when this function has completed, or rejected if an error was raised.
   */
  public async invokeLogout({ clientId, redirectUri }: { clientId?: string; redirectUri: string }) {
    if (!this.isInitialized || !this.msConfig) {
      throw NotInitializedError;
    }

    const popUpName = this.generatePopupName(this.msConfig?.clientId);
    const queryParams = {
      client_id: clientId ?? this.msConfig?.clientId,
      redirect_uri: redirectUri
    };

    const params = new URLSearchParams(queryParams).toString();
    const authorizeUrl = new URL(this.msConfig.endPoints.logoutURL);
    const urlNavigate = `${authorizeUrl}?${params}`;

    // Invoke the blank popup window to invoke the popup immediately.
    // Later asap we can update the URL in same popup window.
    const popupWindow = this.promptUserLogin(PopupTarget, popUpName);
    if (popupWindow) {
      // Interaction In Progress - set interaction status.
      popupWindow.location.href = urlNavigate; // Load the microsoft URL in the popup window.
      setTimeout(function() {
        popupWindow.close();
      }, 10000); // Close the popup window after 10 seconds.
      logger.info("[Microsoft OAuth Handler]: Invoking the popup window for microsoft logout is successful.");

      // Monitor the popup window authentication is successful.
      const response = await this.monitorPopupResponse(popupWindow);
      logger.info(
        "[Microsoft OAuth Handler]: Fetching the auth code from microsoft logout popup window is successful."
      );
      return response;
    } else {
      throw new Error("[Microsoft OAuth Handler]: Error on opening popup window.");
    }
  }

  /**
   * Use when initiating the logout process via opening a popup window in the user's browser
   * it will clear the token from the local storage.
   * @returns void
   */

  public removeToken(): void {
    sessionStorage.removeItem(MSFT_TOKEN);
  }

  /**
   * Monitors a window until it loads a url with the same origin.
   * @param popupWindow - window that is being monitored
   * @returns Promise<string>
   */
  private async monitorPopupResponse(popupWindow: Window): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const intervalId = setInterval(() => {
        // Window is closed
        if (popupWindow.closed) {
          clearInterval(intervalId);
          reject(new Error("[Microsoft OAuth Handler]: User cancelled the flow"));
          return;
        }

        let href = "";
        try {
          /*
           * Will throw if cross origin,
           * which should be caught and ignored
           * since we need the interval to keep running while on STS UI.
           */
          href = popupWindow.location.href;
        } catch (e) {
          // Ignore adding the logs for interval to fetch the href from location.
        }

        // Don't process blank pages or cross domain
        if (!href || href === PopupTarget) {
          return;
        }
        clearInterval(intervalId);

        let responseString = "";
        if (popupWindow) {
          responseString = popupWindow.location.href;
        }

        resolve(responseString);
      }, this.defaultTimeoutOptions.pollInterval);
    }).finally(() => {
      this.cleanPopup(popupWindow);
      this.isPopUpActive = false;
    });
  }

  /**
   *  Method to check the login popup window is opened or not.
   * @returns Boolean
   */
  public getPopUpActiveStatus() {
    return this.isPopUpActive;
  }

  /**
   * Monitors an iframe content window until it loads a url with a response, or hits a specified timeout.
   * @param iframe
   * @returns Promise<string>
   */
  private async monitorIframeResponse(iframe: HTMLIFrameElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      /*
       * Polling for iframes can be purely timing based,
       */
      let intervalId = 0;
      const timeoutId = window.setTimeout(() => {
        window.clearInterval(intervalId);
        reject(new Error("[Microsoft OAuth Handler]: SSO Silent Iframe Timeout"));
      }, this.defaultTimeoutOptions.iframeTimeout);

      intervalId = window.setInterval(() => {
        let href = "";
        const contentWindow = iframe.contentWindow;
        try {
          /*
           * Will throw if cross origin,
           * which should be caught and ignored
           * since we need the interval to keep running while on STS UI.
           */
          href = contentWindow ? contentWindow.location.href : "";
        } catch (e) {
          // Ignore adding the logs for interval to fetch the href from location.
        }

        if (!href || href === PopupTarget) {
          return;
        }

        let responseString = "";
        if (contentWindow) {
          responseString = contentWindow.location.href;
        }
        window.clearTimeout(timeoutId);
        window.clearInterval(intervalId);
        resolve(responseString);
      }, 30);
    }).finally(() => {
      this.removeHiddenIframe(iframe);
    });
  }

  /**
   * Closes popup, removes any state vars created during popup calls.
   * @param popupWindow
   * @returns void
   */
  public cleanPopup(popupWindow?: Window): void {
    if (popupWindow) {
      // Close window.
      popupWindow.close();
    }
    window.removeEventListener("beforeunload", this.unloadWindow);

    // Interaction is completed - remove interaction status.
  }

  /**
   * @hidden
   * Creates a new hidden iframe or gets an existing one for silent token renewal.
   * @ignore
   */
  public createHiddenIframe(): HTMLIFrameElement {
    const authFrame = document.createElement("iframe");

    authFrame.style.visibility = "hidden";
    authFrame.style.position = "absolute";
    authFrame.style.width = authFrame.style.height = "0";
    authFrame.style.border = "0";
    authFrame.setAttribute("sandbox", "allow-scripts allow-same-origin allow-forms");
    document.body.appendChild(authFrame);

    return authFrame;
  }

  /**
   * @hidden
   * Removes a hidden iframe from the page.
   * @ignore
   */
  public removeHiddenIframe(iframe: HTMLIFrameElement): void {
    if (document.body === iframe.parentNode) {
      document.body.removeChild(iframe);
    }
  }

  /**
   * Helper function to generate the iframe along with microsoft authorization URL
   * @param urlNavigate - URL to open in iframe
   * @returns HTMLIFrameElement
   */
  public loadIframeSync(urlNavigate: string): HTMLIFrameElement {
    const iFrameHandle = this.createHiddenIframe();

    iFrameHandle.src = urlNavigate;

    return iFrameHandle;
  }

  /**
   * Helper function to generate the unique popup window name
   * @param clientId - Azure App ClientID
   * @returns string
   */
  private generatePopupName(clientId: string): string {
    const POPUP_NAME_PREFIX = "contact-center";
    return `${POPUP_NAME_PREFIX}.${clientId ?? ""}.${generateUUID()}}`;
  }
}

declare module "../../../index" {
  export namespace Service.Microsoft.OAuthService {
    type LoginRequest = {
      clientId?: string;
      scope: string;
      redirectUri: string;
      nonce?: string;
      state?: string;
      prompt?: string;
    };

    type Config = {
      clientId: string;
      tenantId: string;
      authority: string;
      endPoints: {
        authorizationURL: string;
        logoutURL: string;
      };
    };
  }
}
