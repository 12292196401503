import { AqmReqs } from "../../core/aqm-reqs";
import { Service } from "../../index";
import ScreenPopDetail = Service.Aqm.ScreenPop.ScreenPopDetail;

/**
 *
 * @param aqm Screen pop event handler
 */
export function aqmScreenPop(aqm: AqmReqs) {
  return {
    eScreenPop: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "ScreenPop" }
      },
      msg: {} as ScreenPopDetail
    })
  };
}

declare module "../../index" {
  export namespace Service.Aqm.ScreenPop {
    type ScreenPopDetail = {
      type: string;
      orgId: string;
      trackingId: string;
      data: Settings;
    };

    type Settings = {
      agentId: string;
      interactionId: string;
      type: string;
      screenPopDesktopLabel: string;
      screenPopUrl: string;
      queryParameters: Record<string, string>;
      target: string;
      screenPopName: string;
    };
  }
}
