import { Signal } from "@uuip/unified-ui-platform-sdk";
import { logger } from "../../core/sdk";
import { Service, SERVICE } from "../../index";
import {
  DEFAULT_SHORTCUT_KEYS,
  findMatchingKey,
  hasConflict,
  KEY_CONFLICT_EVENT,
  KEY_CONFLICT_RESOLVED,
  KEY_EXECUTE_EVENT,
  KEY_UP_EVENT,
  MODIFIERS,
  REGISTERED_KEYS,
  validModifierKeysPressed
} from "./ShortcutUtil";
import KeyInfo = Service.shortcut.KeyInfo;
import EKeyInfo = Service.shortcut.EKeyInfo;

export function shortcutKeyService() {
  const allShortcutKeyMap = new Map<string, KeyInfo>();
  const roleBasedFilteredShortcutKeyMap = new Map<string, KeyInfo>();
  const { signal, send } = Signal.create.withData<EKeyInfo>();

  function checkUserRole(widgetUserRole: string): boolean {
    const loggedInUserRole = localStorage.getItem("userRole");
    if (!widgetUserRole && (loggedInUserRole === "agent" || loggedInUserRole === "supervisorAgent")) {
      return true;
    }
    if (widgetUserRole === "all") {
      return true;
    }
    if (loggedInUserRole === "supervisorAgent" && (widgetUserRole === "agent" || widgetUserRole === "supervisor")) {
      return true;
    }
    return loggedInUserRole === widgetUserRole;
  }

  function isSupervisorDesktopEnabled() {
    return SERVICE.featureflag.isSupervisorDesktopEnabled();
  }

  function isSimilarRole(existingWidgetUserRole: string, newWidgetUserRole: string): boolean {
    return isSupervisorDesktopEnabled() ? existingWidgetUserRole === newWidgetUserRole : true;
  }

  function updateKeyMaps(mapKey: string, data: KeyInfo) {
    allShortcutKeyMap.set(mapKey, data);
    if (checkUserRole(data.role)) {
      roleBasedFilteredShortcutKeyMap.set(mapKey, data);
    }
  }

  function updateShortcutKeyMap(data: KeyInfo) {
    if (data) {
      let isRegistrationFromSameComponent = false;

      if (Object.values(MODIFIERS).indexOf(data.modifierKeys) < 0) {
        throw Error(`[ShortcutKey] Not supported modifier ${JSON.stringify(data)}`);
      }

      allShortcutKeyMap.forEach(value => {
        //TODO:CX-3033 Add validation for widgetElement , it should be validated against layout config and should be present in the

        // in case if same widget is included more than once through layout config, then key registration should be done once only
        if (data.group === value.group && data?.action === value.action) {
          isRegistrationFromSameComponent = true;
        } else if (hasConflict(data, value) && isSimilarRole(data.role, value.role)) {
          logger.info("[ShortcutKey] Conflicted shortcut key found", value, "=>", data);
          value.isConflict = true;
          data.isConflict = true;
          send({ type: KEY_CONFLICT_EVENT, data });
        }
      });

      if (!isRegistrationFromSameComponent) {
        const mapKey = data.action + data.modifierKeys + data.key;
        updateKeyMaps(mapKey, data);
      } else {
        logger.warn("[ShortcutKey] Duplicate shortcut key registration from same component", data);
      }
    }
  }

  const trackKeyboardEvents = (matchingKey: KeyInfo) => {
    SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.KEYBOARD_SHORTCUT_KEY_PRESSED, {
      [SERVICE.telemetry.MIX_PROPS.KEYBOARD_SHORTCUT_COMBINATION_PRESSED]: matchingKey.modifierKeys,
      [SERVICE.telemetry.MIX_PROPS.KEYBOARD_SHORTCUT_KEY_VALUE]: matchingKey.key
    });
  };

  function handleKeyupEvent(event: KeyboardEvent) {
    // Shortcut key press event listener to execute callback
    const matchingKey: KeyInfo | undefined = findMatchingKey(roleBasedFilteredShortcutKeyMap, event);
    if (matchingKey) {
      if (matchingKey.isConflict) {
        logger.warn("[ShortcutKey] conflicted shortcut keys cannot not work");
        return;
      }

      logger.info("[ShortcutKey] Matching shortcut key found", matchingKey);
      // execute callback if callback is provided while registration of shortcut key
      const cEvt: EKeyInfo = {
        type: KEY_EXECUTE_EVENT,
        data: matchingKey,
        keyboardEvent: event
      };
      // if listenKeyPress back is attached while registration then invoke the listenKeyPress
      if (matchingKey.listenKeyPress) {
        matchingKey.listenKeyPress(cEvt);
      } else {
        // send response to listeners on keypress event
        send(cEvt);
      }
      trackKeyboardEvents(matchingKey);
    } else {
      logger.warn("[ShortcutKey] No matching shortcut key found", event);
    }
  }

  /**
   * Update keys conflict with the deleted key only.
   * @param modifierKeys
   * @param key
   */
  function updateKeyWithConflcit(modifierKeys: string, key: string) {
    let mapKey,
      count = 0;
    allShortcutKeyMap.forEach(data => {
      if (data.modifierKeys === modifierKeys && data.key === key) {
        count++;
        mapKey = data.action + data.modifierKeys + data.key;
      }
    });
    if (count === 1 && mapKey) {
      const val = allShortcutKeyMap.get(mapKey);
      if (val) {
        const updVal: KeyInfo = { ...val, isConflict: false };
        updateKeyMaps(mapKey, updVal);
      }
    }
  }
  // attach window keyup event listener
  window.addEventListener(KEY_UP_EVENT, ((e: KeyboardEvent) => {
    if (validModifierKeysPressed(e)) {
      handleKeyupEvent(e);
    }
  }) as EventListener);

  return {
    event: {
      listenKeyPress: (cb: (data: Service.shortcut.EKeyInfo) => void): any => {
        return signal.listen((data: EKeyInfo) => {
          if (data.type === KEY_EXECUTE_EVENT) {
            cb(data);
          }
        });
      },
      listenKeyConflict: (cb: (data: Service.shortcut.EKeyInfo) => void) => {
        const { stopListen } = signal.listen((data: EKeyInfo) => {
          if (data.type === KEY_CONFLICT_EVENT) {
            cb(data);
          }
        });
        return stopListen;
      },
      listenConflictResolved: (cb: () => void) => {
        const { stopListen } = signal.listen((data: EKeyInfo) => {
          if (data.type === KEY_CONFLICT_RESOLVED) {
            cb();
          }
        });
        return stopListen;
      }
    },
    register: (keys: KeyInfo[]): void => {
      logger.info("[ShortcutKey] Shortcut Key registration received", keys);
      keys.forEach(key => {
        updateShortcutKeyMap(key);
      });
    },

    updateRegisterKeys: (): void => {
      // unregister all the previous keys
      roleBasedFilteredShortcutKeyMap.clear();
      allShortcutKeyMap.forEach(data => {
        const mapKey = data.action + data.modifierKeys + data.key;
        if (checkUserRole(data.role)) {
          roleBasedFilteredShortcutKeyMap.set(mapKey, data);
        }
      });
    },

    getRegisteredKeys: (): Map<string, KeyInfo> => {
      return isSupervisorDesktopEnabled() ? roleBasedFilteredShortcutKeyMap : allShortcutKeyMap;
    },

    unregisterAllKeys: (): void => {
      allShortcutKeyMap.forEach(data => {
        const mapKey = data.action + data.modifierKeys + data.key;
        allShortcutKeyMap.delete(mapKey);
        roleBasedFilteredShortcutKeyMap.delete(mapKey);
        updateKeyWithConflcit(data.modifierKeys, data.key);
      });
    },

    /**
     * This needs to be called by 3rd party widgets on unmount.
     * WidgetElement name should be same as in used for registering shortcut keys
     */
    unregisterKeys: (widgetElement: string) => {
      allShortcutKeyMap.forEach(data => {
        const mapKey = data.action + data.modifierKeys + data.key;
        if (data.widgetElement === widgetElement) {
          allShortcutKeyMap.delete(mapKey);
          roleBasedFilteredShortcutKeyMap.delete(mapKey);
          updateKeyWithConflcit(data.modifierKeys, data.key);
        }
      });
      let conflict = false;
      allShortcutKeyMap.forEach(data => {
        if (data.isConflict) {
          conflict = true;
        }
      });
      if (!conflict) {
        send({ type: KEY_CONFLICT_RESOLVED });
      }
    },

    DEFAULT_SHORTCUT_KEYS,
    MODIFIERS,
    REGISTERED_KEYS
  };
}

declare module "../../index" {
  export namespace Service.shortcut {
    export type KeyInfo = {
      /** widgetElement must be the MFE/widget's tag ext. agentx-react-out-dial-wrapper, agentx-react-chat, agentx-wc-cloudcherry-widget etc.
       * This widget element must match with the component provided in the layout config .
       * widgetElement need to to resolve conflicts.
        "widgets": {
            "comp1": {
              "comp": "agentx-wc-cloudcherry-widget",
            }
          },
       * */
      widgetElement: string;
      group: string;
      modifierKeys: string;
      action: string;
      key: string;
      role: string;
      listenKeyPress?: (event: EKeyInfo) => void;
      isConflict?: boolean;
      deviceType?: string;
    };

    export type EKeyInfo = {
      type: string;
      data?: KeyInfo;
      keyboardEvent?: KeyboardEvent;
    };
  }
}
