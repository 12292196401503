import { Err } from "@uuip/unified-ui-platform-sdk";
import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };
export function absService() {
  const http = new HttpReqs(CONF.AGENTX_BACKEND_PROXY_SERVICE_HOST);
  const FETCH_IDLECODES_FAILED = "Service.abs.fetchIdleCodesFailed";
  const CMS_BASE_URL = "/cms";
  return {
    fetchAddressBooksCMS: http.req(
      (p: { orgId: string; addressBookId: string; page?: number; search?: string; pageSize?: number }) => ({
        url: `${CMS_BASE_URL}/address-book/${p.addressBookId}?page=${p.page}&pageSize=${p.pageSize ||
          50}&search=${p.search || ""}`,
        headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
        data: {},
        err,
        method: "GET",
        errId: "Service.cms.fetchAddressBooksCMS",
        res: {} as Service.Aqm.Agent.AddressBooks
      })
    ),
    fetchCmsInitConfig: http.req((p: { orgId: string; userId: string; isSupervisor: boolean }) => ({
      url: `${CMS_BASE_URL}/agent-config/${p.userId}${p.isSupervisor ? "?supervisor=true" : ""}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err: /* istanbul ignore next */ (e: any) =>
        new Err.Details("Service.abs.fetchCmsInitConfig", {
          status: e.response?.status ?? 0,
          type: e.response?.data?.errorDetails ?? "",
          trackingId: e.response?.data?.trackingId ?? ""
        }),
      method: "GET",
      errId: "Service.abs.fetchCmsInitConfig",
      res: {} as Service.Conf.Profile
    })),
    fetchIdleCodes: http.req(
      (p: {
        orgId: string;
        accessType: Service.Conf.Profile["idleCodesAccess"];
        ids?: Array<string>;
        page?: number;
        pageSize?: number;
      }) => {
        let url = `${CMS_BASE_URL}/agent-config/idle-codes/${p.accessType}`;

        if (p?.page !== undefined && p?.page >= 0) {
          url += `?page=${p.page}&pageSize=${p.pageSize}`;
        }

        if (p.ids && Array.isArray(p.ids) && p.ids.length > 0) {
          const ids = p.ids.join(",");
          url += `?ids=${ids}`;
        }

        // Dynamically determine the type of res based on the presence of page and pageSize
        // type ResType = 'page' in typeof p ? {} : Service.Aqm.Configs.Entity[];
        // Dynamically determine the type of res based on the presence of page and pageSize
        let resType: Service.Aqm.Configs.agentStateIdleCodes | Service.Aqm.Configs.Entity[];
        if ("page" in p && "pageSize" in p) {
          resType = {} as Service.Aqm.Configs.agentStateIdleCodes;
        } else {
          resType = [] as Service.Aqm.Configs.Entity[];
        }

        return {
          url,
          headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
          data: {},
          err,
          method: "GET",
          errId: FETCH_IDLECODES_FAILED,
          res: resType
        };
      }
    ),
    fetchAgentsByOrgId: http.req(
      (p: { orgId: string; page: number; pageSize: number; searchValue: string; sortOrder: string }) => {
        const url = `${CMS_BASE_URL}/agent-config/agents?pageNumber=${p.page}&pageSize=${p.pageSize}&sortOrder=${p.sortOrder}&search==field=="firstName";value=="${p.searchValue}"`;
        return {
          url,
          headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
          data: {},
          err,
          method: "GET",
          errId: "Service.abs.fetchAgentsFailed",
          res: {} as Service.Cms.AgentDataResponse
        };
      }
    ),
    fetchDefaultAuxCodes: http.req((p: { orgId: string }) => {
      const url = `${CMS_BASE_URL}/agent-config/default-aux-codes`;
      return {
        url,
        headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
        data: {},
        err,
        method: "GET",
        errId: FETCH_IDLECODES_FAILED,
        res: {} as Service.Aqm.Configs.DefaultAuxCodes[]
      };
    }),
    fetchWrapUpCodes: http.req((p: any) => {
      let url = `${CMS_BASE_URL}/agent-config/wrapup-codes/${p.accessType}`;
      if (p.ids && Array.isArray(p.ids) && p.ids.length > 0) {
        const ids = p.ids.join(",");
        url += `?ids=${ids}`;
      }
      return {
        url,
        headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
        data: {},
        err,
        method: "GET",
        errId: "Service.abs.fetchWrapUpCodesFailed",
        res: {} as Service.Aqm.Configs.Entity[]
      };
    }),
    fetchManagedTeams: http.req((p: { orgId: string; userId: string }) => ({
      url: `${CMS_BASE_URL}/managed-teams/${p.userId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchManagedTeamsFailed",
      res: {} as Service.Conf.Team[]
    })),
    fetchManagedQueues: http.req((p: { orgId: string; userId: string }) => ({
      url: `${CMS_BASE_URL}/managed-queues/${p.userId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchManagedQueuesFailed",
      res: {} as Service.Conf.Queue[]
    })),
    fetchVteams: http.req((p: { payload: Service.Aqm.Contact.VTeam; orgId: string; userId: string }) => ({
      url: `${CMS_BASE_URL}/vteams/${p.userId}?type=${p.payload.type}&channelType=${p.payload.channelType}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: p.payload,
      err,
      method: "GET",
      errId: "Service.abs.FetchVTeamListFailed",
      res: {} as Service.Aqm.Contact.VteamDetails[]
    })),
    fetchOutdialANICMS: http.req((p: { orgId: string; outdialANIId: string }) => ({
      url: `${CMS_BASE_URL}/outdial-ani/${p.outdialANIId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchOutdialANICMS",
      res: {} as Service.Aqm.Agent.OutdialAniListSuccess
    })),
    fetchTeams: http.req((p: { orgId: string; id: string }) => ({
      url: `${CMS_BASE_URL}/team/${p.id}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchTeamsFailed",
      res: {} as Service.Conf.Team[]
    })),
    secureCADVariables: http.req(data => ({
      url: `/secure`,
      headers: { ...HTTP_HEADERS },
      data,
      err,
      method: "POST",
      errId: "Service.secure.secureCADVariablesFailed",
      res: {} as Service.Conf.SecureData
    })),
    fetchQueues: http.req((p: { orgId: string; id: string }) => ({
      url: `${CMS_BASE_URL}/queue/${p.id}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchQueuesFailed",
      res: {} as Service.Conf.Queue[]
    })),
    fetchEpDnMappings: http.req((p: { orgId: string }) => ({
      url: `${CMS_BASE_URL}/ep-dn-mapping`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.epDnMappingsFailed",
      res: {} as Array<Service.Cms.EpDnListMap>
    })),
    fetchAgentIdleCodes: http.req((p: { orgId: string; agentId: string }) => ({
      url: `${CMS_BASE_URL}/user-idle-code/${p.agentId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: FETCH_IDLECODES_FAILED,
      res: {} as Service.Cms.AgentIdleCodes
    })),
    fetchNtpTime: http.req(() => ({
      url: `/secure/ntp-time`,
      headers: { ...HTTP_HEADERS },
      data: {},
      err,
      method: "GET",
      errId: "Service.abs.fetchNtpTimeFailed",
      res: "" as string
    }))
  };
}

type ErrDetails = { status: number; type: string; trackingId: string };
type absErrorIds =
  | { "Service.abs.fetchAddressBooksCMS": ErrDetails }
  | { "Service.abs.fetchCmsInitConfig": ErrDetails }
  | { "Service.abs.fetchWrapUpCodesFailed": ErrDetails }
  | { "Service.abs.fetchManagedTeamsFailed": ErrDetails }
  | { "Service.abs.fetchManagedQueuesFailed": ErrDetails }
  | { "Service.abs.FetchVTeamListFailed": ErrDetails }
  | { "Service.abs.fetchOutdialANICMS": ErrDetails }
  | { "Service.abs.secureCADVariablesFailed": ErrDetails }
  | { "Service.abs.fetchTeamsFailed": ErrDetails }
  | { "Service.abs.fetchQueuesFailed": ErrDetails }
  | { "Service.abs.epDnMappingsFailed": ErrDetails }
  | { "Service.abs.fetchIdleCodesFailed": ErrDetails }
  | { "Service.abs.fetchNtpTimeFailed": ErrDetails };

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.abs": absErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Agentx.Backend {}
}
