import { SERVICE, Service } from "../../index";

type SecureData = {
  data: Record<string, string>;
};
const checkVariableLength = (cad: Record<string, any>): boolean => {
  for (const i of Object.keys(cad)) {
    if (cad[i].length > 256) {
      return false;
    }
  }
  return true;
};

export const validateSharedCADVariable = (args: any): Promise<void> => {
  return new Promise((_resolve, _reject) => {
    const fieldLength = Object.keys(args).length;
    if (fieldLength > 30) {
      _reject("Keys cant be more then 30.");
    } else {
      if (checkVariableLength(args)) {
        _resolve();
      } else {
        _reject("Maximum length for a CAD variable value is 256 characters.");
      }
    }
  });
};

export const encryptSecureCAD = async (args: {
  interactionId: string;
  data: Service.Aqm.Dialer.updateCadPayLoad;
  secureCad: Array<string>;
  keyId: string | null;
  keyVersion: number | null;
}) => {
  const dataToEncrypt: Record<string, string> = {};
  Object.entries(args.data.attributes).forEach(([key, value]) => {
    if (args.secureCad.indexOf(key) !== -1) {
      dataToEncrypt[key] = value;
    }
  });
  if (Object.keys(dataToEncrypt).length > 0) {
    const secureData = {
      data: dataToEncrypt,
      action: "encrypt",
      keyId: args.keyId,
      keyVersion: args.keyVersion
    };
    try {
      const encryptedData: SecureData = await SERVICE.abs.secureCADVariables(secureData);
      const decryp = encryptedData["data"];

      Object.keys(args.data.attributes).forEach(key => {
        if (args.secureCad.indexOf(key) !== -1) {
          args.data.attributes[key] = decryp[key];
        }
      });
      return Promise.resolve("success");
    } catch (err) {
      return Promise.reject("encryption error");
    }
  }
  return Promise.resolve("success");
};
