/* eslint-disable sonarjs/no-duplicate-string */
export const MIX_PANEL_ERR_MSG = "Error while mixpanel tracking ";
export const MIX_PANEL_CACHE = "agentx--mix-panel-temp";
export const eventConstants = () => {
  return {
    STATION_LOGIN_CLICKED: "Station Login Clicked",
    STATION_LOGIN: "Station Login Complete",
    CHANGE_TEAM: "Change Team",
    AGENT_STATE_CHANGE: "Availability Status Change",
    AGENT_HISTORY_RESIZE: "Agent History Resize",
    DARK_MODE_TOGGLE: "Dark Mode Toggle",
    STATUS: {
      SUCCESS: "Success",
      FAILED: "Failed"
    },
    HELP_ICON_CLICKED: "Help Icon Clicked",
    SURVEY_ICON_CLICKED: "Survey Icon Clicked",
    TASK_PANE_TOGGLE: "Task Pane Toggle",
    INTERACTION_CONTROL_PANE_TOGGLE: "Interaction Control Pane Toggle",
    ERROR_DETAILS_DOWNLOADED: "Error Details Downloaded",
    SIGN_OUT: "Sign Out",
    CAD_VARIABLE_UPDATED: "CAD Variables Updated",
    CALL_INITIATED: "Call Initiated",
    CALL_TOAST_MESSAGE_ACCEPTED: "Call Toast Message Accepted",
    CALL_ANSWERED: "Call Answered",
    AGENT_RONA: "Agent RONA",
    CALL_CONFERENCE_CLICKED: "Call Conference Clicked",
    CALL_CONFERENCE_ACTIVATED: "Call Conference Activated",
    CALL_CONFERENCE_ENDED: "Call Conference Ended",
    END_MONITORING_CLICKED: "End Monitoring Clicked",
    CALL_CONSULT_CLICKED: "Call Consult Clicked",
    CALL_CONSULT_ACTIVATED: "Call Consult Activated",
    CALL_CONSULT_END_CLICKED: "Call Consult End Clicked",
    CALL_CONSULT_END_ACTIVATED: "Call Consult End Activated",
    INTERACTION_CONSULT_REQUEST_CLICKED: "Interaction Consult Request Clicked",
    INTERACTION_CONFERENCE_REQUEST_CLICKED: "Interaction Conference Request Clicked",
    CALL_HOLD_CLICKED: "Call Hold Clicked",
    CALL_HOLD_ACTIVATED: "Call Hold Activated",
    CALL_RESUME_CLICKED: "Call Resume Clicked",
    CALL_RESUME_ACTIVATED: "Call Resume Activated",
    CALL_TRANSFER_CLICKED: "Call Transfer Clicked",
    CALL_TRANSFER_ACTIVATED: "Call Transfer Activated",
    CALL_TRANSFER_COMPLETED: "Call Transfer Completed",
    INTERACTION_TRANSFER_REQUEST_CLICKED: "Interaction Transfer Request Clicked",
    CONSULT_CALL_TRANSFER: "Consult Call Transfer Clicked",
    CALL_PAUSE_RECORDING_CLICKED: "Call Pause Recording Clicked",
    CALL_PAUSE_RECORDING_ACTIVATED: "Call Pause Recording Activated",
    CALL_RESUME_RECORDING_CLICKED: "Call Resume Recording Clicked",
    CALL_RESUME_RECORDING_ACTIVATED: "Call Resume Recording Activated",
    CALL_COMPLETED: "Call Completed",
    CALL_WRAP_UP_APPLIED: "Wrap Up Reason Applied",
    NOTIFICATION_SETTINGS_UPDATE: "Notification Settings Update",
    IVR_TRANSCRIPT: "IVR Transcript Fetch",
    AGENT_INVITE_FAILED: "Agent Invite Failed",
    SCREEN_POP_VIEWED: "Screen Pop Viewed",
    KEYBOARD_SHORTCUT_KEY_PRESSED: "Keyboard Shortcut Key Pressed",
    CONTACT_HISTORY_VIEWED: "Contact History Viewed",
    WXM_JOURNEY_VIEWED: "WXM Journey Viewed",
    DESKTOP_CONNECTION_LOSS: "Desktop Connection Loss",
    LAYOUT_SETTINGS_EDIT: "Layout Settings Edit",
    LAYOUT_SETTINGS_RESET: "Layout Settings Reset",
    LAYOUT_SETTINGS_SAVE: "Layout Settings Save",
    /*eslint-disable-next-line @wxcc-desktop/no-blocked-words-plugin/no-blocked-words */
    LAYOUT_SETTINGS_MASTER_RESET: "Layout Settings Master Reset",
    NOTIFICATION_SOUND_SETTINGS_UPDATE: "Notification Sound Settings Update",
    WEBEX_APP_OPENED: "Webex App opened",
    WEBEX_APP_CLOSED: "Webex App closed",
    WEBEX_APP_OPENED_BY_SHORTCUT_KEY: "Webex App Opened By Shortcut Key",
    SCREEN_POP_INSIDE_DESKTOP_REFERRED: "Screen Pop Inside Desktop Referred",
    SCREEN_POP_DELIVERED: "Screen Pop Delivered",
    AGENT_STATE_SEARCHED: "Agent State Searched",
    AGENT_HISTORY: "Fetch Agent History",
    AGENT_CONSULT_TRANSFER_SEARCH: "Agent Consult/Transfer Search",
    AGENT_INTERACTION_TRANSFERRED: "Agent Interaction Transferred",
    CHANGE_PROFILE_SETTINGS: "Change Profile Settings",
    SWITCH_USER_ROLE: "Switch User Role",
    LAYOUT_FETCHED_FROM_SERVER: "Layout fetched from Server",
    WEB_SOCKET_DISCONNECT: "Web Socket Disconnect",
    CUSTOM_LAYOUT_LOAD_FAIL: "Custom layout load failed",
    WELCOME_MESSAGE_RECEIVED: "Welcome Message Received",
    NOTES_WIDGET_OPENED: "Notes Widget Opened",
    WEBEX_CHAT_TPW_MESSAGE_SEND: "Team Performance Widget Webex Chat Message Sent",
    IDLE_INACTIVITY_SIGN_OUT: "Idle Inactivity Sign Out",
    LANDING_PAGE_IMAGE: "Landing Page Image",
    AID_OPENED: "Active Interaction Details Opened",
    AID_CLOSED: "Active Interaction Details Closed",
    PAUSE_MONITORING_CLICKED: "Pause Monitoring Clicked",
    RESUME_MONITORING_CLICKED: "Resume Monitoring Clicked",
    MIDCALL_MONITORING_STARTED: "Midcall Monitoring Started",
    MIDCALL_MONITORING_OFFERED: "Midcall Monitoring Offered",
    MIDCALL_MONITORING_ENDED: "Midcall Monitoring Ended",
    MIDCALL_MONITORING_FAILED: "Midcall Monitoring Failed",
    BARGE_IN_CLICKED: "Barge In Clicked",
    BARGE_IN_ACTIVATED: "Barge In Activated",
    ADDRESS_BOOK: "Address Book",
    WEBRTC_REGISTRATION: "WebRtc Registration",
    WEBRTC_DISCONNECTED: "WebRtc Disconected",
    WEBRTC_VOICE_CHANNEL_SWITCHED: "Desktop Voice Channel Switched",
    POST_INTERACTION_VISITED: "Post Interaction: Widget Visited",
    PI_TIME_FILTER_SELECTED: "Post Interaction: Time Filter Selected",
    PI_RECORDING_DETAILS_MODAL_CLOSED: "Post Interaction: Recording Details Modal Closed",
    TPW_COLUMN_SORTED: "Team Performance Widget Column Sorted",
    TPW_COLUMN_FILTERED: "Team Performance Widget Column Filtered",
    DESKTOP_VISITED_PAGE: "Desktop Visited Page",
    STATE_CHANGE_ON_PRESENCE_SYNC: "State Change On Presence Sync",
    WEBRTC_REGISTRATION_FAILURE: "WebRtc registration failure"
  };
};

export const propsConstants = () => {
  return {
    CALL_DIRECTION: "Call Direction",
    CONSULT_TYPE: "Consult Type",
    CONSULT_DURATION: "Consult Duration",
    SIGN_IN_TYPE: "Sign In Type",
    AGENT_ID: "Agent ID",
    "Organization ID": "Organization ID",
    "Dark Mode Enabled": "Dark Mode Enabled",
    "Platform Type": "Platform Type",
    "Availability Status": "Availability Status",
    "Number Of Widgets": "Number Of Widgets",
    "Interaction Control State": "Interaction Control State",
    "Task Pane State": "Task Pane State",
    "Tenant ID": "Tenant ID",
    "Team Name": "Team Name",
    Locale: "Locale",
    "Site ID": "Site ID",
    "Browser Height": "Browser Height",
    "Browser Width": "Browser Width",
    "Agent ID": "Agent ID",
    "New Widget State": "New Widget State",
    "Previous Availability Status": "Previous Availability Status",
    "Time In Previous Status": "Time In Previous Status",
    TRANSFER_TYPE: "Transfer Type",
    DIAL_NUMBER_TYPE: "Dial Number Type",
    PAUSE_RECORDING_TIME: "Pause Recording Time",
    TOTAL_CALL_TIME: "Total Call Time",
    TOTAL_CONSULT: "Total Number Of Consult",
    TOTAL_CONSULT_TIME: "Total Consult Time",
    HOLD_TIME: "Hold Time",
    TOTAL_HOLD_TIME: "Total Hold Time",
    TOTAL_TIMES_IN_HOLD: "Total Times in Hold",
    PLATFORM_TYPE: "Platform Type",
    INTERACTION_CONTROL_STATE: "Interaction Control State",
    TASK_PANE_STATE: "Task Pane State",
    ERROR_DETAILS_DOWNLOADED: "Error Details Downloaded",
    SIGN_OUT_METHOD: "Sign Out Method",
    INTERACTION_ID: "InteractionId",
    TOTAL_RECORDING_PAUSE_TIME: "Total Recording Pause Time",
    TOTAL_TIMES_IN_RECORDING_PAUSE: "Total Times In Recording Pause",
    SIGN_IN_FAILURE_REASON: "Sign In Failure Reason",
    TOTAL_TIME_SPENT: "Total Time Spent",
    SHORTCUT_KEY_ACTION: "Shortcut Key Action",
    SHORTCUT_KEY_COMBINATION: "Shortcut Key Combination",
    TOTAL_CONNECTION_LOST_DURATION: "Total Connection Lost Duration",
    ENABLE_NOTIFICATION: "Enable Notification",
    ENABLE_SILENT_NOTIFICATION: "Enable Silent Notification",
    MEDIA_TYPE: "Media Type",
    MEDIA_CHANNEL: "Media Channel",
    LAYOUT_SETTINGS_EDIT: "Layout Settings Edit",
    LAYOUT_SETTINGS_RESET: "Layout Settings Reset",
    LAYOUT_SETTINGS_SAVE: "Layout Settings Save",
    AGENT_HISTORY_RESIZE_HEIGHT: "Agent History Resize Height",
    NOTIFICATION_SOUND_SETTINGS_VOLUME: "Notification Sound Volume",
    ENABLE_SOUND_NOTIFICATION: "Enable Sound Notification",
    ENABLE_SOUND_NOTIFICATION_MUTE: "Enable Sound Notification Mute",
    KEYBOARD_SHORTCUT_COMBINATION_PRESSED: "Keyboard Shortcut Key Combination",
    KEYBOARD_SHORTCUT_KEY_VALUE: "Keyboard Shortcut Key Value",
    SCREEN_POP_TARGET: "Screen Pop Target",
    SCREEN_POP_INTERACTION: "Screen Pop Interaction",
    STOP_NAVIGATE_ON_ACCEPT_TASK: "Stop Navigate on Accept Task",
    LANDING_PAGE_IMAGE_DEFAULT: "DEFAULT",
    LANDING_PAGE_IMAGE_CUSTOM: "CUSTOM",
    LANDING_PAGE_IMAGE_NO_ILLUSTRATION: "NO_ILLUSTRATION",
    ADVANCED_HEADER_ENABLED: "ENABLED",
    ADVANCED_HEADER_DISABLED: "DISABLED",
    ADDRESS_BOOK_LIST: "Address Book List",
    ADDRESS_BOOK_LIST_SEARCH: "Address Book List Search",
    PAGE_NAME: "Page Name",
    PI_MAIN_RECORDING_PLAYED: "Post Interaction: Main Audio Recording Played",
    PI_CONSULT_RECORDING_PLAYED: "Post Interaction: Consult Audio Recording Played",
    PI_NUM_CONSULT_RECORDINGS: "Post Interaction: Number of Consult Recordings",
    PI_TIME_FILTER_DURATION_SELECTED: "Post Interaction: Time Filter Selected"
  };
};

export const cacheKeys = () => {
  return {
    HOLD_COUNT: "hc",
    HOLD_START_TIME: "hst",
    TOTAL_NUMBER_OF_CONSULT: "cc",
    CONSULT_START_TIME: "cst",
    CUMULATIVE_CONSULT_TIME: "ccst",
    CONSULT_TYPE: "ct",
    CONTACT_ASSIGNED_TIME: "cat",
    CUMULATIVE_HOLD_TIME: "cht",
    CUMULATIVE_REC_PAUSED_TIME: "crpt",
    PAUSE_REC_START_TIME: "prst",
    PAUSE_REC_COUNT: "prc",
    ANALYZER_VIEW_START_TIME: "avst",
    CONTACT_HISTORY_START_TIME: "chst",
    WXM_JOURNEY_START_TIME: "wjst",
    WXM_EXPERIENCE_ANALYTICS_START_TIME: "weast",
    CONNECTION_LOSS_START_TIME: "clst",
    AUXILIARY_PANEL_TAB_NAME: "aptn",
    SEARCHED_ADDRESS_BOOK: "sad",
    CHOSE_ADDRESS_BOOK_ENTRY: "cabe"
  };
};
