import { logger } from "../../core/sdk";
import { SERVICE } from "../../index";
import { absService } from "./agentx-backend-proxy-service";

interface ExposedBackendAPIsFunctionType {
  mockOutdialAniList?: () => Promise<any>;
  fetchAddressBooks?: () => Promise<any>;
}

export function exposedBackendAPIs(desktopAqmEolCX11771Enabled: boolean): ExposedBackendAPIsFunctionType {
  async function fetchMockOutidalANIList() {
    let outdialANIListObject: any;
    try {
      const response = await absService().fetchOutdialANICMS({
        orgId: SERVICE.conf.profile?.orgId || "",
        outdialANIId: SERVICE.conf.profile?.outdialANIId || ""
      });
      outdialANIListObject = {
        data: { ...response, jsMethod: "mockOutdialAni" },
        orgId: SERVICE.conf.profile?.orgId,
        trackingId: response.trackingId || "",
        type: "mockOutdialAniList"
      };
      //update the outdial ani store obj
      window.dispatchEvent(
        new CustomEvent("ax-outdial-update-ani-list", {
          detail: { data: outdialANIListObject },
          bubbles: true,
          composed: true
        })
      );
    } catch (error) {
      logger.error("Error fetching outdial entries: ", error);
      throw error;
    }
    return outdialANIListObject;
  }
  async function fetchAllAddressBookEntries() {
    let completeAddressBook: any = [];
    try {
      const response = await absService().fetchAddressBooksCMS({
        orgId: SERVICE.conf.profile?.orgId || "",
        addressBookId: SERVICE.conf.profile?.addressBookId || "",
        page: 0,
        pageSize: 50
      });

      completeAddressBook = completeAddressBook.concat(response.speedDials);

      const totalPages = response.totalPages!;
      const promises = [];

      for (let page = 1; page < totalPages; page++) {
        promises.push(
          absService().fetchAddressBooksCMS({
            orgId: SERVICE.conf.profile?.orgId || "",
            addressBookId: SERVICE.conf.profile?.addressBookId || "",
            page,
            pageSize: 50
          })
        );
      }

      const responses = await Promise.all(promises);
      responses.forEach(response => {
        completeAddressBook = completeAddressBook.concat(response.speedDials);
      });
    } catch (error) {
      logger.error("Error fetching all address book entries: ", error);
      throw error;
    }
    return { speedDials: completeAddressBook };
  }
  return {
    ...(desktopAqmEolCX11771Enabled
      ? {
          mockOutdialAniList: async () => {
            return fetchMockOutidalANIList();
          },
          fetchAddressBooks: async () => {
            return fetchAllAddressBookEntries();
          }
        }
      : {})
  };
}
