import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import userPrefs = Service.userPrefs;

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };
export function userPrefsService() {
  const http = new HttpReqs(CONF.API_GATEWAY);

  return {
    fetchUserPreferences: http.req((p: { userId: string; orgId: string }) => ({
      url: `/organization/${p.orgId}/user-preference/${p.userId}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.orgId },
      data: {},
      err,
      method: "GET",
      errId: "Service.userPrefs.fetchUserPreferences",
      res: {} as userPrefs.savedUserPreferencesResponse
    })),
    saveUserPreferences: http.req(
      (p: {
        organizationId: string;
        id: string;
        data: userPrefs.UserPreferences | userPrefs.emergencyUserPreference;
      }) => ({
        url: `/organization/${p.organizationId}/user-preference/`,
        headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.organizationId },
        data: {
          desktopPreference: JSON.stringify(p.data)
        },
        err,
        method: "POST",
        errId: "Service.userPrefs.saveUserPreferences",
        res: {}
      })
    ),
    updateUserPreferences: http.req(
      (p: {
        organizationId: string;
        id: string;
        data: userPrefs.UserPreferences | userPrefs.emergencyUserPreference;
      }) => ({
        url: `/organization/${p.organizationId}/user-preference/${p.id}`,
        headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.organizationId },
        data: {
          desktopPreference: JSON.stringify(p.data)
        },
        err,
        method: "PUT",
        errId: "Service.userPrefs.updateUserPreferences",
        res: {}
      })
    ),
    deleteUserPreferences: http.req((p: { organizationId: string; id: string }) => ({
      url: `/organization/${p.organizationId}/user-preference/${p.id}`,
      headers: { ...HTTP_HEADERS, "X-ORGANIZATION-ID": p.organizationId },
      data: {},
      err,
      method: "DELETE",
      errId: "Service.userPrefs.deleteUserPreferences",
      res: {}
    }))
  };
}

type userPrefsErrorIds =
  | "Service.userPrefs.fetchUserPreferences"
  | "Service.userPrefs.saveUserPreferences"
  | "Service.userPrefs.updateUserPreferences"
  | "Service.userPrefs.deleteUserPreferences";
declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.userPrefs": userPrefsErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.userPrefs {
    type UserPreferences = {
      selectedRoleName?: string;
      teamName: string | null;
      teamId: string | null;
      dialNumber?: string | null;
      isExtension?: boolean;
      extensionNumber: string;
      dialNumberValueForNonUS: string;
      isInternational: boolean;
      internationalPhoneNumber: string;
      countryCallingCode: string;
      countryCodeValue: string;
      isRememberMyCredentials: boolean;
      deviceType?: Service.Aqm.Agent.DeviceType;
      deviceId?: string | null;
      isEmergencyModalAlreadyDisplayed?: boolean;
    };

    type emergencyUserPreference = {
      isEmergencyModalAlreadyDisplayed: boolean;
    };

    type savedUserPreferencesResponse = {
      organizationId: string;
      id: string;
      desktopPreference: string;
    };

    type EUserPrefsSaved = {
      detail: {
        isPrefSaved: boolean;
      };
    };

    type EUserPrefs = {
      detail: {
        selectedRoleName?: string;
        teamName: string | null;
        teamId: string | null;
        dialNumber?: string | null; // Logged in DN/Extension/international number
        isExtension?: boolean; // True if Extension radio is selected
        extensionNumber: string; // Value in Extension number field
        dialNumberValueForNonUS: string; // Value in DN number field when international checkbox is not checked
        isInternational: boolean; // True if international checkbox is checked
        internationalPhoneNumber: string;
        countryCallingCode: string;
        countryCodeValue: string;
        isRememberMyCredentials: boolean; // True if Remember my credentials checkbox is checked
        deviceType?: Service.Aqm.Agent.DeviceType;
        deviceId?: string | null;
        isEmergencyModalAlreadyDisplayed?: boolean;
      };
    };
    type EUserIsEmergency = {
      detail: {
        isEmergencyModalAlreadyDisplayed: boolean;
      };
    };
  }
}
