import { SERVICE, Service } from "../..";
import { CONF } from "../../config";
import { AqmNotifs } from "../../core/aqm-notifs";
import { AqmReqs, Msg } from "../../core/aqm-reqs";
import { HttpReqs } from "../../core/http-reqs";
import { exposedBackendAPIs } from "../agentx-backend-proxy/agentx-backend-for-jssdk";
import { aqmAgent } from "./aqm-service-agent";
import { aqmConfigs } from "./aqm-service-configs";
import { ConnectionService } from "./aqm-service-connection";
import { aqmContact } from "./aqm-service-contact";
import { aqmDialer } from "./aqm-service-dialer";
import { aqmScreenPop } from "./aqm-service-screenpop";
import { routingAgent } from "./routing-agent-service";
import { createRoutingContactAPIS } from "./routing-contact";
import { aqmSupervisor } from "./routing-supervisor-service";

/**
 * Aqm Service.
 * Initializes Notifs connection as well as provides interface to emit AQM requests.
 */
export class AqmService {
  private readonly notifs: AqmNotifs;

  readonly agent: ReturnType<typeof aqmAgent> & ReturnType<typeof routingAgent>;
  readonly configs: ReturnType<typeof aqmConfigs>;
  readonly contact: ReturnType<typeof aqmContact> & ReturnType<typeof createRoutingContactAPIS>;
  readonly dialer: ReturnType<typeof aqmDialer>;
  readonly connectionConfig: ReturnType<typeof ConnectionService>;
  readonly screenpop: ReturnType<typeof aqmScreenPop>;
  readonly supervisor!: ReturnType<typeof aqmSupervisor>;

  closeSocket(shouldReconnect: boolean, reason: string) {
    this.notifs.close(shouldReconnect, reason);
  }

  constructor(init: (p: () => Promise<void>) => void) {
    this.notifs = new AqmNotifs();

    const aqm = new AqmReqs(this.notifs);
    const http = new HttpReqs(CONF.AQM_HOST);
    if (this.isExternalAgentRouteEnabled()) {
      const agentApiFeatureFlags = this.externalAgentRouteFeatureFlags();
      this.agent = { ...aqmAgent(aqm, http), ...routingAgent(aqm, agentApiFeatureFlags) };
      if (this.isExternalAgentRouteBackendFeatureFlags()) {
        this.agent = {
          ...this.agent,
          ...exposedBackendAPIs(agentApiFeatureFlags.desktopAqmEolCX11771Enabled)
        };
      }
    } else {
      this.agent = aqmAgent(aqm, http);
    }
    if (SERVICE.featureflag.isSupervisorDesktopEnabled() && SERVICE.featureflag.isMidCallMonitoringEnabled()) {
      this.supervisor = { ...aqmSupervisor(aqm) };
    }
    this.configs = aqmConfigs(aqm);

    if (this.isExternalContactRouteEnabled()) {
      const contactApiFeatureFlags = this.externalContactRouteFeatureFlags();
      this.contact = {
        ...aqmContact(aqm, http),
        ...createRoutingContactAPIS(aqm, contactApiFeatureFlags)
      };
    } else {
      this.contact = aqmContact(aqm, http);
    }

    this.dialer = aqmDialer(aqm);
    this.connectionConfig = ConnectionService(this.notifs);
    this.screenpop = aqmScreenPop(aqm);

    init(() => this.notifs.init());
  }

  private isExternalContactRouteEnabled(): boolean {
    return (
      SERVICE.featureflag.isDesktopConsultToEntryPointDnEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolCX11764Enabled() ||
      SERVICE.featureflag.isDesktopQrmApiTransferEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolConsultTransferEnabled() ||
      SERVICE.featureflag.isDesktopEOLConsultConferenceEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolAcceptEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolPauseResumeEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolEndWrapupEnabled()
    );
  }
  private isExternalAgentRouteEnabled(): boolean {
    return (
      (SERVICE.featureflag.isESDStationLoginFlowEnabled() && SERVICE.featureflag.isSupervisorDesktopEnabled()) ||
      SERVICE.featureflag.isDesktopAqmEolStateChangeEnabled() ||
      SERVICE.featureflag.isDesktopAqmEolCX11771Enabled()
    );
  }
  private externalContactRouteFeatureFlags(): Service.Aqm.Global.contactRouteFeatureFlag {
    return {
      desktopEOLHoldUnHold: SERVICE.featureflag.isDesktopAqmEolCX11764Enabled(),
      wxccConsultToEntryPointDn: SERVICE.featureflag.isDesktopConsultToEntryPointDnEnabled(),
      desktopEOLConsultConference: SERVICE.featureflag.isDesktopEOLConsultConferenceEnabled(),
      isWxccQrmApiAgentListEnabled: SERVICE.featureflag.isWxccQrmApiAgentListEnabled(),
      desktopEOLTransfer: SERVICE.featureflag.isDesktopQrmApiTransferEnabled(),
      desktopEOLConsultTransfer: SERVICE.featureflag.isDesktopAqmEolConsultTransferEnabled(),
      desktopEOLEndWrapup: SERVICE.featureflag.isDesktopAqmEolEndWrapupEnabled(),
      desktopEOLAccept: SERVICE.featureflag.isDesktopAqmEolAcceptEnabled(),
      desktopAqmEolPauseResume: SERVICE.featureflag.isDesktopAqmEolPauseResumeEnabled()
    };
  }
  private externalAgentRouteFeatureFlags(): Service.Aqm.Global.agentRouteFeatureFlag {
    return {
      isESDRoutingApisEnabled:
        SERVICE.featureflag.isESDStationLoginFlowEnabled() && SERVICE.featureflag.isSupervisorDesktopEnabled(),
      desktopEOLStateChange: SERVICE.featureflag.isDesktopAqmEolStateChangeEnabled(),
      desktopAqmEolCX11771Enabled: SERVICE.featureflag.isDesktopAqmEolCX11771Enabled()
    };
  }
  private isExternalAgentRouteBackendFeatureFlags(): boolean {
    return SERVICE.featureflag.isDesktopAqmEolCX11771Enabled();
  }
}

declare module "../../index" {
  export namespace Service.Aqm.Global {
    export type Failure = Msg<{
      agentId: string;
      trackingId: string;
      reasonCode: number;
      orgId: string;
      reason: string;
    }>;

    type contactRouteFeatureFlag = {
      desktopEOLHoldUnHold: boolean;
      wxccConsultToEntryPointDn: boolean;
      desktopEOLConsultConference: boolean;
      isWxccQrmApiAgentListEnabled: boolean;
      desktopEOLTransfer: boolean;
      desktopEOLConsultTransfer: boolean;
      desktopEOLEndWrapup: boolean;
      desktopEOLAccept: boolean;
      desktopAqmEolPauseResume: boolean;
    };

    type agentRouteFeatureFlag = {
      isESDRoutingApisEnabled: boolean;
      desktopEOLStateChange: boolean;
      desktopAqmEolCX11771Enabled: boolean;
    };
  }
}
