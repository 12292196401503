import { Err } from "@uuip/unified-ui-platform-sdk";
import axios, { AxiosError } from "axios";
import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import MediaStorageType = Service.MediaStorage;

export class MediaStorage {
  private readonly http = new HttpReqs(CONF.MEDIA_SERVICE_HOST);
  private readonly metaDataErrorId = "Service.mediaStorage.metaData";

  private readonly fetchMetaData = this.http.req(
    (p: { orgId: string; interactionId: string; timeoutMins: number }) => ({
      url: `/media/organization/${p.orgId}/interaction/${p.interactionId}/ivrtranscript?timeOutMins=${p.timeoutMins}`,
      method: "GET",
      err: (e: any) => new Err.Details(this.metaDataErrorId, { axiosError: e.response }),
      res: {} as MediaStorageType.MetaData
    })
  );

  private readonly fetchConversation = async (url: string) => {
    return new Promise<MediaStorageType.IvrTranscriptData>((resolve, reject) => {
      axios
        .get(url)
        .then(conversationResponse => {
          resolve(conversationResponse.data as MediaStorageType.IvrTranscriptData);
        })
        .catch(e => {
          reject(new Err.Details("Service.mediaStorage.conversation", { axiosError: e.response }));
        });
    });
  };

  // flattern the params object recursively
  private readonly getFlatParams = (params: any, paramKey: string) => {
    let flatParams: MediaStorageType.BotTranscript["parameters"] = {};
    if (Array.isArray(params)) {
      params.forEach(param => {
        if (param && typeof param === "object") {
          flatParams = { ...flatParams, ...this.getFlatParams(param, paramKey) };
        } else {
          flatParams[paramKey] = param;
        }
      });
    } else {
      Object.keys(params).forEach(key => {
        const finalKey = paramKey ? `${paramKey} ${key}` : key;
        if (params[key] && typeof params[key] === "object") {
          flatParams = { ...flatParams, ...this.getFlatParams(params[key], finalKey) };
        } else {
          flatParams[finalKey] = params[key];
        }
      });
    }
    return flatParams;
  };

  private readonly parseConversations = (
    conversation: MediaStorageType.IvrTranscriptData["conversation"],
    botName: string
  ) => {
    conversation.forEach((transcript, index) => {
      if (transcript.bot) {
        transcript.bot.botName = botName;
        if (transcript.bot.parameters) {
          transcript.bot.parameters = this.getFlatParams(transcript.bot.parameters, "");
        }
        conversation[index] = transcript;
      }
    });
    return conversation;
  };

  fetchIVRTranscript = async (orgId: string, interactionId: string, timeoutMins: number) => {
    const metaData = await this.fetchMetaData({ orgId, interactionId, timeoutMins });
    let transcriptConversations: MediaStorageType.IvrConversations = [];
    const transcriptMetaDataList = metaData.transcripts;
    for (const transcriptMetaData of transcriptMetaDataList) {
      let { conversation } = await this.fetchConversation(transcriptMetaData.transcriptPath);
      conversation = this.parseConversations(conversation, transcriptMetaData.botName);
      transcriptConversations = transcriptConversations.concat(conversation);
    }
    return transcriptConversations;
  };

  fetchStorageEmailContent = async (url: string) => {
    return new Promise<Service.Emm.EmailStorageContent>((resolve, reject) => {
      axios
        .get(url)
        .then(emailContentResponse => {
          resolve(emailContentResponse.data as Service.Emm.EmailStorageContent);
        })
        .catch(e => {
          reject({ axiosError: e.message });
        });
    });
  };

  private readonly getStorageUrl = this.http.req((resourceUrl: string) => ({
    url: `/${resourceUrl.substring(resourceUrl.indexOf("mediastorage/v1"))}`,
    method: "GET",
    err: (e: any) => new Err.Details(this.metaDataErrorId, { axiosError: e.response }),
    res: {} as Service.MediaStorage.preSignedData
  }));

  downloadStorageAttachment = async (url: string) => {
    const data: Service.MediaStorage.preSignedData = await this.getStorageUrl(url);
    return new Promise<"arraybuffer">((resolve, reject) => {
      axios
        .get(data.preSignedUrl, {
          responseType: "arraybuffer"
        })
        .then(emailContentResponse => {
          resolve(emailContentResponse.data);
        })
        .catch(e => {
          reject({ axiosError: e.message });
        });
    });
  };
}

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.mediaStorage":
        | { "Service.mediaStorage.metaData": MediaStorageType.Failure }
        | { "Service.mediaStorage.conversation": MediaStorageType.Failure };
    }
  }
}

declare module "../../index" {
  export namespace Service.MediaStorage {
    type MetaData = {
      orgId: string;
      interactionId: string;
      timeOutMins: number;
      transcripts: Array<TranscriptMetaData>;
    };

    type TranscriptMetaData = {
      transcriptId: string;
      startTime: number;
      stopTime: number;
      cvaId: string;
      botName: string;
      transcriptPath: string;
    };

    type IvrTranscriptData = {
      conversation: IvrConversations;
    };

    type IvrConversations = Array<Transcript>;

    type Transcript = {
      customer?: CustomerTranscript;
      bot?: BotTranscript;
    };

    type CustomerTranscript = {
      query: string;
      sentiment: number;
      timestamp: number;
    };

    type BotTranscript = {
      timestamp: number;
      confidence: number;
      reply: string;
      intentName?: string;
      parameters?: BotTranscriptParams;
      intentId?: string;
      botName?: string;
    };

    type Failure = {
      axiosError: AxiosError["response"];
    };

    type BotTranscriptParams = any;

    type preSignedData = {
      preSignedUrl: string;
    };
  }
}
