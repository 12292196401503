/* eslint-disable @typescript-eslint/no-explicit-any */
import { SERVICE, Service } from "../..";
import { TIMEOUT_REQ } from "../../core/aqm-reqs";
import { logger } from "../../core/sdk";
import { AqmService } from "../aqm/aqm-service";
import { LOST_CONNECTION_RECOVERY_TIMEOUT } from "../aqm/constants";
import { DEFAULT_LOGIN_VOICE_OPTIONS, LOGIN_VOICE_OPTIONS_FOR_ORDER } from "../constant";

const IDLE = "Idle";
const LOGGEDIN = "LoggedIn";

export class ConfService {
  private readonly aqm: AqmService;

  profile: Service.Conf.Profile | null = null;
  abs: any;
  subStatus: string = IDLE;
  status: string = LOGGEDIN;

  constructor(aqm: AqmService, abs: any) {
    this.aqm = aqm;
    this.abs = abs;
  }

  getCampaignManagerAdditionalInfo(
    orgUrlMappingresponse: Service.Cms.OrgUrlMappingResponse,
    agent: Service.Aqm.Configs.AgentPropertyChanged["data"]["data"]
  ) {
    if (SERVICE.featureflag.isDesktopAddOnsEnabled()) {
      return "";
    } else {
      return agent.attribute.find((a: { name: string }) => a.name === "campaignManagerAdditionalInfo")?.value ?? "";
    }
  }

  getLcmUrl(
    orgUrlMappingresponse: Service.Cms.OrgUrlMappingResponse,
    agent: Service.Aqm.Configs.AgentPropertyChanged["data"]["data"]
  ) {
    let url;
    if (SERVICE.featureflag.isDesktopAddOnsEnabled()) {
      url = orgUrlMappingresponse?.find(a => a.name === SERVICE.constants.AcqueonConstants.ACQUEON_API_URL)?.url ?? "";
    } else {
      url = agent.attribute.find(a => a.name === "campaignManagerHosts")?.value ?? "";
    }
    return this.getFormattedUrl(url);
  }

  getFormattedUrl(url: string) {
    if (!url.includes("https")) {
      url = `https://${url}`;
    }
    return url;
  }

  getSortedVoiceOptions = (loginVoiceOptions: Service.Conf.LoginOption[]) => {
    const sortedVoiceOptions: Service.Conf.LoginOption[] = [];
    LOGIN_VOICE_OPTIONS_FOR_ORDER.forEach(option => {
      if (loginVoiceOptions.includes(option)) {
        sortedVoiceOptions.push(option);
      }
    });
    return sortedVoiceOptions;
  };

  getLoginVoiceOptions = (loginVoiceOptions: Service.Conf.LoginOption[] | undefined, webRtcEnabled: boolean) => {
    if (loginVoiceOptions) {
      if (webRtcEnabled) {
        if (loginVoiceOptions.length !== 0) {
          return this.getSortedVoiceOptions(loginVoiceOptions.sort());
        }
      } else {
        // remove browser option if webRtc is not Enabled
        const index = loginVoiceOptions.indexOf(SERVICE.constants.LoginVoiceOptions.BROWSER, 0);
        if (index > -1) {
          loginVoiceOptions?.splice(index, 1);
        }
        return loginVoiceOptions.length === 0 ? [] : this.getSortedVoiceOptions(loginVoiceOptions.sort());
      }
    }
    return DEFAULT_LOGIN_VOICE_OPTIONS;
  };

  getDefaultIdleCode = (idleCodes: Service.Aqm.Configs.Entity[]) => {
    return idleCodes?.find(c => c.isDefault);
  };

  getDefaultWrapUpCode = (wrapUpReasonList: Service.Aqm.Configs.Entity[]) => {
    return wrapUpReasonList?.find((c: Service.Aqm.Configs.Entity) => c.isDefault);
  };

  updateAuxCodeConfig = (idleCodes: Service.Aqm.Configs.Entity[], wrapUpReasonList: Service.Aqm.Configs.Entity[]) => {
    if (this.profile) {
      if (!SERVICE.featureflag.isDesktopAuxCodesInfiniteScrollEnabled()) {
        // For Idle codes
        this.profile.idleCodes = idleCodes;
        const defaultIdleData = this.getDefaultIdleCode(idleCodes);
        if (defaultIdleData) {
          // Update default Idle code
          this.setDefaultIdleCode(defaultIdleData);
        }
      }

      // For WrapUp codes
      this.profile.wrapupCodes = wrapUpReasonList;
      if (this.profile.wrapUpData?.wrapUpProps) {
        this.profile.wrapUpData.wrapUpProps.wrapUpReasonList = wrapUpReasonList;
      }

      const defaultWrapUpData = this.getDefaultWrapUpCode(wrapUpReasonList);
      if (defaultWrapUpData) {
        // Update default WrapUp code
        this.setDefaultWrapUpCode(defaultWrapUpData);
      }
    }
  };

  setDefaultIdleCode = (defaultIdleData: { id: string; name: string }) => {
    if (this.profile && defaultIdleData) {
      this.profile.idleCode = defaultIdleData; // Idle code Object.
      this.profile.defaultIdleName = defaultIdleData?.name ?? "";
    }
  };

  setDefaultWrapUpCode = (defaultWrapUpData: { id: string; name: string }) => {
    if (this.profile && defaultWrapUpData) {
      this.profile.defaultWrapupCode = defaultWrapUpData?.id ?? "";
    }
  };

  initializeProfile = (configResp: Service.Conf.Profile, orgId?: string) => {
    this.profile!.loginVoiceOptions = this.getLoginVoiceOptions(
      configResp?.loginVoiceOptions,
      configResp?.webRtcEnabled ?? false
    );
    this.profile!.webRtcEnabled = configResp.webRtcEnabled;
    this.profile!.regexUS = new RegExp(configResp?.regexUS);
    this.profile!.regexOther = new RegExp(configResp?.regexOther);
    this.profile!.orgId = orgId ?? "";
    this.profile!.agentSubStatus = this.subStatus;
    this.profile!.agentStatus = this.status;
    this.aqm.connectionConfig.setConnectionProp({
      lostConnectionRecoveryTimeout: this.profile!.lostConnectionRecoveryTimeout
    });
  };

  parseAgentBooleanAttribute = (agent: Service.Aqm.Configs.AgentPropertyChanged["data"]["data"], name: string) => {
    return JSON.parse(agent.attribute.find(item => item.name === name)?.value ?? "false");
  };
  parseAgentProfileBooleanAttribute = (
    profile: Service.Aqm.Configs.UpdateAgentProfile["data"]["data"]["agent-profile"],
    name: string
  ) => {
    const profileAttribute = "profile-attribute";
    return JSON.parse(profile[profileAttribute].find(item => item.name === name)?.value ?? "false");
  };

  buildAgentProfile = (
    agent: Service.Aqm.Configs.AgentPropertyChanged["data"]["data"],
    agentProfile: Service.Aqm.Configs.UpdateAgentProfile["data"]["data"]["agent-profile"],
    teams: {
      teamId: string;
      teamName: string;
      desktopLayoutId: string;
    }[],
    wrapUpData: any,
    wrapupCodes: Service.Aqm.Configs.Entity[],
    orgUrlMappingresponse: Service.Cms.OrgUrlMappingResponse,
    app: any
  ) => {
    const idleCodes = agentProfile.entityList.find((e: any) => e.type === "idleCode")?.entity ?? [];
    const lostConnectionRecoveryTimeout: string | undefined = agent.attribute.find(
      (a: any) => a?.name === "lostConnectionRecoveryTimeout"
    )?.value;

    const profileAttribute = "profile-attribute";
    this.aqm.connectionConfig.setConnectionProp({
      lostConnectionRecoveryTimeout: lostConnectionRecoveryTimeout
        ? parseInt(lostConnectionRecoveryTimeout)
        : LOST_CONNECTION_RECOVERY_TIMEOUT
    });
    return {
      teams,
      defaultDn: agent["std.defaultDn"],
      agentAnalyzerId: agent.agentAnalyzerId,
      agentId: agent["std.agentId"],
      agentName: agent["std.userRealName"],
      agentMailId: agent["std.agentName"],
      agentSubStatus: agent["std.subStatus"] || this.subStatus,
      skillProfileId: agent["skillProfileId"],
      siteId: agent["std.siteId"],
      enterpriseId: agent["std.enterpriseId"],
      agentProfileID: agent["agentProfileIDsCSV"],
      privacyShieldVisible: this.parseAgentBooleanAttribute(agent, "privacyShieldVisible"),
      orgId: app.orgId,
      dialPlan: agentProfile.dialPlan,
      addressBookId: JSON.parse(
        agentProfile[profileAttribute].find((a: any) => a.name === "addressBookIds")?.value || "[]"
      )[0],
      forceDefaultDn: this.parseAgentBooleanAttribute(agent, "forceDefaultDn"),
      forceDefaultDnForAgent: this.parseAgentProfileBooleanAttribute(agentProfile, "forceDefaultDn"),
      regexUS: new RegExp(agent.attribute.find(a => a.name === "dn.default.regex")!.value),
      regexOther: new RegExp(agent.attribute.find((a: any) => a.name === "dn.other.regex")!.value),
      idleCodes,
      idleCode: this.getDefaultIdleCode(idleCodes),
      wrapupCodes,
      cadVariables: agent["cadVariables"],
      callVariablesSuppressed: agent["callVariablesSuppressed"],
      agentStatus: this.status,
      loginVoiceOptions: DEFAULT_LOGIN_VOICE_OPTIONS,
      webRtcEnabled: false,
      defaultIdleName: idleCodes.find(c => c.isDefault)?.name ?? "",
      defaultWrapupCode: wrapupCodes.find(c => c.isDefault)?.id ?? "",
      isOutboundEnabledForTenant: this.parseAgentBooleanAttribute(agent, "outdialEnabled"),
      isOutboundEnabledForAgent: this.parseAgentProfileBooleanAttribute(agentProfile, "outdialEnabled"),
      isAdhocDialingEnabled: this.parseAgentProfileBooleanAttribute(agentProfile, "adhocDialingEnabled"),
      isAgentAvailableAfterOutdial: JSON.parse(
        agentProfile[profileAttribute].find(
          a => a.name === "agentAvailableAfterOutdial" || a.name === "isAgentAvailableAfterOutdial"
        )?.value ?? "false"
      ),
      isCampaignManagementEnabled: this.parseAgentBooleanAttribute(agent, "isCampaignManagementEnabled"),
      campaignManagerAdditionalInfo: this.getCampaignManagerAdditionalInfo(orgUrlMappingresponse, agent),
      outDialEp: agentProfile.outDialEp,
      wrapUpData,
      isEndCallEnabled: this.parseAgentBooleanAttribute(agent, "endCallEnabled"),
      isEndConsultEnabled: this.parseAgentBooleanAttribute(agent, "endConsultEnabled"),
      lcmUrl: this.getLcmUrl(orgUrlMappingresponse, agent),
      agentDbId: agentProfile[profileAttribute].find((a: any) => a.name === "agentDbid")!.value,
      allowConsultToQueue: JSON.parse(
        agentProfile[profileAttribute].find(a => a.name === "consultToQueueAllowed" || a.name === "allowConsultToQueue")
          ?.value ?? "false"
      ),
      agentPersonalStatsEnabled: this.parseAgentProfileBooleanAttribute(agentProfile, "agentPersonalStatsEnabled"),
      preferredSupervisorTeamId: "",
      isTimeoutDesktopInactivityEnabled: this.parseAgentProfileBooleanAttribute(
        agentProfile,
        "timeoutDesktopInactivityCustomEnabled"
      ),
      timeoutDesktopInactivityMins: parseInt(
        agentProfile[profileAttribute].find((a: any) => a.name === "timeoutDesktopInactivityMins")?.value ?? "0"
      ),
      organizationIdleCodes: [],
      lostConnectionRecoveryTimeout: parseInt(lostConnectionRecoveryTimeout ?? "120000")
    };
  };

  async fetchProfile(
    cmsConfigFlag = false,
    orgId?: string,
    userId?: string,
    isSupervisor?: boolean
  ): Promise<Service.Conf.Profile> {
    // request
    logger.info(`CMS Config featureFlag turned on - ${cmsConfigFlag}`);
    logger.info("event=requestAgentConfig | Requesting agent configurations");

    // In order to get the current agent state - 'Available' on reload.
    this.aqm.agent.eAgentStateChangeSuccess.listen(data => {
      this.subStatus = data.data.subStatus;
    });

    this.aqm.agent.eAgentReloginSuccess.listen(data => {
      this.status = data.data.status;
    });

    const ifMaerskEnabled = () => {
      return SERVICE.featureflag.isDesktopMaerskEnhancementEnabled();
    };

    if (cmsConfigFlag) {
      const isMaerskEnabled = ifMaerskEnabled();
      const isDesktopAuxCodesInfiniteScrollEnabled = SERVICE.featureflag.isDesktopAuxCodesInfiniteScrollEnabled();
      const configResp: Service.Conf.Profile = await this.abs.fetchCmsInitConfig({
        orgId,
        userId,
        isSupervisor
      });
      this.profile = configResp;
      this.initializeProfile(configResp, orgId);

      if (!isDesktopAuxCodesInfiniteScrollEnabled && !isMaerskEnabled) {
        // If isDesktopAuxCodesInfiniteScrollEnabled and isMaerskEnabled FF is not enabled,
        // then /agent-config endpoint will return the whole idleCodes.
        this.updateAuxCodeConfig(
          configResp?.idleCodes ?? [],
          configResp.wrapUpData?.wrapUpProps?.wrapUpReasonList ?? []
        );
      }

      if (configResp.urlMappings?.acqueonApiUrl && configResp.urlMappings?.acqueonConsoleUrl) {
        this.profile.lcmUrl =
          this.getFormattedUrl(
            new URL(this.getFormattedUrl(configResp.urlMappings?.acqueonApiUrl) ?? "")?.hostname ?? ""
          ) ?? "";
        this.profile.campaignManagerAdditionalInfo = `URI=${new URL(
          this.getFormattedUrl(configResp.urlMappings?.acqueonConsoleUrl) ?? ""
        )?.pathname?.replace(/\//, "") ?? ""};APIURI=${new URL(
          this.getFormattedUrl(configResp.urlMappings?.acqueonApiUrl) ?? ""
        )?.pathname?.replace(/\//, "") ?? ""}`;
      }
      logger.info("CmsConfigResp from proxy Abs:::", this.profile);
      return this.profile;
    } else {
      let orgUrlMappingresponse: Service.Cms.OrgUrlMappingResponse = [];
      if (SERVICE.featureflag.isDesktopAddOnsEnabled()) {
        // execute the API when the FF is enabled
        orgUrlMappingresponse = await SERVICE.cms.fetchOrgUrlMappingByNameV2({
          orgId: orgId ?? ""
        });
        logger.info(`fetchOrgUrlMappingByNameV2 ${orgUrlMappingresponse}`);
      }

      const [profile, app] = await Promise.all([
        this.aqm.configs.eUpdateAgentProfile.listenOnceAsync({
          timeout: TIMEOUT_REQ
        }),
        this.aqm.configs.application()
      ]);

      // Load teams for get the desktoplayoutid
      const allTeams: Service.Cms.AllTeamResponse = await SERVICE.cms.fetchAllTeams(app.orgId);
      logger.info(`event=fetchAllTeams success for orgId ${app.orgId} `);
      const getDesktopLayoutId = (teamId: string, allTeams: Service.Cms.AllTeamResponse) => {
        const auxData = allTeams.filter(
          (team: any) =>
            (teamId === team.dbId || teamId === team.id) && team.desktopLayoutId && team.desktopLayoutId !== ""
        );
        return auxData && auxData.length > 0 ? auxData[0].desktopLayoutId : "";
      };

      logger.info("event=agentPropertyChanged | agent properties fetch success", app);
      logger.info("event=updateAgentProfile | agent profile fetch success", profile);

      const profileAttribute = "profile-attribute";
      const agent = app.data.data;

      const agentProfile = profile.data.data["agent-profile"];
      const teamIds = agent["std.teamIdsCSV"].split(",").map(x => x.trim());
      const teamValues = agent["std.teamNamesCSV"].split(",").map(x => x.trim());
      const teams = teamIds.map((k, i) => ({
        teamId: k,
        teamName: teamValues[i],
        desktopLayoutId: getDesktopLayoutId(k, allTeams)
      }));

      const wrapupCodes = agentProfile.entityList.find(e => e.type === "wrapupCode")?.entity ?? [];

      // Populating wrap up data
      // 'This logic needs to move to AgentX together with fetching config and mmprofile etc'
      const autoWrapup: boolean = agentProfile[profileAttribute]
        ? JSON.parse(
            agentProfile[profileAttribute].find(item => item.name === "autoWrapUp" || item.name === "auto-wrapup")!
              .value
          )
        : false;
      const autoWrapupInterval: number = agentProfile
        ? JSON.parse(
            agentProfile[profileAttribute].find(
              item => item.name === "autoWrapUpInterval" || item.name === "auto-wrapup-interval"
            )!.value
          )
        : 0;
      const lastAgentRoute: boolean = agentProfile
        ? JSON.parse(agentProfile[profileAttribute].find(item => item.name === "lastAgentRouting")!.value)
        : false;
      const allowCancelAutoWrapup = agentProfile[profileAttribute]
        ? JSON.parse(
            agentProfile[profileAttribute].find(
              item => item.name === "autoWrapUpExtensionAllowed" || item.name === "allowAutoWrapupExtension"
            )!.value
          )
        : false;

      const wrapUpData = {
        wrapUpProps: {
          autoWrapup,
          autoWrapupInterval,
          lastAgentRoute,
          wrapUpReasonList: wrapupCodes,
          interactionId: "",
          allowCancelAutoWrapup
        }
      };

      this.profile = this.buildAgentProfile(
        agent,
        agentProfile,
        teams,
        wrapUpData,
        wrapupCodes,
        orgUrlMappingresponse,
        app
      );

      return this.profile;
    }
  }
  updateProfileTeams(teams: Service.Conf.Team[]) {
    if (this.profile) {
      this.profile.teams = teams;
    }
  }
}

declare module "../../index" {
  export namespace Service.Conf {
    export type Profile = {
      microsoftConfig?: {
        showUserDetailsMS?: boolean;
        stateSynchronizationMS?: boolean;
      };
      webexConfig?: {
        showUserDetailsWebex?: boolean;
        stateSynchronizationWebex?: boolean;
      };
      teams: Team[];
      defaultDn: string;
      forceDefaultDn: Record<string, any> | boolean;
      forceDefaultDnForAgent: boolean;
      regexUS: RegExp | string;
      regexOther: RegExp | string;
      agentId: string;
      agentName: string;
      agentMailId: string;
      agentSubStatus: string;
      agentStatus: string;
      agentProfileID: string;
      dialPlan: Service.Aqm.Configs.DialPlan;
      skillProfileId: string;
      siteId: string;
      enterpriseId: string;
      privacyShieldVisible: string | boolean;
      idleCodes: Service.Aqm.Configs.Entity[];
      idleCodesList?: Array<string>;
      idleCodesAccess?: "ALL" | "SPECIFIC";
      idleCode: any;
      defaultIdleName: string;
      wrapupCodes: Service.Aqm.Configs.Entity[];
      defaultWrapupCode: string;
      wrapUpData: Service.Aqm.Configs.WrapupData | any;
      orgId: string;
      isOutboundEnabledForTenant: boolean;
      isOutboundEnabledForAgent: boolean;
      isAdhocDialingEnabled: boolean;
      isAgentAvailableAfterOutdial: boolean;
      isCampaignManagementEnabled: boolean;
      outDialEp: string;
      isEndCallEnabled: boolean;
      isEndConsultEnabled: boolean;
      cadVariables: Service.Aqm.Configs.CadVariables[];
      callVariablesSuppressed: any; //not needed going forward ,added for bkward compatibility
      lcmUrl: string;
      agentDbId: string;
      agentAnalyzerId: string;
      allowConsultToQueue: boolean;
      campaignManagerAdditionalInfo: string;
      agentPersonalStatsEnabled: boolean;
      addressBookId?: string;
      outdialANIId?: string;
      analyserUserId?: string;
      isCallMonitoringEnabled?: boolean;
      isMidCallMonitoringEnabled?: boolean;
      isBargeInEnabled?: boolean;
      isManagedTeamsEnabled?: boolean;
      isManagedQueuesEnabled?: boolean;
      isSendMessageEnabled?: boolean;
      isAgentStateChangeEnabled?: boolean;
      urlMappings?: URLMappings;
      preferredSupervisorTeamId: string;
      isTimeoutDesktopInactivityEnabled: boolean;
      timeoutDesktopInactivityMins?: number;
      isAnalyzerEnabled?: boolean;
      tenantTimezone?: string;
      isMonitoringHoldUnHoldEnabled?: boolean;
      loginVoiceOptions?: LoginOption[];
      deviceType?: Service.Aqm.Agent.DeviceType;
      webRtcEnabled: boolean;
      organizationIdleCodes?: Service.Aqm.Configs.Entity[];
      isRecordingManagementEnabled?: boolean;
      lostConnectionRecoveryTimeout: number;
      maskSensitiveData?: boolean;
    };

    export type LoginOption = "AGENT_DN" | "EXTENSION" | "BROWSER";

    export type Team = {
      teamId: string;
      teamName: string;
      desktopLayoutId?: string;
    };

    export type Queue = {
      queueId: string;
      queueName: string;
    };

    export type URLMappings = {
      acqueonApiUrl: string;
      acqueonConsoleUrl: string;
    };

    export type Role = {
      roleId: string;
      roleName: string;
      roleDisplay: string;
    };

    export type UserProfile = {
      teamName: string | null;
      teamId: string | null;
      roles: Array<string>;
      dnNumber: string;
      isExtension: boolean;
      usesOtherDN: boolean;
      extensionNumber: string;
      dialNumberValueForNonUS: string;
      isInternational: boolean;
      phoneNumber: string;
      countryCallingCode: string;
      countryCodeValue: string;
      isRememberMyCredentials: boolean;
      deviceType?: Service.Aqm.Agent.DeviceType;
      isEmergencyModalAlreadyDisplayed?: boolean;
    };

    export type OptionMember = { [key: string]: string };

    export type SecureData = {
      data: Record<string, string>;
    };
  }
}
