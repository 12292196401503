import { Service } from "..";

enum AgentState {
  Idle = "Idle",
  Available = "Available",
  RONA = "RONA",
  AvailableAuxCodeId = "0"
}

enum ContactDirection {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND"
}

enum WebexConstants {
  CALL_INACTIVE = "CALL_INACTIVE",
  MEETING_INACTIVE_TERMINATING = "MEETING_INACTIVE_TERMINATING",
  CALL = "CALL",
  MEETING = "MEETING",
  INCOMING = "INCOMING",
  JOIN = "JOIN",
  WEBEX_DND = "WEBEX_DND",
  WEBEX_MEETING_TIME_STAMP = "webex-meeting-time-stamp",
  WEBEX_PRESENCE_UPDATE = "event:apheleia.subscription_update",
  AX_PRESENCE_STATE_UPDATE = "ax-presence-state-update",
  WEBEX_SDK_PRESENCE_UPDATE = "updated"
}

enum AcqueonConstants {
  ACQUEON_API_URL = "ACQUEON_API_URL",
  CALABRIO_URL = "CALABRIO_URL",
  ACQUEON_CONSOLE_URL = "ACQUEON_CONSOLE_URL"
}
export enum RtdConstants {
  RTD_PING_EVENT = "rtd-online-status"
}

export enum LoginVoiceOptions {
  AGENT_DN = "AGENT_DN",
  EXTENSION = "EXTENSION",
  BROWSER = "BROWSER"
}

export enum MicrosoftServiceEvents {
  RENEW_ACCESS_TOKEN_FAILURE = "RENEW_ACCESS_TOKEN_FAILURE"
}

const IMAGE_BASE_URL = "/images/illustrations";
const APPLE_DARK_NORMAL = `${IMAGE_BASE_URL}/apple-messages-dark.svg`;
const APPLE_LIGNT_NORMAL = `${IMAGE_BASE_URL}/apple-messages-dark.svg`;
const GBM_DARK_COMMUNICATION = `${IMAGE_BASE_URL}/gbm-communication-status-dark.svg`;
const GBM_LIGHT_COMMUNICATION = `${IMAGE_BASE_URL}/gbm-communication-status-light.svg`;
const EMAIL_OUTBOUND_LIGHT_NORMAL = `${IMAGE_BASE_URL}/email-outgoing.svg`;
const EMAIL_OUTBOUND_DARK_NORMAL = `${IMAGE_BASE_URL}/email-outgoing-darkUI.svg`;
const EMAIL_OUTBOUND_LIGHT_COMMUNICATION = `${IMAGE_BASE_URL}/email-outgoing-communication.svg`;
const EMAIL_OUTBOUND_DARK_COMMUNICATION = `${IMAGE_BASE_URL}/email-outgoing-communication-darkUI.svg`;
const EMAIL_OUTBOUND_LIGHT_AIH = `${IMAGE_BASE_URL}/email-outgoing-aih-light.svg`;
const EMAIL_OUTBOUND_DARK_AIH = `${IMAGE_BASE_URL}/email-outgoing-aih-light.svg`;
const SMS_OUTBOUND_LIGHT_NORMAL = `${IMAGE_BASE_URL}/SMS-outgoing.svg`;
const SMS_OUTBOUND_DARK_NORMAL = `${IMAGE_BASE_URL}/SMS-outgoing-darkUI.svg`;
const SMS_OUTBOUND_LIGHT_COMMUNICATION = `${IMAGE_BASE_URL}/SMS-outgoing-communication.svg`;
const SMS_OUTBOUND_DARK_COMMUNICATION = `${IMAGE_BASE_URL}/SMS-outgoing-communication-darkUI.svg`;
const SMS_OUTBOUND_LIGHT_AIH = `${IMAGE_BASE_URL}/SMS-outgoing-aih-light.svg`;
const SMS_OUTBOUND_DARK_AIH = `${IMAGE_BASE_URL}/SMS-outgoing-aih-light.svg`;
const SMS_INBOUND_LIGHT_NORMAL = `${IMAGE_BASE_URL}/SMS-incoming.svg`;
const SMS_INBOUND_LIGHT_AIH = `${IMAGE_BASE_URL}/SMS-incoming-AIH.svg`;
const SMS_INBOUND_LIGHT_COMMUNICATION = `${IMAGE_BASE_URL}/SMS-incoming-communication.svg`;
const SMS_INBOUND_DARK_NORMAL = `${IMAGE_BASE_URL}/SMS-incoming-darkUI.svg`;
const SMS_INBOUND_DARK_AIH = `${IMAGE_BASE_URL}/SMS-incoming-AIH-darkUI.svg`;
const SMS_INBOUND_DARK_COMMUNICATION = `${IMAGE_BASE_URL}/SMS-incoming-communication-darkUI.svg`;

/*
  normal - When the task item is Active
  unselected - When the task item is inactive/ unselected
  aih - AIH Icon
  communication - Communication status icon used in the Accordion
  notification -  Chat Message Notification Icon
*/

export const MEDIA_SUBCHANNEL_TYPES = {
  appleMessages: {
    normal: {
      dark: APPLE_DARK_NORMAL,
      light: APPLE_LIGNT_NORMAL
    },
    unselected: {
      dark: APPLE_DARK_NORMAL,
      light: APPLE_LIGNT_NORMAL
    },
    aih: {
      dark: `${IMAGE_BASE_URL}/apple-messages-aih-dark.svg`,
      light: `${IMAGE_BASE_URL}/apple-messages-aih-light.svg`
    },
    communication: {
      dark: `${IMAGE_BASE_URL}/apple-messages-communication-status-dark.svg`,
      light: `${IMAGE_BASE_URL}/apple-messages-communication-status-light.svg`
    },
    notification: {
      dark: APPLE_DARK_NORMAL,
      light: APPLE_LIGNT_NORMAL
    }
  },

  googleMessages: {
    normal: {
      dark: `${IMAGE_BASE_URL}/gbm-dark.svg`,
      light: `${IMAGE_BASE_URL}/gbm-light.svg`
    },
    unselected: {
      dark: `${IMAGE_BASE_URL}/gbm-nostroke-dark.svg`,
      light: `${IMAGE_BASE_URL}/gbm-nostroke-light.svg`
    },
    aih: {
      dark: `${IMAGE_BASE_URL}/gbm-aih-dark.svg`,
      light: `${IMAGE_BASE_URL}/gbm-aih-light.svg`
    },
    communication: {
      dark: GBM_DARK_COMMUNICATION,
      light: GBM_LIGHT_COMMUNICATION
    },
    notification: {
      dark: GBM_DARK_COMMUNICATION,
      light: GBM_LIGHT_COMMUNICATION
    }
  },
  outboundEmail: {
    normal: {
      dark: EMAIL_OUTBOUND_DARK_NORMAL,
      light: EMAIL_OUTBOUND_LIGHT_NORMAL
    },
    unselected: {
      dark: EMAIL_OUTBOUND_DARK_NORMAL,
      light: EMAIL_OUTBOUND_LIGHT_NORMAL
    },
    aih: {
      dark: EMAIL_OUTBOUND_DARK_AIH,
      light: EMAIL_OUTBOUND_LIGHT_AIH
    },
    communication: {
      dark: EMAIL_OUTBOUND_DARK_COMMUNICATION,
      light: EMAIL_OUTBOUND_LIGHT_COMMUNICATION
    },
    notification: {
      dark: EMAIL_OUTBOUND_DARK_NORMAL,
      light: EMAIL_OUTBOUND_LIGHT_NORMAL
    }
  },
  outboundSms: {
    normal: {
      dark: SMS_OUTBOUND_DARK_NORMAL,
      light: SMS_OUTBOUND_LIGHT_NORMAL
    },
    unselected: {
      dark: SMS_OUTBOUND_DARK_NORMAL,
      light: SMS_OUTBOUND_LIGHT_NORMAL
    },
    aih: {
      dark: SMS_OUTBOUND_DARK_AIH,
      light: SMS_OUTBOUND_LIGHT_AIH
    },
    communication: {
      dark: SMS_OUTBOUND_DARK_COMMUNICATION,
      light: SMS_OUTBOUND_LIGHT_COMMUNICATION
    },
    notification: {
      dark: SMS_OUTBOUND_DARK_NORMAL,
      light: SMS_OUTBOUND_LIGHT_NORMAL
    }
  },
  sms: {
    normal: {
      dark: SMS_INBOUND_DARK_NORMAL,
      light: SMS_INBOUND_LIGHT_NORMAL
    },
    unselected: {
      dark: SMS_INBOUND_DARK_NORMAL,
      light: SMS_INBOUND_LIGHT_NORMAL
    },
    aih: {
      dark: SMS_INBOUND_DARK_AIH,
      light: SMS_INBOUND_LIGHT_AIH
    },
    communication: {
      dark: SMS_INBOUND_DARK_COMMUNICATION,
      light: SMS_INBOUND_LIGHT_COMMUNICATION
    },
    notification: {
      dark: SMS_INBOUND_DARK_NORMAL,
      light: SMS_INBOUND_LIGHT_NORMAL
    }
  }
};

export function constant() {
  return {
    AgentState,
    ContactDirection,
    WebexConstants,
    AcqueonConstants,
    RtdConstants,
    LoginVoiceOptions,
    MicrosoftServiceEvents,
    MEDIA_SUBCHANNEL_TYPES
  };
}

export const KEY_PATH = "ACQUEON_API_URL,ACQUEON_CONSOLE_URL";

export const DEFAULT_LOGIN_VOICE_OPTIONS: Service.Conf.LoginOption[] = ["AGENT_DN", "EXTENSION"];
export const LOGIN_VOICE_OPTIONS_FOR_ORDER: Service.Conf.LoginOption[] = ["AGENT_DN", "EXTENSION", "BROWSER"];

export const NON_PROD_WEBEX_URL = {
  U2C: "https://u2c-intb.ciscospark.com/u2c/api/v1",
  HYDRA: "https://apialpha.ciscospark.com/v1/"
};

export const WEB_CALLING = {
  INDICATOR_CALLING: "calling",
  INDICATOR_CONTACT_CENTER: "contactcenter",
  CALLING_REGISTER_EVENT: "callingClient:registered",
  CALLING_ERROR: "callingClient:error",
  CALLING_UN_REGISTERED: "callingClient:unregistered",
  CALLING_INCOMING_EVENT: "callingClient:incoming_call",
  CALL_DISCONNECT_EVENT: "call:disconnect",
  DTMF_TONE_CHANGED_EVENT: "dtmfTone:changed",
  CALL_REMOTE_MEDIA_UPDATE_EVENT: "call:remote_media",
  CALL_ESTABLISHED_EVENT: "call:established",
  AX_WC_NOT_FOUND: "ax-web-call-not-found",
  AX_WC_DEVICE_REGISTERED: "web-calling-device-registered",
  AX_WC_DEVICE_REGISTERING: "web-calling-device-registering",
  AX_WC_DEVICE_DISCONNECTED: "web-calling-device-disconnected",
  AX_WC_ESTABLISHED: "ax-web-call-established",
  AX_WC_REMOTE_MEDIA_UPDATE: "ax-web-call-remote-media-update",
  AX_WC_INCOMING: "ax-web-call-incoming"
};

export const PRESENCE_MERCURY_REG = {
  AX_MERCURY_SUCCESSFUL: "presence-mercury-success",
  AX_MERCURY_FAILURE: "presence-mercury-failure",
  AX_MERCURY_RETRYING: "presence-mercury-retry"
};

export const MICROSOFT_URLS = {
  FETCH_USERS: "users",
  FETCH_USERS_PRESENCE: "communications/getPresencesByUserId",
  BATCH_REQUEST: "$batch"
};

export const RETRY_TIMEOUT = 1000;
export const REVOKE_PRESENCE_TOKEN_FAILURE = "revoke-presence-token-failure";
