import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err, generateUUID } from "../service-utils";

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };
const DEFAULT_CONTACT_HISTORY_PAGE_SIZE = 100;
const DEFAULT_CONTACT_HISTORY_PAGE = 0;
const DEFAULT_AGENT_HISTORY_PAGE_SIZE = 1000;

export function tahoeService() {
  const http = new HttpReqs(CONF.API_GATEWAY);

  return {
    fetchAgentHistory: http.req((p: { agentId: string }) => ({
      headers: { ...HTTP_HEADERS, TrackingID: `agentx_${generateUUID()}` },
      url:
        `/v1/history/agentHistory?` +
        `agentId=${encodeURIComponent(
          p.agentId
        )}&page=${DEFAULT_CONTACT_HISTORY_PAGE}&pageSize=${DEFAULT_AGENT_HISTORY_PAGE_SIZE}`,
      err,
      method: "GET",
      errId: "Service.tahoe.fetchTahoeHistory",
      res: {} as Service.Tahoe.HistoryByANI
    })),
    fetchInteractionHistory: http.req(
      (p: { ani: string; startTime: number; endTime: number; agentId: string; interactionId: string }) => ({
        url:
          `/v1/history/contactHistory?from=${p.startTime}&to=${p.endTime}&ani=${encodeURIComponent(
            p.ani
          )}&agentId=${encodeURIComponent(p.agentId)}&interactionId=${p.interactionId}` +
          `&page=${DEFAULT_CONTACT_HISTORY_PAGE}&pageSize=${DEFAULT_CONTACT_HISTORY_PAGE_SIZE}`,
        headers: { ...HTTP_HEADERS, TrackingID: `agentx_${generateUUID()}` },
        err,
        method: "GET",
        errId: "Service.tahoe.fetchInteractionHistory",
        res: {} as Service.Tahoe.HistoryByANI
      })
    )
  };
}

// prettier-ignore
type tahoeErrorIds = "Service.tahoe.fetchTahoeHistory" | "Service.tahoe.fetchInteractionHistory"
declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.tahoe": tahoeErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Tahoe {
    type HistoryData = {
      data: {
        task: {
          pageInfo: PageInfo;
          tasks: TaskMap[];
        };
      };
    };
    type HistoryDataTaskDetails = {
      data: {
        taskDetails: {
          pageInfo: PageInfo;
          tasks: TaskMap[];
        };
      };
    };
    type HistoryByANI = {
      contacts: ContactTahoe[];
    };

    type ContactTahoe = {
      cstts: string;
      cetts: string;
      talkDuration: number;
      agentName?: string;
      entrypointName?: string;
      channelType: string;
      ani: string;
      sid: string;
      transcript?: string;
      outboundTranscript?: string;
      terminationType?: string;
      subject?: string;
      customerName: string;
      dnis: string;
      callDirection: string;
      subChannelType?: string;
      wrapUpCode?: string;
      outDialType?: string;
      isCallback?: boolean;
    };
    type PageInfo = {
      hasNextPage: boolean;
      endCursor: string;
    };

    type Owner = {
      name: string;
    };

    type Customer = {
      name: string;
    };

    type EntryPointMap = {
      name: string;
    };

    type LastEntryPointMap = {
      name: string;
    };

    type TaskPropertyMap = {
      name: string;
    };

    type TaskMap = {
      owner: Owner;
      origin: string;
      id: string;
      lastEntryPoint: LastEntryPointMap;
      createdTime: number;
      endedTime: number;
      channelType: string;
      channelSubType: string;
      terminationType: string;
      connectedDuration: number;
      channelMetaData: any;
      customer: Customer;
      destination: string;
      direction: string;
      lastWrapupCodeName: string;
      isCallback: boolean;
    };
  }
}
