export const WS_DISCONNECT_ALLOWED = 8000;
export const LOST_CONNECTION_RECOVERY_TIMEOUT = 120000;
export const CONNECTIVITY_CHECK_INTERVAL = 1000;
export enum DESTINATION_TYPE {
  QUEUE = "queue",
  DIALNUMBER = "dialNumber",
  AGENT = "agent",
  EPDN = "entrypointDialNumber",
  ENTRYPOINT = "entryPoint"
}
export const EP_DN = "EP-DN";
export const DN = "DN";
