/* eslint-disable @typescript-eslint/no-explicit-any */

export type AgentxServicesConfig = {
  AQM_HOST: string;
  EMM_HOST: string;
  U2C_SERVICE_URL: string;
  NOTIF_HOST: string;
  COMPLIANCE_SERVICE_URL: string;
  CONFIG_URL: string;
  DIALER_HOST: string;
  MEDIA_SERVICE_HOST: string;
  MIX_PANEL_PROJECT_TOKEN?: string;
  API_GATEWAY: string;
  AGENTX_BACKEND_PROXY_SERVICE_HOST: string;
  WELCOME_TIMEOUT: string;
  SPLIT_KEY_TOKEN: string;
  LOGROCKET_AUTHORIZATION_KEY?: string;
  HOST_URL: string;
  MICROSOFT_CLIENT_ID: string;
  CC_DC: string;
  JABBER_HOST_URL: string;
};
export const KEEPALIVE_WORKER_INTERVAL = 4000;
export const NOTIFS_RESOLVE_DELAY = 1200;
export const CLOSE_SOCKET_TIMEOUT_DURATION = 16000;
export const PING_API_URL = "/health";
export const METHOD_NAME = location && location.host.includes("localhost") ? "GET" : "HEAD";
export const CONF: { readonly [k in keyof AgentxServicesConfig]: AgentxServicesConfig[k] } = {} as any;
export function setAgentxServicesConfig(config: AgentxServicesConfig) {
  Object.keys(config).forEach(k => ((CONF as any)[k] = config[k as keyof AgentxServicesConfig]));
}
