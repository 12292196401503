import { Err } from "@uuip/unified-ui-platform-sdk";
import { AxiosError } from "axios";
import { CONF } from "../../config";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { generateUUID } from "../service-utils";
import QMW = Service.QMW;

const HTTP_HEADERS = { "Content-Type": "application/json", "cache-control": "no-cache" };

export const createErrDetailsObjectWithStatusCode = (errObj: AxiosError<string>) => {
  const details = {
    trackingId: errObj?.response?.headers["trackingid"] || errObj?.config?.headers["TrackingID"],
    msg: errObj?.response?.data,
    statusCode: errObj.response?.status,
    retryAfter: errObj?.response?.headers["retry-after"]
  };
  return new Err.Details("Service.reqs.generic.failure", details);
};

export function qmwService() {
  const http = new HttpReqs(CONF.API_GATEWAY);

  return {
    fetchTasks: http.req((p: { startTime: number; endTime: number; pageNumber: number }) => ({
      url: `/search`,
      headers: { ...HTTP_HEADERS, TrackingID: `agentx_${generateUUID()}` },
      data: {
        query: `
        query($startTime: Long!,$endTime: Long!)
        {
    
          task(
            from: $startTime
            to: $endTime
             filter: {
              and: [
                { channelType: { equals: telephony } }
                { status : { equals: "ended" } }
                { captureRequested: { equals: true} }
              ]
            } 
           #Start with Page 0     
            pagination: { cursor: "${p.pageNumber}" }
          ) {
            tasks {
              id
              origin
              destination
              lastQueue {
                name
              }
              lastTeam {
                name
              }
              owner {
                name
              }
              connectedDuration
              lastWrapupCodeName
              createdTime
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
        `,
        variables: {
          startTime: p.startTime,
          endTime: p.endTime
        }
      },
      err: createErrDetailsObjectWithStatusCode,
      method: "POST",
      errId: "Service.qmw.fetchTasks",
      res: {} as QMW.SearchResults
    })),
    fetchCapture: http.req((p: { taskId: string }) => ({
      url: `/v1/captures/query`,
      headers: { ...HTTP_HEADERS },
      data: {
        query: {
          taskIds: [p.taskId],
          includeSegments: false
        }
      },
      err: createErrDetailsObjectWithStatusCode,
      method: "POST",
      errId: "Service.qmw.fetchCapture",
      res: {} as QMW.CaptureResults
    }))
  };
}

type qmwErrorIds = "Service.qmw.fetchTasks" | "Service.qmw.fetchCapture";
declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.qmw": qmwErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.QMW {
    type SearchResults = {
      data: {
        task: {
          pageInfo: PageInfo;
          tasks: TaskMap[];
        };
      };
    };

    type PageInfo = {
      hasNextPage: boolean;
      endCursor: string;
    };

    type TaskPropertyMap = {
      name: string;
    };

    type TaskMap = {
      id: string;
      connectedDuration: number;
      destination: string;
      createdTime: number;
      origin: string;
      owner: TaskPropertyMap;
      lastQueue: TaskPropertyMap;
      lastTeam: TaskPropertyMap;
      lastWrapupCodeName: string;
    };

    type CaptureResults = {
      meta: {
        orgId: string;
        urlExpiration: number;
      };
      data: CapturesData[];
    };

    type CapturesData = {
      taskId: string;
      recording: RecordingData[];
      transcription: TranscriptionData[];
    };

    type RecordingData = {
      id: string;
      segment: boolean;
      attributes: {
        fileName: string;
        filePath: string;
        startTime: number;
        stopTime: number;
        participants: string[];
        callType: string;
      };
    };

    type TranscriptionData = {
      Source: string;
      Provider: string;
      id: string;
      fileName: string;
      filePath: string;
      startTime: string;
      languageCode: string;
      createTime: string;
    };
  }
}
