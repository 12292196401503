import { CONF } from "../../config";
import { AqmReqs, TIMEOUT_REQ } from "../../core/aqm-reqs";
import { SERVICE, Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import { DESTINATION_TYPE, DN, EP_DN } from "./constants";
import Contact = Service.Aqm.Contact;

/*
 * Create routing Contact APIS
 * @param reqs
 * @category Routing Service
 */

const getDestAgentTypeForEporEPDN = () => {
  if (SERVICE.featureflag.isWxccPersistCallEnabled() || SERVICE.featureflag.isWxccMultiPartyConfEnabled()) {
    return DESTINATION_TYPE.ENTRYPOINT;
  }
  return DESTINATION_TYPE.EPDN;
};

const buildConsultConferenceParamData = (
  dataPassed: Contact.consultConferenceData | Contact.ConsultPayload | Contact.ConsultDN,
  interactionIdPassed: string
) => {
  const data: Contact.consultConferenceDataV2 = {
    ...("agentId" in dataPassed && { agentId: dataPassed.agentId }),
    to: dataPassed.destAgentId,
    destinationType: ""
  };

  if ("destinationType" in dataPassed) {
    if (dataPassed.destinationType === DN) {
      data.destinationType = DESTINATION_TYPE.DIALNUMBER;
    }
    if (dataPassed.destinationType === EP_DN) {
      data.destinationType = getDestAgentTypeForEporEPDN();
    }
  } else {
    data.destinationType = DESTINATION_TYPE.AGENT;
  }
  return {
    interactionId: interactionIdPassed,
    data
  };
};

const buildConsultParamData = (data: Contact.consultDataV2, pData: Contact.consultData, pUrl: string) => {
  if (pData) {
    if ("queueId" in pData && pUrl === "ctq") {
      data.to = pData.queueId;
      data.destinationType = DESTINATION_TYPE.QUEUE;
    } else if ("destinationType" in pData && "destAgentId" in pData) {
      data.to = pData.destAgentId;
      data.destinationType =
        pData.destinationType === "DN" ? DESTINATION_TYPE.DIALNUMBER : getDestAgentTypeForEporEPDN();
    } else if ("destAgentId" in pData) {
      data.to = pData.destAgentId;
      data.destinationType = DESTINATION_TYPE.AGENT;
    }
    // MultiParty Feature Consult
    if ("holdParticipants" in pData && !pData.holdParticipants) {
      // If holdParticipants is not present then api will consider as true
      // If holdParticipants is present and false then we will send to api
      data.holdParticipants = pData.holdParticipants;
    }
  }
};

export const buildBlindTransferParamData = (pInteractionId: string, pData: Contact.blindTransferPayLoad) => {
  const data: Contact.transferV2Payload = {
    to: pData?.destAgentId || "",
    destinationType: pData && "destinationType" in pData ? DESTINATION_TYPE.DIALNUMBER : DESTINATION_TYPE.AGENT
  };
  return {
    interactionId: pInteractionId,
    data
  };
};

export const buildConsultTransferParamData = (pInteractionId: string, pData: Contact.consultTransferPayLoad) => {
  const data: Contact.transferV2Payload = {
    to: pData?.destAgentId || "",
    destinationType:
      pData && pData.destinationType
        ? pData.destinationType === EP_DN
          ? getDestAgentTypeForEporEPDN()
          : DESTINATION_TYPE.DIALNUMBER
        : DESTINATION_TYPE.AGENT
  };
  return {
    interactionId: pInteractionId,
    data
  };
};

export const buildVTeamsTransferParamData = (pInteractionId: string, pData: Contact.vteamTransferPayLoad) => {
  const data: Contact.transferV2Payload = {
    to: pData?.vteamId || "",
    destinationType: pData.vteamType === "inboundqueue" ? DESTINATION_TYPE.QUEUE : DESTINATION_TYPE.EPDN
  };
  return {
    interactionId: pInteractionId,
    data
  };
};

export function createRoutingContactAPIS(routing: AqmReqs, featureFlags: Service.Aqm.Global.contactRouteFeatureFlag) {
  const consultV2 = routing.req((p: { interactionId: string; data: Contact.consultDataV2 }) => ({
    url: `/v1/tasks/${p.interactionId}/consult`,
    data: p.data,
    timeout: p.data && p.data.destinationType === DESTINATION_TYPE.QUEUE ? "disabled" : TIMEOUT_REQ,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultCreated", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: {
          type: p.data && p.data.destinationType === DESTINATION_TYPE.QUEUE ? "AgentCtqFailed" : "AgentConsultFailed"
        }
      },
      errId: "Service.aqm.contact.consult"
    },
    notifCancel: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentCtqCancelled", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    }
  }));
  const exitConference = routing.req((p: { interactionId: string }) => ({
    url: `/v1/tasks/${p.interactionId}/conference/exit `,
    host: CONF.API_GATEWAY,
    data: {},
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ParticipantLeftConference", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ParticipantLeftConferenceFailed" } //to be finalized
      },
      errId: "Service.aqm.contact.consultConference"
    }
  }));
  const consultEndV2 = routing.req(
    (p: { interactionId: string; isConsult: boolean; isSecondaryEpDnAgent?: boolean; queueId?: string }) => {
      // Setting false value for optional attribute
      const { interactionId, isConsult, isSecondaryEpDnAgent = false, queueId } = p;
      return {
        url: `/v1/tasks/${interactionId}/consult/end`,
        host: CONF.API_GATEWAY,
        data: queueId
          ? {
              queueId
            }
          : {},
        err,
        notifSuccess: {
          bind: {
            type: "RoutingMessage",
            data: {
              type: queueId
                ? "AgentCtqCancelled"
                : isSecondaryEpDnAgent
                ? "ContactEnded"
                : isConsult
                ? "AgentConsultEnded"
                : "AgentConsultConferenceEnded",
              interactionId: p.interactionId
            }
          },
          msg: {} as Contact.AgentContact
        },
        notifFail: {
          bind: {
            type: "RoutingMessage",
            data: { type: queueId ? "AgentCtqCancelFailed" : "AgentConsultEndFailed" }
          },
          errId: queueId ? "Service.aqm.contact.cancelCtq" : "Service.aqm.contact.consultEnd"
        }
      };
    }
  );
  const buddyAgentsV2 = routing.req((p: { data: Contact.BuddyAgentsV2 }) => ({
    url: `/v1/agents/buddyList`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "BuddyAgents",
        data: { type: "BuddyAgents" }
      },
      msg: {} as Contact.BuddyAgentsSuccess
    },
    notifFail: {
      bind: {
        type: "BuddyAgents",
        data: { type: "BuddyAgentsRetrieveFailed" }
      },
      errId: "Service.aqm.contact.BuddyAgentsRetrieveFailed"
    }
  }));

  const blindTransferV2 = routing.req((p: { interactionId: string; data: Contact.transferV2Payload }) => ({
    url: `/v1/tasks/${p.interactionId}/transfer`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentBlindTransferred", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentBlindTransferFailedEvent" }
      },
      errId: "Service.aqm.contact.AgentBlindTransferFailedEvent"
    }
  }));
  const consultConferenceV2 = routing.req((p: { interactionId: string; data: Contact.consultConferenceDataV2 }) => ({
    url: `/v1/tasks/${p.interactionId}/consult/conference`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultConferenced", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultConferenceFailed" }
      },
      errId: "Service.aqm.contact.consultConference"
    }
  }));

  const vteamTransferV2 = routing.req((p: { interactionId: string; data: Contact.transferV2Payload }) => ({
    url: `/v1/tasks/${p.interactionId}/transfer`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentVteamTransferred", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentVteamTransferFailed" }
      },
      errId: "Service.aqm.contact.AgentVteamTransferFailed"
    }
  }));
  const consultTransferV2 = routing.req((p: { interactionId: string; data: Contact.transferV2Payload }) => ({
    url: `/v1/tasks/${p.interactionId}/consult/transfer`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: {
          type: "AgentConsultTransferred",
          interactionId: p.interactionId
        }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentConsultTransferFailed" }
      },
      errId: "Service.aqm.contact.AgentConsultTransferFailed"
    }
  }));
  // Modify this logic using shared-actions so there is no need to pass parameters to endV2
  const endV2 = routing.req((p: { interactionId: string; isEndingFromNonPrimary?: boolean }) => ({
    url: `/v1/tasks/${p.interactionId}/end`,
    host: CONF.API_GATEWAY,
    data: {},
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: {
          type: p.isEndingFromNonPrimary ? "ParticipantLeftConference" : "AgentWrapup",
          interactionId: p.interactionId
        }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: p.isEndingFromNonPrimary ? "AgentConsultConferenceEndFailed" : "AgentContactEndFailed" }
      },
      errId: "Service.aqm.contact.end"
    }
  }));
  const cancelTaskV2 = routing.req((p: { interactionId: string }) => ({
    url: `/v1/tasks/${p.interactionId}/end`,
    host: CONF.API_GATEWAY,
    data: {},
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactEnded", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactEndFailed" }
      },
      errId: "Service.aqm.contact.end"
    }
  }));
  const wrapupV2 = routing.req((p: { interactionId: string; data: Contact.WrapupV2PayLoad }) => ({
    url: `/v1/tasks/${p.interactionId}/wrapup`,
    host: CONF.API_GATEWAY,
    data: p.data,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentWrappedUp", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentWrapupFailed" }
      },
      errId: "Service.aqm.contact.wrapup"
    }
  }));
  const reject = routing.req((p: { interactionId: string; data: Contact.declinePayload; isConsult: boolean }) => ({
    url: `/v1/tasks/${p.interactionId}/reject`,
    host: CONF.API_GATEWAY,
    data: p.data,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: p.isConsult ? "AgentConsultFailed" : "AgentOfferContactRona", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    }
  }));
  const acceptV2 = routing.req((p: { interactionId: string }) => ({
    url: `/v1/tasks/${p.interactionId}/accept`,
    data: {},
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactAssigned", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentContactAssignFailed", interactionId: p.interactionId }
      },
      errId: "Service.aqm.contact.accept"
    }
  }));
  const pauseRecordingV2 = routing.req((p: { interactionId: string }) => ({
    url: `/v1/tasks/${p.interactionId}/record/pause`,
    data: {},
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingPaused", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingPauseFailed", interactionId: p.interactionId }
      },
      errId: "Service.aqm.contact.pauseRecording"
    }
  }));
  const resumeRecordingV2 = routing.req((p: { interactionId: string; data: { autoResumed: boolean } }) => ({
    url: `/v1/tasks/${p.interactionId}/record/resume`,
    data: p.data,
    host: CONF.API_GATEWAY,
    err,
    notifSuccess: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingResumed", interactionId: p.interactionId }
      },
      msg: {} as Contact.AgentContact
    },
    notifFail: {
      bind: {
        type: "RoutingMessage",
        data: { type: "ContactRecordingResumeFailed", interactionId: p.interactionId }
      },
      errId: "Service.aqm.contact.resumeRecording"
    }
  }));

  return {
    ...(featureFlags.wxccConsultToEntryPointDn
      ? {
          consult: (p: { interactionId: string; data: Contact.consultData; url: string }) => {
            const data: Contact.consultDataV2 = {
              to: "",
              destinationType: ""
            };
            buildConsultParamData(data, p.data, p.url);
            const param: { interactionId: string; data: Contact.consultDataV2 } = {
              interactionId: p.interactionId,
              data
            };
            return consultV2(param);
          },
          consultV2,
          /*
           * Consult Accept contact
           */
          consultAccept: routing.req((p: { interactionId: string }) => ({
            url: `/v1/tasks/${p.interactionId}/consult/accept`,
            data: {},
            err,
            host: CONF.API_GATEWAY,
            notifSuccess: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentConsulting", interactionId: p.interactionId }
              },
              msg: {} as Contact.AgentContact
            },
            notifFail: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentContactAssignFailed" }
              },
              errId: "Service.aqm.contact.consultAccept"
            }
          })),
          /*
           * Consult End contact
           */
          consultEnd: (p: { interactionId: string; isConsult: boolean; isSecondaryEpDnAgent?: boolean }) => {
            // Setting false value for optional attribute
            const { interactionId, isConsult, isSecondaryEpDnAgent = false } = p;
            return consultEndV2({ interactionId, isConsult, isSecondaryEpDnAgent });
          },

          /*
           * Cancel Ctq request
           */
          cancelCtq: (p: { interactionId: string; data: Contact.cancelCtq }) => {
            return consultEndV2({
              interactionId: p.interactionId,
              isConsult: false,
              queueId: p.data && p.data.queueId ? p.data.queueId : ""
            });
          },
          consultEndV2,
          exitConference
        }
      : {}),
    ...(featureFlags.desktopEOLHoldUnHold
      ? {
          /*
           * Hold contact
           */
          hold: routing.req((p: { interactionId: string; data: { mediaResourceId: string } }) => ({
            url: `/v1/tasks/${p.interactionId}/hold`,
            data: p.data,
            err,
            host: CONF.API_GATEWAY,
            notifSuccess: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentContactHeld", interactionId: p.interactionId }
              },
              msg: {} as Contact.AgentContact
            },
            notifFail: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentContactHoldFailed" }
              },
              errId: "Service.aqm.contact.hold"
            }
          })),

          /*
           * Unhold contact
           */
          unHold: routing.req((p: { interactionId: string; data: { mediaResourceId: string } }) => ({
            url: `/v1/tasks/${p.interactionId}/unhold`,
            data: p.data,
            err,
            host: CONF.API_GATEWAY,
            notifSuccess: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentContactUnheld", interactionId: p.interactionId }
              },
              msg: {} as Contact.AgentContact
            },
            notifFail: {
              bind: {
                type: "RoutingMessage",
                data: { type: "AgentContactUnHoldFailed" }
              },
              errId: "Service.aqm.contact.unHold"
            }
          }))
        }
      : {}),
    ...(featureFlags.desktopEOLConsultConference
      ? {
          /*
           * consult conference
           */
          consultConference: (p: {
            interactionId: string;
            data: Contact.consultConferenceData | Contact.ConsultPayload | Contact.ConsultDN;
          }) => {
            const paramsDataForConferenceV2 = buildConsultConferenceParamData(p.data, p.interactionId);
            return consultConferenceV2(paramsDataForConferenceV2);
          }
        }
      : {}),
    ...(featureFlags.isWxccQrmApiAgentListEnabled
      ? {
          /*
           * Get list of Agents
           */
          buddyAgents: (p: { data: Contact.BuddyAgents }) => {
            const data: Contact.BuddyAgentsV2 = {
              agentProfileId: p.data.agentProfileId,
              mediaType: p.data.channelName,
              ...(p.data.state && { state: p.data.state })
            };
            const par: { data: Contact.BuddyAgentsV2 } = {
              data
            };
            return buddyAgentsV2(par);
          }
        }
      : {}),
    ...(featureFlags.desktopEOLTransfer
      ? {
          /*
           * Blind Transfer
           */
          blindTransfer: (p: { interactionId: string; data: Contact.blindTransferPayLoad }) => {
            return blindTransferV2(buildBlindTransferParamData(p.interactionId, p.data));
          },
          /*
           * vTeam Transfer
           */
          vteamTransfer: (p: { interactionId: string; data: Contact.vteamTransferPayLoad }) => {
            return vteamTransferV2(buildVTeamsTransferParamData(p.interactionId, p.data));
          }
        }
      : {}),
    ...(featureFlags.desktopEOLConsultTransfer
      ? {
          /*
           * Consult Transfer contact
           */
          consultTransfer: (p: { interactionId: string; data: Contact.consultTransferPayLoad }) => {
            return consultTransferV2(buildConsultTransferParamData(p.interactionId, p.data));
          }
        }
      : {}),
    ...(featureFlags.desktopEOLEndWrapup
      ? {
          /*
           * end Call
           */
          end: (p: { interactionId: string; isEndingFromNonPrimary?: boolean }) => {
            return endV2({
              interactionId: p.interactionId,
              isEndingFromNonPrimary: p.isEndingFromNonPrimary
            });
          },
          /*
           * cancel Task
           */
          cancelTask: (p: { interactionId: string }) => {
            return cancelTaskV2({
              interactionId: p.interactionId
            });
          },
          /*
           * wrapup contact
           */
          wrapup: (p: { interactionId: string; data: Contact.WrapupPayLoad }) => {
            const par = {
              interactionId: p.interactionId,
              data: {
                auxCodeId: p.data.auxCodeId,
                wrapUpReason: p.data.wrapUpReason
              }
            };
            return wrapupV2(par);
          },
          /*
           * Decline contact
           */
          decline: (p: { interactionId: string; data: Contact.declinePayload; isConsult: boolean }) => {
            return reject(p);
          }
        }
      : {}),
    ...(featureFlags.desktopEOLAccept
      ? {
          /*
           * Accept incoming contact
           */
          accept: (p: { interactionId: string }) => {
            return acceptV2(p);
          }
        }
      : {}),
    ...(featureFlags.desktopAqmEolPauseResume
      ? {
          /*
           * Pause recording
           */
          pauseRecording: (p: { interactionId: string }) => {
            return pauseRecordingV2(p);
          },

          /*
           * Resume Recording
           */
          resumeRecording: (p: { interactionId: string; data: { autoResumed: boolean } }) => {
            return resumeRecordingV2(p);
          }
        }
      : {})
  };
}
