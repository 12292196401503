const CACHE_KEY_SEPARATOR = "-";
const REFRESH_TOKEN = "RefreshToken";

function generateAccountId(credentialEntity: CredentialEntity): string {
  const accountId: Array<string> = [credentialEntity.homeAccountId, credentialEntity.environment];
  return accountId.join(CACHE_KEY_SEPARATOR).toLowerCase();
}

/**
 * Generate Credential Id key component as per the schema: <credential_type>-<client_id>-<realm>
 */
function generateCredentialId(credentialEntity: CredentialEntity): string {
  const clientOrFamilyId =
    credentialEntity.credentialType === REFRESH_TOKEN
      ? credentialEntity.familyId || credentialEntity.clientId
      : credentialEntity.clientId;
  const credentialId: Array<string> = [credentialEntity.credentialType, clientOrFamilyId, ""];

  return credentialId.join(CACHE_KEY_SEPARATOR).toLowerCase();
}

function generateCredentialKey(credentialEntity: CredentialEntity): string {
  const credentialKey = [generateAccountId(credentialEntity), generateCredentialId(credentialEntity), "", "", ""];

  return credentialKey.join(CACHE_KEY_SEPARATOR).toLowerCase();
}

/**
 * Create RefreshTokenEntity
 * @param homeAccountId
 * @param authenticationResult
 * @param clientId
 * @param authority
 */
export function createRefreshTokenKey(
  homeAccountId: string,
  environment: string,
  clientId: string,
  familyId?: string
): string {
  const rtEntity: CredentialEntity = {
    credentialType: REFRESH_TOKEN,
    homeAccountId,
    environment,
    clientId
  };

  if (familyId) {
    rtEntity.familyId = familyId;
  }

  return generateCredentialKey(rtEntity);
}

/* returns time left to token expire in milliseconds. */
export function getRevokeInterval(expiresOn: Date | string) {
  return new Date(expiresOn).valueOf() - Math.floor(Date.now());
}

export const loginPromptDimension = () => {
  const DEFAULT_POPUP_WIDTH = 483;
  const DEFAULT_POPUP_HEIGHT = 600;
  /**
   * adding winLeft and winTop to account for dual monitor
   * using screenLeft and screenTop for IE8 and earlier
   */
  const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const winTop = window.screenTop ? window.screenTop : window.screenY;
  /**
   * window.innerWidth displays browser window"s height and width excluding toolbars
   * using document.documentElement.clientWidth for IE8 and earlier
   */
  const winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const width = DEFAULT_POPUP_WIDTH;
  const height = DEFAULT_POPUP_HEIGHT;

  const top = Math.max(0, winHeight / 2 - DEFAULT_POPUP_HEIGHT / 2 + winTop);
  const left = Math.max(0, winWidth / 2 - DEFAULT_POPUP_WIDTH / 2 + winLeft);

  return {
    width,
    height,
    top,
    left
  };
};

const base64UrlDecode = (base64Url: string) => {
  // Add padding if necessary and replace URL-safe characters
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  // Base64 decode and convert to string
  return window.atob(base64);
};

export const decodeJwt = (jwt: string) => {
  // Split the JWT into its three parts
  try {
    const [header, payload] = jwt.split(".");

    // Base64 decode each part
    const decodedHeader = base64UrlDecode(header);
    const decodedPayload = base64UrlDecode(payload);

    // Parse the JSON in each part
    const headerObj = JSON.parse(decodedHeader);
    const payloadObj = JSON.parse(decodedPayload);

    // Return the decoded header and payload
    return { header: headerObj, payload: payloadObj };
  } catch (error) {
    throw new Error("Error on decrypting Json Web Token");
  }
};

export const MSFT_TOKEN = "msft-token";
export const MSFT_USER_INFO = "msft-user-info";

type CredentialEntity = {
  /** Identifier for the user in their home tenant*/
  homeAccountId: string;
  /** Entity that issued the token, represented as a full host */
  environment: string;
  /** Type of credential */
  credentialType: string;
  /** Client ID of the application */
  clientId: string;
  /** Family ID identifier, usually only used for refresh tokens */
  familyId?: string;
};
