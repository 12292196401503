import { BatchResponseContent, Client } from "@microsoft/microsoft-graph-client";
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { Signal } from "@uuip/unified-ui-platform-sdk";
import { CONF } from "../../../config";
import { HttpReqs } from "../../../core/http-reqs";
import { logger } from "../../../core/sdk";
import { SERVICE, Service } from "../../../index";
import { MICROSOFT_URLS, MicrosoftServiceEvents } from "../../constant";
import {
  RETRY_INTERVAL,
  createErrDetailsObject as err,
  handleExternalServiceErrorDetails,
  sleep
} from "../../service-utils";
import { MicrosoftAuthCodeHandler } from "./microsoft-oauth-handler";
import { MSFT_TOKEN, MSFT_USER_INFO, decodeJwt } from "./utils";

export class MicrosoftService extends MicrosoftAuthCodeHandler {
  private readonly accessToken: string = "";
  public activeUserId: string | null = null; // Active Microsoft Account User ID.
  private readonly scope = "https://graph.microsoft.com/.default offline_access openid profile";
  private clientId = "";
  private tenantId = "";
  private msftPresenceServiceUrl = "";
  private readonly jabberService = new HttpReqs(CONF.JABBER_HOST_URL, true);
  private readonly http = new HttpReqs(CONF.U2C_SERVICE_URL, true);
  public graphClient: Client | null = null;
  public onMessage: Service.Microsoft.EventInstance;
  private readonly onMessageSend: Signal.Send<Service.Microsoft.EventMessage>;
  public tokenResponse: Service.Microsoft.Token | undefined = undefined;

  constructor(accessToken: string) {
    super();
    this.accessToken = accessToken;

    const { send, signal } = Signal.create.withData<Service.Microsoft.EventMessage>();
    this.onMessage = signal;
    this.onMessageSend = send;
  }

  public async initialize(clientId: string, tenantId: string): Promise<boolean> {
    this.clientId = clientId;
    this.tenantId = tenantId;
    this.initializeOAuthService(this.clientId, this.tenantId);
    logger.info("[MicrosoftService]: Initializing Microsoft Auth Service SUCCESS");
    return this.isInitialized;
  }

  public async loginPopup(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      if (this.getPopUpActiveStatus()) {
        // MSLogin pop up window is already opened.
        throw new Error("[MicrosoftService] : Login Popup Interaction in progress");
      } else {
        const redirectUri = this.getLoginRedirectURL();
        const codeValue: string = await this.invokeLoginPopUp({ redirectUri, scope: this.scope });
        // Fetch accessToken using Jabber Service API.
        const allowRetry = true;
        const payload = { tenantId: this.tenantId, code: codeValue };
        const response: Service.Microsoft.ExchangeTokenResponse = await this.fetchExchangeToken(payload, allowRetry);
        this.setToken(response);
        return response;
      }
    } catch (error) {
      logger.error("[MicrosoftService]: Error on loginPopup ", (error as Error)?.message);
      throw error;
    }
  }

  public async logoutPopup() {
    try {
      const redirectUri = this.getLogoutRedirectURL();
      await this.invokeLogout({ redirectUri });
    } catch (error) {
      logger.error("[MicrosoftService]: Error on logoutPopup ", error);
      throw error;
    }
  }

  private async ssoSilent(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      const redirectUri = this.getLoginRedirectURL();
      const codeValue = await this.invokeSilentSSOLogin({ redirectUri, scope: this.scope });
      // Fetch accessToken using Jabber Service API.
      const allowRetry = true;
      const payload = { tenantId: this.tenantId, code: codeValue };
      const response: Service.Microsoft.ExchangeTokenResponse = await this.fetchExchangeToken(payload, allowRetry);
      this.setToken(response);
      return response;
    } catch (error) {
      logger.error("[MicrosoftService]: Error on ssoSilent ", (error as Error)?.message);
      throw error;
    }
  }

  public isTokenValid(accessTokenExpiryTime?: string): boolean {
    const expiryTime = accessTokenExpiryTime ?? this.getToken()?.accessTokenExpiryTime ?? false;
    if (expiryTime) {
      return new Date().getTime() < Number(expiryTime);
    }
    return false;
  }

  private getTokenFromCache(): null | Service.Microsoft.Token {
    const tokenResponse = sessionStorage.getItem(MSFT_TOKEN);
    if (tokenResponse && tokenResponse !== "") {
      // Parse the Token response from the session storage.
      try {
        return JSON.parse(tokenResponse);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  private async renewAccessTokenSilently(refreshToken: string) {
    try {
      const payload = { tenantId: this.tenantId, refreshToken };
      const allowRetry = true;
      const response: Service.Microsoft.ExchangeTokenResponse = await this.renewAccessToken(payload, allowRetry);
      this.setToken(response); // Save the new Token set in the Microsoft Authentication Instance.
      return response;
    } catch (error) {
      throw new Error("Renew Access Token API has experienced an operational failure, Acquire Token Silent is Failed");
    }
  }

  private async acquireTokenSilent(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    const tokenCache = this.getTokenFromCache();
    if (!tokenCache) {
      throw new Error("Token not found in Cache");
    }

    if (this.isTokenValid(tokenCache.accessTokenExpiryTime)) {
      // In cache, Valid token is available.
      // Save the valid token set in the Microsoft Authentication Instance.
      this.setToken(tokenCache);
      return tokenCache;
    } else {
      // Token values are present in cache, But access token is expired, Using the renew token from cache to get new access token.
      // Refresh tokens have a longer lifetime than access tokens. The default lifetime for the refresh tokens is 24 hours for single page apps and 90 days for all other scenarios.
      // [REFER: https://learn.microsoft.com/en-us/entra/identity-platform/refresh-tokens]

      // Invoke Jabber Service API for get new access token.

      return this.renewAccessTokenSilently(tokenCache.refresh_token);
    }
  }

  /**
   *
   * @param value - JWT Token
   * @returns null
   */
  private setActiveUserId(value: string) {
    try {
      const profileInfo = decodeJwt(value);
      if (profileInfo && profileInfo.payload) {
        const { oid } = profileInfo.payload;
        // The Object ID, often referred to as "oid," is a unique identifier assigned to each user in Azure Active Directory (Azure AD).
        this.activeUserId = oid ?? null;
        this.setUserInfo(profileInfo.payload ?? {});
      }
    } catch (error) {
      logger.error("[MicrosoftService]: Error on updating the active user id from microsoft profile: id_token");
    }
  }

  /**
   * Set the microsoft Signed-In user informations to the session storage.
   */
  private setUserInfo(userInfo: any) {
    logger.info("[MicrosoftService]: Microsoft authenticated user identity provider information:", userInfo);
    logger.info("[MicrosoftService]: Microsoft User ID:", userInfo?.oid ?? "");
    sessionStorage.setItem(MSFT_USER_INFO, JSON.stringify(userInfo));
  }

  /**
   * Remove the microsoft Signed-In user informations to the session storage.
   */
  private removeUserInfo() {
    sessionStorage.removeItem(MSFT_USER_INFO);
    logger.info(`[MicrosoftService]: ${MSFT_USER_INFO} is successfully removed from session storage`);
  }

  /**
   * Set the microsoft token to the session storage and OAuth Class.
   * While setting the token, expiry time will be calculated and stored in the session storage.
   */
  public async setToken(tokenResponse: Service.Microsoft.SetToken) {
    let accessTokenExpiryTime = "";
    if (tokenResponse.accessTokenExpiryTime) {
      accessTokenExpiryTime = tokenResponse.accessTokenExpiryTime;
    } else {
      const tokenExpiredAfterInMilliSeconds = parseInt(tokenResponse.expires_in, 10) * 1000; // 60 mins
      const tokenExpirationSkewInMilliSeconds = 10 * 60 * 1000; // 10 mins

      // Cisco Jabber Service will not return the token expiry time in seconds.
      // According the Microsoft Documentation, the token expiry time will be in 60 minutes.
      accessTokenExpiryTime = (
        new Date().getTime() +
        tokenExpiredAfterInMilliSeconds -
        tokenExpirationSkewInMilliSeconds
      ).toString();
    }

    logger.info("[MicrosoftService]: Updating the Microsoft Token to sessionStorage.");
    this.tokenResponse = {
      ...tokenResponse,
      accessTokenExpiryTime
    };
    sessionStorage.setItem(MSFT_TOKEN, JSON.stringify(this.tokenResponse));

    // Update the LoggedIn User Id.
    if (this.tokenResponse.id_token) {
      // The id_token is a JSON Web Token (JWT) that is included in the response when a user authenticates with an identity provider, such as Azure Active Directory (Azure AD).
      // This token contains information about the authenticated user and is typically used for identity-related tasks in authentication and authorization processes.
      this.setActiveUserId(this.tokenResponse.id_token);
    } else {
      logger.error(
        "[MicrosoftService]: Error retrieving the `id_token` from Jabber Service containing microsoft authenticated user information like user id and email address"
      );
    }
  }

  /**
   * Return the token stored in the OAuth Instance.
   */
  public getToken(): undefined | Service.Microsoft.Token {
    return this.tokenResponse;
  }

  public async executeApiToFetchExchangeToken(tenantId: string, code: string) {
    const postExchangeTokenApi = this.jabberService.req((p: { tenantId: string; code: string }) => {
      const requestData = new URLSearchParams();
      requestData.append("tenant", p?.tenantId);
      requestData.append("code", p?.code);
      requestData.append("userObjectId", "");
      requestData.append("client_id", CONF.MICROSOFT_CLIENT_ID);
      requestData.append("redirect_uri", CONF.HOST_URL);
      requestData.append("scope", this.scope);

      return {
        url: `/jabber-integration/api/v1/msteams/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Origin: CONF.HOST_URL
        },
        data: requestData.toString(),
        method: "POST",
        err: handleExternalServiceErrorDetails,
        res: {}
      };
    });
    return postExchangeTokenApi({
      tenantId,
      code
    }) as Promise<Service.Microsoft.ExchangeTokenResponse>;
  }

  public async fetchExchangeToken(
    payload: { tenantId: string; code: string },
    allowRetry: boolean
  ): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      return await this.executeApiToFetchExchangeToken(payload.tenantId, payload.code);
    } catch (e) {
      // Retrying on failure
      const error = e as workerTimers.Err.Details<any>;
      if (allowRetry && error?.details && error.details?.status === 429) {
        // Retry on too many requests
        await sleep(RETRY_INTERVAL);
        logger.info(
          `[MicrosoftService]: Rate Limit exceeded. Retrying endpoint: "api/v1/msteams/token" after ${RETRY_INTERVAL} seconds`
        );
        allowRetry = false; // Sending allowRetry as FALSE as we want to retry only once.
        return this.fetchExchangeToken(payload, allowRetry);
      } else {
        logger.error("[MicrosoftService]: Error on exhangeToken ", error?.details?.msg?.message);
        throw new Error(
          `${error?.details?.msg?.message ?? "Error on retrieving token from exhangeToken Jabber Service"}`
        );
      }
    }
  }

  public async initiateMicrosoftLogin(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      const response = await this.acquireTokenSilent();
      logger.info("[MicrosoftService]: acquireTokenSilent success");
      return response;
    } catch (silentError) {
      logger.info("[MicrosoftService]: acquireTokenSilent is Failed.", (silentError as Error)?.message);
      return this.handleSilentError();
    }
  }

  private async handleSilentError(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      const response = await this.ssoSilent();
      logger.info("[MicrosoftService]: ssoSilent success");
      return response;
    } catch (ssoError) {
      logger.info("[MicrosoftService]: ssoSilent Failed.", (ssoError as Error)?.message);
      return this.handleSsoError();
    }
  }

  private async handleSsoError(): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      const response = await this.loginPopup();
      logger.info("[MicrosoftService]: loginPopup success");
      return response;
    } catch (popupError) {
      logger.error("[MicrosoftService]: loginPopup Failed:", (popupError as Error)?.message);
      throw popupError;
    }
  }

  private getLoginRedirectURL(): string {
    return CONF.HOST_URL.replace(/\/$/, ""); // Remove Trailing slash from the host url;
  }

  public getLogoutRedirectURL(): string {
    const APPLICATION_HOST = CONF.HOST_URL.replace(/\/$/, ""); // Remove Trailing slash from the host url
    return `${APPLICATION_HOST}`;
  }
  public async executeApiToFetchRenewToken(
    tenantId: string,
    refreshToken: string
  ): Promise<Service.Microsoft.ExchangeTokenResponse> {
    const postRenewTokenApi = this.jabberService.req((p: { tenantId: string; refreshToken: string }) => {
      const requestData = new URLSearchParams();
      requestData.append("tenant", p.tenantId);
      requestData.append("refresh_token", p.refreshToken);
      requestData.append("userObjectId", "");
      requestData.append("client_id", CONF.MICROSOFT_CLIENT_ID);
      requestData.append("redirect_uri", CONF.HOST_URL);
      requestData.append("scope", this.scope);

      return {
        url: `/jabber-integration/api/v1/msteams/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Origin: CONF.HOST_URL
        },
        data: requestData.toString(),
        method: "POST",
        err: handleExternalServiceErrorDetails,
        res: {}
      };
    });
    return postRenewTokenApi({
      tenantId,
      refreshToken
    }) as Promise<Service.Microsoft.ExchangeTokenResponse>;
  }

  /**
   *
   * @param allowRetry - if true, retry the request after 200 ms
   * @returns
   */
  public async renewAccessToken(
    payload: { tenantId: string; refreshToken: string },
    allowRetry: boolean
  ): Promise<Service.Microsoft.ExchangeTokenResponse> {
    try {
      // Fetch renewToken using Jabber Service API.
      return await this.executeApiToFetchRenewToken(payload.tenantId, payload.refreshToken);
    } catch (e) {
      const error = e as workerTimers.Err.Details<any>;
      if (allowRetry && error?.details && error.details?.status === 429) {
        // Too many requests
        await sleep(RETRY_INTERVAL);
        logger.info(
          `[MicrosoftService]: Rate Limit exceeded. Retrying endpoint: "api/v1/msteams/token" after ${RETRY_INTERVAL} seconds`
        );
        const retry = false; // Sending allowRetry as FALSE as we want to retry only once.
        return this.renewAccessToken(payload, retry);
      } else {
        logger.error("[MicrosoftService]: Error on renewAccessToken ", error?.details?.msg?.message);
        throw new Error(
          `${error?.details?.msg?.message ?? "Error on retrieving token from renewToken Jabber Service"}`
        );
      }
    }
  }

  private readonly authProviderCallback = async (done: (error: any, accessToken: string | null) => void) => {
    const tokenForGraphClient = this.getToken()?.access_token;

    //If token is valid and is present in the sessionStorage
    if (this.isTokenValid() && tokenForGraphClient) {
      done(null, tokenForGraphClient);
    } else {
      //Token not valid or not present in sessionStorage, we will call the renewAccessToken function.
      logger.info("[GraphClientService]: Token is expired or not valid, Invoking renew token service");
      try {
        const currentRefreshToken = this.getToken()?.refresh_token;

        if (currentRefreshToken) {
          const response = await this.renewAccessTokenSilently(currentRefreshToken);
          done(null, response.access_token);
        } else {
          throw new Error("Refresh Token is not found in session Storage");
        }
      } catch (e) {
        //If renewAccessToken fails then do SSO silent login
        logger.info(
          `[GraphClientService]: Renewing access token is failed ${(e as Error)?.message}. Invoking SSO silent`
        );
        try {
          const response = await this.ssoSilent();
          done(null, response.access_token);
        } catch (e) {
          logger.error(
            `[GraphClientService]: Encountered an error during token retrieval. Graph client authentication is failed`
          );
          this.onMessageSend({ eventType: MicrosoftServiceEvents.RENEW_ACCESS_TOKEN_FAILURE });
          done("Error to retrieve access token", null);
        }
      }
    }
  };

  public async initializeGraphClient() {
    if (this.isInitialized) {
      const graphClient = Client.init({
        debugLogging: true,
        authProvider: this.authProviderCallback
      });
      this.graphClient = graphClient;
    }
  }

  public async graphRequestHandler(payload: {
    endPoint: string;
    isBetaVersion?: boolean;
    method: "GET" | "POST";
    count?: number;
    skipToken?: string;
    requestData?: any;
    customHeaders?: { [key: string]: string };
    selectParameters?: Array<string>;
    filter?: string;
    orderby?: string;
  }) {
    // Default headers
    let headers = {
      "content-type": "application/json",
      ConsistencyLevel: "eventual"
    };
    if (payload.customHeaders && Object.keys(payload.customHeaders).length > 0) {
      // payload.customHeaders
      headers = { ...headers, ...payload.customHeaders };
    }

    // Add Endpoint URL.
    const requestClient = this.graphClient?.api(payload.endPoint);

    // Add Headers
    requestClient?.headers(headers);

    // Add field for selecting
    if (payload.selectParameters && payload.selectParameters.length > 0) {
      requestClient?.select(payload.selectParameters);
    }

    if (payload.filter) {
      requestClient?.filter(payload.filter);
    }

    if (payload.orderby) {
      requestClient?.orderby(payload.orderby);
    }

    if (payload.isBetaVersion) {
      requestClient?.version("beta");
    }

    if (payload.count) {
      requestClient?.top(payload.count); // helpful for fetch the certain number of records.
      requestClient?.count(true); // helpful for identify the no.of records present in the api.
      if (payload.skipToken && payload.skipToken !== "") {
        requestClient?.skipToken(payload.skipToken);
      }
    }

    if (payload.method === "GET") {
      return requestClient?.get();
    }
    // For POST Method
    return requestClient?.post(payload.requestData);
  }

  public async fetchUsers(payload: { count?: number; searchQuery?: string; skipToken?: string }) {
    const selectParameters = ["id", "userPrincipalName", "businessPhones", "department", "displayName", "jobTitle"];
    const graphRequestPayload: {
      endPoint: string;
      method: "GET";
      selectParameters: Array<string>;
      skipToken?: string;
      count?: number;
      filter?: string;
      orderby: string;
    } = {
      endPoint: MICROSOFT_URLS.FETCH_USERS,
      method: "GET",
      selectParameters,
      orderby: "displayName ASC"
    };

    if (payload.skipToken) {
      graphRequestPayload.skipToken = payload.skipToken;
    }

    if (payload.count) {
      graphRequestPayload.count = payload.count;
    }

    let searchQuery;
    if (payload.searchQuery) {
      const value = payload.searchQuery;
      // Encode URI is required for search the phone number withg special character, like +1-(922)123456
      const phoneNumber = encodeURIComponent(payload.searchQuery);
      searchQuery = `startswith(department,'${value}')`;
      searchQuery += ` OR startswith(displayName,'${value}')`;
      searchQuery += ` OR startswith(jobTitle,'${value}')`;
      searchQuery += ` OR businessPhones/any(p:startsWith(p, '${phoneNumber}'))`;
    }

    // Default Filter Query
    let filterQuery = "accountEnabled eq true"; // To prevent the not active users/deleted users

    if (this.activeUserId) {
      // Pre-Defined filter value for remove the signed-in user.
      filterQuery += ` AND NOT(id eq '${this.activeUserId}')`;
    }

    if (searchQuery) {
      filterQuery += ` AND (${searchQuery})`;
    }

    graphRequestPayload.filter = filterQuery;
    return this.graphRequestHandler(graphRequestPayload);
  }

  public async batchRequest(batchRequests: any) {
    // For Batch Request
    // https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/docs/content/Batching.md
    // https://learn.microsoft.com/en-us/graph/sdks/batch-requests?tabs=typescript
    const response = await this.graphRequestHandler({
      endPoint: MICROSOFT_URLS.BATCH_REQUEST,
      requestData: { requests: batchRequests },
      method: "POST"
    });
    return new BatchResponseContent(response);
  }

  public async fetchUserPresence(payload: any) {
    return this.graphRequestHandler({
      endPoint: MICROSOFT_URLS.FETCH_USERS_PRESENCE,
      requestData: payload.requestData,
      method: "POST"
    });
  }

  public async msLogout(): Promise<boolean | void> {
    try {
      this.removeToken(); // Removes the token stores in session storage
      this.removeUserInfo(); // Removes the MS Signed-In user information in session storage
      await this.logoutPopup();
      return true;
    } catch (error) {
      logger.error("[MicrosoftService]: Error on logout ", error);
      throw error;
    }
  }

  private readonly fetchMicrosoftPressenceAPIURL = this.http.req((p: { orgId: string; token: string }) => ({
    url: `/org/${p?.orgId}/catalog?services=msft-presence-service&format=hostmap`,
    headers: {},
    method: "GET",
    err,
    res: {} as Service.Microsoft.U2CResponse
  }));

  public async presenceAPI() {
    const profileOrgId = SERVICE.conf?.profile?.orgId || "";
    if (this.msftPresenceServiceUrl === "") {
      const U2CResponse = await this.fetchMicrosoftPressenceAPIURL({ orgId: profileOrgId, token: this.accessToken });
      this.msftPresenceServiceUrl = U2CResponse.services[0].serviceUrls[0].baseUrl;
    }
    const sessionData = JSON.parse(sessionStorage.getItem(MSFT_TOKEN) ?? "{}");
    if (sessionData && sessionData.refresh_token) {
      logger.info("[PresenceStateSync] : Session data successfully fetched");
      const httpPresence = new HttpReqs(this.msftPresenceServiceUrl, true);
      const postPresenceApi = httpPresence.req(
        (p: { accessToken: string; msftRefreshToken: string; msftToken: string }) => ({
          url: `/userMapping`,
          headers: {
            msftToken: p.msftToken,
            webexToken: p.accessToken,
            msftRefreshToken: p.msftRefreshToken
          },
          method: "POST",
          err,
          res: {}
        })
      );
      return postPresenceApi({
        accessToken: this.accessToken,
        msftRefreshToken: sessionData?.refresh_token,
        msftToken: sessionData?.access_token || ""
      });
    }
    logger.error("[PresenceStateSync] : Presence API error. Unable to get refresh token ");
    return Promise.reject("Error : Unable to get refresh token");
  }

  public getEventInstance = (): Service.Microsoft.EventInstance => {
    return this.onMessage;
  };
}

declare module "../../../index" {
  export namespace Service.Microsoft {
    type LoginRequest = {
      clientId?: string;
      scope: string;
      redirectUri: string;
      nonce?: string;
      state?: string;
      prompt?: string;
    };

    type ExchangeTokenResponse = {
      access_token: string;
      refresh_token: string;
      id_token: string;
      expires_in: string;
      scope: string;
      token_type: string;
    };

    type SetToken = Service.Microsoft.ExchangeTokenResponse & {
      accessTokenExpiryTime?: string;
    };

    type Token = Service.Microsoft.ExchangeTokenResponse & {
      accessTokenExpiryTime: string;
    };

    type EventMessage = { eventType: MicrosoftServiceEvents; payload?: any };
    type EventInstance = Signal.WithData<EventMessage>;

    type U2CResponse = {
      services: Service[];
      format: string;
    };

    type Service = {
      serviceName: string;
      logicalNames: string[];
      serviceUrls: ServiceURL[];
      internalServiceUrls: any[];
      ttl: number;
      id: string;
    };

    type ServiceURL = {
      baseUrl: string;
      priority: number;
    };
  }
}
