import { createClient } from "@webex/web-calling-sdk";
import { CallingClientConfig, ICallingClient } from "@webex/web-calling-sdk/dist/types/CallingClient/types";
import { SERVICE } from "../..";
import { logger } from "../../core/sdk";
import { WEB_CALLING } from "../constant";

export class WebCallingService {
  private callingClient: any;
  private webex: any;
  private webCall: any;
  private domainUrl = "";
  private agentId = "";
  private webCallingRegistered = false;

  public registerWebCalling(domainUrl: string, agentId: string) {
    if (!(window as any).webexService) {
      logger.error("[WebRtc]: event=webexNotFound | webex not available in window, error, agentId :", agentId);
      return;
    }
    if (this.webCallingRegistered) {
      logger.info("[WebRtc]: webCalling Already Registered");
      return;
    }

    this.domainUrl = domainUrl;
    this.agentId = agentId;
    this.webex = (window as any).webexService;

    (window as any).CreateClient = (webex: any, config?: CallingClientConfig): ICallingClient =>
      createClient(webex, config);
    if (this.webex.internal.device.registered && this.webex.internal.mercury.connected) {
      this.handleWebRtcDeviceRegister();
    } else {
      this.handleWebRtcRegister();
    }
  }

  public async deregisterWebCalling() {
    try {
      await this.callingClient?.deregister();
      this.webCallingRegistered = false;
      logger.info("[WebRtc]: event=callingClientDeregisterSuccess: webCalling deregistered! AgentId is:", this.agentId);
    } catch (error) {
      logger.error(
        `[WebRtc] : event=callingClientDeregisterFailed:  Error while deregistering webCalling, ${error}, AgentId is : ${this.agentId}`
      );
    }
  }

  public async getAudioTrack(audio: any): Promise<MediaStream | undefined> {
    try {
      const audioTrack: MediaStream = await this.callingClient?.mediaEngine.Media.createAudioTrack(audio);
      logger.info("[WebRtc] : event=getAudioTrackSuccess, agentId", this.agentId);
      return Promise.resolve(audioTrack);
    } catch (err) {
      logger.error("[WebRtc] : event=getAudioTrackFailed", err, "agentId :", this.agentId);
      return Promise.reject(err);
    }
  }

  public answerWebCall(localAudioTrack: any) {
    if (this.webCall) {
      logger.info(`[WebRtc]: WebCall Answering with localAudioTrack, ${localAudioTrack}`);
      this.webCall.answer({ localAudioTrack });
    } else {
      this.handleNoWebCall();
    }
  }

  public muteWebCall(localAudioTrack: any) {
    if (this.webCall) {
      logger.info("[WebRtc]: WebCall mute|unmute requesting!");
      this.webCall.mute(localAudioTrack);
    } else {
      this.handleNoWebCall();
    }
  }

  public isWebCallMuted() {
    if (this.webCall) {
      return this.webCall.isMuted();
    } else {
      this.handleNoWebCall();
      return false;
    }
  }

  public declineWebCall() {
    if (this.webCall) {
      logger.info("[WebRtc]: WebCall end requesting!");
      this.webCall.end();
    } else {
      this.handleNoWebCall();
    }
  }

  private dispatchWebCallEvent(eventName: string, detail?: any) {
    window.dispatchEvent(
      new CustomEvent(eventName, {
        detail: { value: detail },
        bubbles: true,
        composed: true
      })
    );
  }

  private handleNoWebCall() {
    logger.info("[WebRtc]: WebCall not found!");
    this.dispatchWebCallEvent(WEB_CALLING.AX_WC_NOT_FOUND);
  }

  private handleWebRtcDeviceRegister() {
    if (!(window as any).CreateClient) {
      logger.error(
        "[WebRtc]: event=webexCallingNotFound | CreateClient not available in window, error, agentId",
        this.agentId
      );
      return;
    }
    const sdkConfig: any = {
      serviceData: {
        indicator: WEB_CALLING.INDICATOR_CONTACT_CENTER,
        domain: this.domainUrl
      }
    };

    if (SERVICE.featureflag.isSDKCallingInfoLevelLogsEnabled()) {
      sdkConfig.logger = {
        level: "info"
      };
    }
    logger.info("[WebRtc]: trying to register with webex-calling-sdk with domainUrl", this.domainUrl);
    this.callingClient = (window as any).callingClient = (window as any).CreateClient(this.webex, sdkConfig);

    this.callingClient.register();
    this.registerCallingEvents();
    this.createUpdateWebCall();
  }

  private registerCallingEvents() {
    this.callingClient.on(WEB_CALLING.CALLING_REGISTER_EVENT, (deviceInfo: any) => {
      const deviceRegistered =
        this.webex.internal?.device?.url !== ""
          ? `Registered, with deviceId: ${deviceInfo?.device?.deviceId}, uri : ${deviceInfo?.device?.uri} and clientDeviceUri : ${deviceInfo?.device?.clientDeviceUri}`
          : "Not Registered";
      logger.info(`[WebRtc]: event=CallingRegistration, agentId :${this.agentId}, device Info: ${deviceRegistered}`);
      this.webCallingRegistered = true;
      this.dispatchWebCallEvent(WEB_CALLING.AX_WC_DEVICE_REGISTERED, this.webCallingRegistered);
    });

    this.callingClient.on(WEB_CALLING.CALLING_UN_REGISTERED, () => {
      logger.info("[WebRtc]: device unregistered");
      this.webCallingRegistered = false;
      this.dispatchWebCallEvent(WEB_CALLING.AX_WC_DEVICE_REGISTERING);
    });

    this.callingClient.on(WEB_CALLING.CALLING_ERROR, (err: any) => {
      logger.error(
        "[WebRtc]: event=CallingClientRegistrationFailed : Device registration error",
        err,
        "agentId",
        this.agentId
      );
      this.webCallingRegistered = false;
      this.dispatchWebCallEvent(WEB_CALLING.AX_WC_DEVICE_DISCONNECTED, {
        mode: "client",
        errorDetails: JSON.stringify(err)
      });
    });
  }

  // Create/Update webCall Object.
  private createUpdateWebCall() {
    this.callingClient.on(WEB_CALLING.CALLING_INCOMING_EVENT, (callObj: any) => {
      this.webCall = callObj;
      logger.info(
        `[WebRtc]: event=WebrtcIncomingCall, agentId : ${this.agentId},  MessageType : ${callObj?.remoteRoapMessage?.messageType}, SDP : ${callObj?.remoteRoapMessage?.sdp}`
      );
      this.dispatchWebCallEvent(WEB_CALLING.AX_WC_INCOMING);

      // All webCall related Event listners.
      this.webCall.on(WEB_CALLING.CALL_DISCONNECT_EVENT, (correlationId: string) => {
        logger.info("[WebRtc]: Call Disconnected, correlationId:", correlationId);
        this.dispatchWebCallEvent("ax-web-call-disconnected", correlationId);
      });

      this.webCall.on(WEB_CALLING.CALL_ESTABLISHED_EVENT, (correlationId: string) => {
        logger.info("[WebRtc]: Call Established, correlationId:", correlationId);
        this.dispatchWebCallEvent(WEB_CALLING.AX_WC_ESTABLISHED, correlationId);
      });

      this.webCall.on(WEB_CALLING.CALL_REMOTE_MEDIA_UPDATE_EVENT, (track: any) => {
        logger.info("[WebRtc]: Call Remote Media Update");
        this.dispatchWebCallEvent(WEB_CALLING.AX_WC_REMOTE_MEDIA_UPDATE, track);
      });

      //on sendDTMF call
      this.webCall.on(WEB_CALLING.DTMF_TONE_CHANGED_EVENT, (digit: string) => {
        logger.info(`[WebRtc]: Make a DTMF call with digit ${digit}`);
      });
    });
  }

  private handleWebRtcRegister() {
    return this.webex.internal.device
      .register()
      .then(() => {
        logger.info(
          "[WebRtc]: event=WebexDeviceRegistrationSuccess : webex.internal.device.register successful , agentId",
          this.agentId
        );

        return this.webex.internal.mercury
          .connect()
          .then(() => {
            logger.info(
              "[WebRtc]: event=WebexMercuryConnectSuccessFull, webex.internal.mercury.connect successful, agentId",
              this.agentId
            );
            this.handleWebRtcDeviceRegister();
          })
          .catch((error: any) => {
            logger.error(
              "[WebRtc]: event=WebexMercuryConnectionFailed : Error occurred during mercury.connect()",
              error,
              "agentId",
              this.agentId
            );
            this.dispatchWebCallEvent(WEB_CALLING.AX_WC_DEVICE_DISCONNECTED, {
              mode: "device",
              errorDetails: JSON.stringify(error)
            });
          });
      })
      .catch((error: any) => {
        this.webCallingRegistered = false;
        logger.error(
          "[WebRtc]: event=WebexDeviceRegistrationFailed, Error occurred during webex.internal.device.register()",
          error,
          "agentId",
          this.agentId
        );
        this.webCallingRegistered = false;
        this.dispatchWebCallEvent(WEB_CALLING.AX_WC_DEVICE_DISCONNECTED, {
          mode: "device",
          errorDetails: JSON.stringify(error)
        });
      });
  }

  public sendDTMF(digit: number) {
    if (this.webCall) {
      this.webCall.sendDTMF(digit);
    } else {
      logger.error(`[WebRtc]: Call object is not defined`);
    }
  }
}
