import { PrometheusConstant } from "./constants";
import {
  getRegisterEvent,
  getTimeStampDifference,
  unifyEventNames,
  getGeneralizeLabels,
  submitIntoStorage
} from "./prometheus-service";
import { SERVICE } from "../..";

export interface ServiceInterface {
  event: string;
  timestamp: Date;
}

export interface RegisterInterface {
  timestamp: Date;
}

export interface CompleteInterface {
  timestamp: Date;
  interaction_id?: string;
  state?: string;
  old_state?: string;
  status: boolean;
  reason_code?: string;
  media_type?: string;
  media_channel?: string;
  error_code?: string;
}

export class PrometheusService {
  private _db: Array<ServiceInterface> = [];

  EVENT: ReturnType<typeof PrometheusConstant> = PrometheusConstant();

  init(): PrometheusService {
    return this;
  }
  startEvent(event: string, options: RegisterInterface) {
    const unifiedEventName = unifyEventNames(event);
    this._db.push({ event: unifiedEventName, timestamp: options.timestamp });
  }

  endEvent(event: string, options: CompleteInterface) {
    const unifiedEventName = unifyEventNames(event);
    const { updatedDb, registerEvent } = getRegisterEvent(this._db, unifiedEventName);
    if (registerEvent) {
      if (updatedDb) {
        this._db = updatedDb;
      }
      const duration = getTimeStampDifference(registerEvent?.timestamp, options.timestamp);
      const generalizeLabels = getGeneralizeLabels({
        org_id: SERVICE.conf.profile?.orgId,
        event: unifiedEventName,
        ...options,
        duration
      });
      submitIntoStorage(generalizeLabels);
    }
  }
}
