import { createHttp, createLogger } from "@uuip/unified-ui-platform-sdk";
const objKeysToBeMasked: Record<string, boolean> = {
  ani: true,
  dn: true,
  agent_ani: true,
  dnis: true,
  email: true,
  callAssociatedData: true,
  title: true,
  text: true,
  agentName: true,
  agentMailId: true,
  userName: true,
  emails: true,
  sipAddresses: true
};
export const http = createHttp();
export const logger = createLogger("agentx-services", objKeysToBeMasked);
