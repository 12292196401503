/* eslint-disable sonarjs/no-identical-functions */
import { Err } from "@uuip/unified-ui-platform-sdk";
import { AqmReqs, Msg } from "../../core/aqm-reqs";
import { HttpReqs } from "../../core/http-reqs";
import { Service } from "../../index";
import { createErrDetailsObject as err } from "../service-utils";
import Agent = Service.Aqm.Agent;
import Failure = Service.Aqm.Global.Failure;

/*
 * aqmAgent
 * @param reqs
 * @category AQM Service
 */
export function aqmAgent(aqm: AqmReqs, http: HttpReqs) {
  return {
    reload: aqm.reqEmpty(() => ({
      url: "/agentreload",
      data: {},
      err,
      notifSuccess: {
        bind: {
          type: "AgentReloginSuccess",
          data: { type: "AgentReloginSuccess" }
        },
        msg: {} as Agent.ReloginSuccess
      },
      notifFail: {
        bind: {
          type: "AgentReloginFailed",
          data: { type: "AgentReloginFailed" }
        },
        errId: "Service.aqm.agent.reload"
      }
    })),

    logout: aqm.req((p: { data: Agent.Logout }) => ({
      url: "/logout",
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "Logout",
          data: { type: "AgentLogoutSuccess" }
        },
        msg: {} as Agent.LogoutSuccess
      },
      notifFail: {
        bind: {
          type: "Logout",
          data: { type: "AgentLogoutFailed" }
        },
        errId: "Service.aqm.agent.logout"
      }
    })),

    stationLogin: aqm.req((p: { data: Agent.UserStationLogin }) => ({
      url: "/login",
      data: p.data,
      err: /* istanbul ignore next */ (e: any) =>
        new Err.Details("Service.aqm.agent.stationLogin", {
          status: e.response?.status ?? 0,
          type: e.response?.data?.errorType,
          trackingId: e.response?.headers?.trackingid?.split("_")[1]
        }),
      notifSuccess: {
        bind: {
          type: "StationLogin",
          data: { type: "AgentStationLoginSuccess" }
        },
        msg: {} as Agent.StationLoginSuccess
      },
      notifFail: {
        bind: {
          type: "StationLogin",
          data: { type: "AgentStationLoginFailed" }
        },
        errId: "Service.aqm.agent.stationLoginFailed"
      }
    })),

    stateChange: aqm.req((p: { data: Agent.StateChange }) => ({
      url: "/state",
      data: p.data,
      err,
      notifSuccess: {
        bind: {
          type: "AgentStateChange",
          data: { type: "AgentStateChangeSuccess" }
        },
        msg: {} as Agent.StateChangeSuccess
      },
      notifFail: {
        bind: {
          type: "AgentStateChange",
          data: { type: "AgentStateChangeFailed" }
        },
        errId: "Service.aqm.agent.stateChange"
      }
    })),

    mockOutdialAniList: aqm.req(() => ({
      url: "/mockOutdialAniList",
      err,
      notifSuccess: {
        bind: {
          type: "mockOutdialAniList"
        },
        msg: {} as Agent.OutdialAniListSuccess
      },
      notifFail: {
        bind: {
          type: "MockOutdialAniListFailed"
        },
        errId: "Service.aqm.agent.mockOutdialAniList"
      }
    })),

    fetchAddressBooks: http.req(() => ({
      url: `/querySpeedDialList`,
      errId: "Service.aqm.agent.fetchAddressBooks",
      err,
      res: {} as Agent.AddressBooks
    })),

    eMockOutdialAniList: aqm.evt({
      bind: {
        type: "mockOutdialAniList"
      },
      msg: {} as Agent.OutdialAniListSuccess
    }),

    eAgentDNRegistered: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentDNRegistered" }
      },
      msg: {} as Agent.DNRegistered
    }),

    eAgentDNRegisterFailure: aqm.evt({
      bind: {
        type: "RoutingMessage",
        data: { type: "AgentDNRegisterFailure" }
      },
      msg: {} as Failure
    }),

    eAgentMultiLogin: aqm.evt({
      bind: {
        type: "AGENT_MULTI_LOGIN",
        data: { type: "AgentMultiLoginCloseSession" }
      },
      msg: {} as Msg<{
        agentId: string;
        reason: string;
        type: "AgentMultiLoginCloseSession";
        agentSessionId: string;
      }>
    }),

    // jsapi required events
    eAgentReloginSuccess: aqm.evt({
      bind: {
        type: "AgentReloginSuccess",
        data: { type: "AgentReloginSuccess" }
      },
      msg: {} as Agent.ReloginSuccess
    }),
    eAgentStationLoginSuccess: aqm.evt({
      bind: {
        type: "StationLogin",
        data: { type: "AgentStationLoginSuccess" }
      },
      msg: {} as Agent.StationLoginSuccess
    }),
    eAgentStateChangeSuccess: aqm.evt({
      bind: {
        type: "AgentStateChange",
        data: { type: "AgentStateChangeSuccess" }
      },
      msg: {} as Agent.StateChangeSuccess
    }),
    eAgentLogoutSuccess: aqm.evt({
      bind: {
        type: "Logout",
        data: { type: "AgentLogoutSuccess" }
      },
      msg: {} as Agent.LogoutSuccess
    })
  };
}

type ErrDetails = { status: number; type: string; trackingId: string };
type AgentErrorIds =
  | { "Service.aqm.agent.stationLogin": ErrDetails }
  | { "Service.aqm.agent.stationLoginFailed": Failure }
  | { "Service.aqm.agent.stateChange": Failure }
  | { "Service.aqm.agent.reload": Failure }
  | { "Service.aqm.agent.logout": Failure }
  | { "Service.aqm.agent.mockOutdialAniList": Failure }
  | { "Service.reqs.generic.failure": { trackingId: string } }
  | "Service.aqm.agent.fetchAddressBooks";

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.aqm.agent": AgentErrorIds;
    }
  }
}

declare module "../../index" {
  export namespace Service.Aqm.Agent {
    type LogoutSuccess = Msg<{
      eventType: "AgentDesktopMessage";
      agentId: string;
      trackingId: string;
      agentSessionId: string;
      orgId: string;
      status: string;
      subStatus: string;
      loggedOutBy?: string;
      roles?: string[];
      type: "AgentLogoutSuccess";
    }>;
    type ReloginSuccess = Msg<{
      eventType: "AgentDesktopMessage";
      agentId: string;
      trackingId: string;
      auxCodeId: string;
      teamId: string;
      agentSessionId: string;
      dn: string;
      orgId: string;
      interactionIds: string[];
      isExtension: boolean;
      status: "LoggedIn";
      subStatus: "Idle";
      siteId: string;
      lastIdleCodeChangeTimestamp: number;
      lastStateChangeTimestamp: number;
      lastStateChangeReason?: string;
      profileType: string;
      channelsMap: Record<string, string[]>;
      dialNumber?: string;
      roles?: string[];
      deviceType?: DeviceType;
      deviceId?: string | null;
      isEmergencyModalAlreadyDisplayed?: boolean;
      type: "AgentReloginSuccess";
    }>;
    type StateChangeSuccess = Msg<{
      eventType: "AgentDesktopMessage";
      agentId: string;
      trackingId: string;
      auxCodeId: string;
      agentSessionId: string;
      orgId: string;
      status: string;
      subStatus: "Available" | "Idle";
      lastIdleCodeChangeTimestamp: number;
      lastStateChangeTimestamp: number;
      type: "AgentStateChangeSuccess";
      changedBy: string | null;
      changedById: string | null;
      changedByName: string | null;
      lastStateChangeReason: string;
    }>;
    type StationLoginSuccess = Msg<{
      eventType: "AgentDesktopMessage";
      agentId: string;
      trackingId: string;
      auxCodeId: string;
      teamId: string;
      agentSessionId: string;
      orgId: string;
      interactionIds: string[];
      status: string;
      subStatus: "Available" | "Idle";
      siteId: string;
      lastIdleCodeChangeTimestamp: number;
      lastStateChangeTimestamp: number;
      profileType: string;
      channelsMap: Record<string, string[]>;
      dialNumber?: string;
      roles?: string[];
      supervisorSessionId?: string;
      type: "AgentStationLoginSuccess";
    }>;
    type DNRegistered = Msg<{
      eventType: "RoutingMessage";
      agentId: string;
      trackingId: string;
      dn: string;
      orgId: string;
      type: "AgentDNRegistered";
    }>;
    type OutdialAniListSuccess = Msg<{
      data: Record<string, any>;
    }>;

    type OutdialAni = {
      id: string;
      name: string;
    };

    type OutDialAniData = {
      initialFetchCompleted: boolean;
      data: OutdialAni[];
    };

    // PAYLOAD

    type Logout = { logoutReason?: "User requested logout" | "Inactivity Logout" };

    type StateChange = {
      state: AgentState;
      auxCodeIdArray: string;
      lastStateChangeReason?: string;
      agentId?: string;
    };

    type AgentState = "Available" | "Idle" | "RONA" | string;

    type UserStationLogin = {
      dialNumber?: string | null;
      dn?: string | null;
      teamId: string | null;
      teamName: string | null;
      roles?: Array<string>;
      siteId: string;
      usesOtherDN: boolean;
      skillProfileId?: string;
      auxCodeId: string;
      isExtension?: boolean;
      deviceType?: DeviceType;
      deviceId?: string | null;
      isEmergencyModalAlreadyDisplayed?: boolean;
    };

    type AddressBooks = {
      totalRecords?: number;
      totalPages?: number;
      page?: number;
      speedDials: Address[];
    };

    type Address = {
      desc: string;
      dn: string;
      phoneBookName?: string;
    };

    type AddressBooksData = {
      initialFetchCompleted: boolean;
      data: Address[];
      errorObj: any;
    };

    type DeviceType = null | Service.Conf.LoginOption | string; // cleanup this while removing FF: wxcc_webrtc.
  }
}

// JS DOC Comments - Type Definitions

/**
 * @typedef {Object} Service_Aqm_Agent_StateChange
 * @summary Service.Aqm.Agent.StateChange
 * @property {Service_Aqm_Agent_AgentState} state State of the agent. Possible values are Available, Idle, and RONA.
 * @property {String} auxCodeIdArray Unique identifier for the global desktop state.
 */

/**
 * @typedef {String} Service_Aqm_Agent_AgentState
 * @summary Service.Aqm.Agent.AgentState
 * @description State of the agent. Possible values are Available, Idle, and RONA.
 */

/**
 * @typedef {Object} Service_Aqm_Agent_OutdialAni
 * @summary Service.Aqm.Agent.OutdialAni
 * @property {String} id Unique Identifier for the outdial ANI.
 * @property {String} name Name of the outdial ANI.
 */
