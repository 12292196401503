import { eventConstants } from "../mixpanel/constants";
import { PrometheusConstant } from "./constants";
import { ServiceInterface } from "./prometheus-tracking";

export const getTimeStampDifference = (startDate: Date, endDate: Date) => {
  return (endDate.getTime() - startDate.getTime()) / 1000;
};

export const getRegisterEvent = (_db: Array<ServiceInterface>, event: string) => {
  const registerEventIndex = _db.findIndex(a => a.event === event);
  if (registerEventIndex > -1) {
    const registerEvent = _db[registerEventIndex];
    _db.splice(registerEventIndex, 1);
    return { updatedDb: _db, registerEvent };
  } else {
    return { updatedDb: null, registerEvent: null };
  }
};

export const unifyEventNames = (event: string): string => {
  switch (event) {
    case eventConstants().AGENT_STATE_CHANGE: {
      return PrometheusConstant().AGENT_STATE_CHANGE;
    }
    case eventConstants().STATION_LOGIN: {
      return PrometheusConstant().AGENT_LOGIN;
    }
    case eventConstants().SIGN_OUT: {
      return PrometheusConstant().AGENT_LOGOUT;
    }
    case eventConstants().WEB_SOCKET_DISCONNECT: {
      return PrometheusConstant().WEB_SOCKET_DISCONNECT;
    }
    case eventConstants().CUSTOM_LAYOUT_LOAD_FAIL: {
      return PrometheusConstant().CUSTOM_LAYOUT_LOAD_FAIL;
    }
    case eventConstants().WEBRTC_REGISTRATION_FAILURE: {
      return PrometheusConstant().WEBRTC_REGISTRATION_FAILURE;
    }
    default: {
      return event;
    }
  }
};

/*
 * Generalize Label Object to label string for grok patten search.
 * Label: event
 * Label: org_id
 * Label: state
 * Label: old_state
 * Label: status
 * Label: reason_code
 * Label: duration
 * Label: media_type
 * Label: media_channel
 * Label: interaction_id
 */
export const getGeneralizeLabels = (options: Record<string, any>): string => {
  const labels = [
    "event",
    "org_id",
    "state",
    "old_state",
    "status",
    "reason_code",
    "duration",
    "media_type",
    "error_code",
    "media_channel"
  ];
  let labelString = "";
  for (const i in labels) {
    const label = labels[i];
    if (Number(i) > 0) {
      labelString += "|";
    }
    if (options[label] || options[label] === false) {
      labelString += ` ${label}=${options[label]} `;
    } else {
      labelString += ` ${label}=null `;
    }
  }
  labelString += `|`;
  return labelString.trim();
};

export const submitIntoStorage = (event: string) => {
  const existingStorage = localStorage.getItem("prometheus-logs") || "";
  let existingStorageParsed = [];
  try {
    existingStorageParsed = JSON.parse(existingStorage);
  } catch (e) {
    existingStorageParsed = [];
  }
  existingStorageParsed.push({ message: event, level: "info" });
  localStorage.setItem("prometheus-logs", JSON.stringify(existingStorageParsed));
};
