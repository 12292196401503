import { Service } from "../../index";

const SEPARATOR_CHAR = "_";
export const KEY_UP_EVENT = "keyup";
export const KEY_REGISTRATION_EVENT = "register";
export const KEY_EXECUTE_EVENT = "execute";
export const KEY_CONFLICT_EVENT = "conflict";
export const KEY_CONFLICT_RESOLVED = "conflict_resolved";

const EMAIL_COMPOSER_ELEMENT = "agentx-react-email-composer";
const AGENT_PROFILE_ELEMENT = "agentx-react-agent-profile";
const INTERACTION_CONTROL_ELEMENT = "agentx-react-interaction-control";

const EMAIL_COMPOSER_GROUP = "app:keyboardShortcutKeys.emailComposer.componentName";
const INTERACTION_CONTROL_GROUP = "app:keyboardShortcutKeys.interactionControl.componentName";
const INTERACTION_POPOVER_GROUP = "app:keyboardShortcutKeys.interactionPopover.componentName";
const AGENT_PROFILE_GROUP = "app:keyboardShortcutKeys.userProfile.componentName";

const agent = "agent";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const supervisor = "supervisor";
const all = "all";

export const validModifierKeysPressed = (keyEvent: KeyboardEvent) => {
  return (
    ((keyEvent.ctrlKey && keyEvent.shiftKey) ||
      (keyEvent.altKey && keyEvent.shiftKey) ||
      (keyEvent.ctrlKey && keyEvent.altKey) ||
      keyEvent.ctrlKey ||
      keyEvent.altKey ||
      keyEvent.shiftKey) &&
    keyEvent.key !== "Shift" &&
    keyEvent.key !== "Control" &&
    keyEvent.key !== "Alt"
  );
};

export const findMatchingKey = (
  shortcutKeyMap: Map<string, Service.shortcut.KeyInfo>,
  event?: KeyboardEvent
): Service.shortcut.KeyInfo | undefined => {
  const combiMatch = [];

  event!.ctrlKey && combiMatch.push("ctrlKey");
  event!.altKey && combiMatch.push("altKey");
  event!.shiftKey && combiMatch.push("shiftKey");

  const modifierKeyCombination = combiMatch.join(SEPARATOR_CHAR);

  let matchingKey: Service.shortcut.KeyInfo | undefined;
  shortcutKeyMap.forEach(shortcutEntry => {
    // sonar.issue.ignore.block.start
    if (
      shortcutEntry.modifierKeys === modifierKeyCombination &&
      shortcutEntry.key === String.fromCharCode(event!.keyCode).toLowerCase()
    ) {
      // sonar.issue.ignore.block.end
      matchingKey = shortcutEntry;
    }
  });
  return matchingKey;
};

export const hasConflict = (data: Service.shortcut.KeyInfo | undefined, value: Service.shortcut.KeyInfo) =>
  data?.key && data?.modifierKeys && value.key + value.modifierKeys === data?.key + data?.modifierKeys;

export const REGISTERED_KEYS = {
  EXPAND_COLLAPSE_INTERACTION_PANEL_KEY: "y",
  SAVE_EDITED_CAD_KEY: "m",
  REVERT_EDITED_CAD_KEY: "z",
  HOLD_RESUME_CALL_KEY: "v",
  TRANSFER_KEY: "x",
  CONSULT_KEY: "c",
  END_KEY: "e",
  CONFERENCE_KEY: "h",
  PAUSE_RESUME_RECORDING_KEY: "z",
  GO_TO_AVAILABLE_KEY: "r",
  OPEN_STATE_SELECTOR_KEY: "n", //used for mute/unmute in interaction control as well
  SEND_EMAIL_KEY: "s",
  REPLY_EMAIL_KEY: "6",
  REPLY_ALL_EMAIL_KEY: "5",
  OPEN_USER_PROFILE_KEY: "u",
  ENABLE_SILENT_NOTIFICATION_KEY: "d",
  OPEN_SHORTCUT_KEY_MODAL_KEY: "f",
  DOWNLOAD_ERROR_REPORT_KEY: "2",
  SIGNOUT_KEY: "l",
  ACCEPT_TASK_KEY: "a",
  SWITCH_POPOVER_KEY: "p",
  EXPAND_COLLAPSE_POPOVER_KEY: "9",
  OPEN_OUTDIAL_KEY: "o",
  OPEN_WRAP_UP_KEY: "w",
  EXPAND_COLLAPSE_TASK_LIST_PANEL_KEY: "7",
  OPEN_NOTIFICATION_CENTER_KEY: "i",
  OPEN_NAVIGATION_TAB_KEY: "t",
  REFRESH_KEY: "b",
  SWITCH_TASK_KEY: "8",
  ACCEPT_ALL_TASK_KEY: "4",
  EXPAND_COLLAPSE_WEBEX: "3",
  MINIMIZE_RESTORE_WEBEX: "1",
  OPEN_DTMF_KEYPAD: "k"
};

export const MODIFIERS = {
  CTRL_SHIFT: "ctrlKey_shiftKey",
  ALT_SHIFT: "altKey_shiftKey",
  CTRL_ALT: "ctrlKey_altKey",
  SHIFT: "shiftKey",
  CTRL: "ctrlKey",
  ALT: "altKey"
};
export const DEFAULT_SHORTCUT_KEYS: { [key: string]: Service.shortcut.KeyInfo[] } = {
  agentState: [
    {
      widgetElement: "agentx-react-state-selector",
      group: "app:keyboardShortcutKeys.agentState.componentName",
      action: "app:keyboardShortcutKeys.agentState.availableState",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.GO_TO_AVAILABLE_KEY,
      role: agent
    },
    {
      widgetElement: "agentx-react-state-selector",
      group: "app:keyboardShortcutKeys.agentState.componentName",
      action: "app:keyboardShortcutKeys.agentState.idleState",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_STATE_SELECTOR_KEY,
      role: agent
    }
  ],
  emailComposer: [
    {
      widgetElement: EMAIL_COMPOSER_ELEMENT,
      group: EMAIL_COMPOSER_GROUP,
      action: "app:keyboardShortcutKeys.emailComposer.sendEmail",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.SEND_EMAIL_KEY,
      role: agent
    },
    {
      widgetElement: EMAIL_COMPOSER_ELEMENT,
      group: EMAIL_COMPOSER_GROUP,
      action: "app:keyboardShortcutKeys.emailComposer.reply",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.REPLY_EMAIL_KEY,
      role: agent
    },
    {
      widgetElement: EMAIL_COMPOSER_ELEMENT,
      group: EMAIL_COMPOSER_GROUP,
      action: "app:keyboardShortcutKeys.emailComposer.replyAll",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.REPLY_ALL_EMAIL_KEY,
      role: agent
    }
  ],
  outDial: [
    {
      widgetElement: "agentx-react-out-dial",
      group: "app:keyboardShortcutKeys.outDial.componentName",
      action: "app:keyboardShortcutKeys.outDial.outboundCall",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_OUTDIAL_KEY,
      role: agent
    }
  ],
  notification: [
    {
      widgetElement: "agentx-wc-menu-notification",
      group: "app:keyboardShortcutKeys.notification.componentName",
      action: "app:keyboardShortcutKeys.notification.notificationCenter",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_NOTIFICATION_CENTER_KEY,
      role: all
    },
    {
      widgetElement: "agentx-wc-menu-notification",
      group: "app:keyboardShortcutKeys.notification.componentName",
      action: "app:keyboardShortcutKeys.notification.silentNotification",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.ENABLE_SILENT_NOTIFICATION_KEY,
      role: all
    }
  ],
  interactionPopover: [
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_POPOVER_GROUP,
      action: "app:keyboardShortcutKeys.interactionPopover.acceptPopover",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.ACCEPT_TASK_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_POPOVER_GROUP,
      action: "app:keyboardShortcutKeys.interactionPopover.switchPopover",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.SWITCH_POPOVER_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_POPOVER_GROUP,
      action: "app:keyboardShortcutKeys.interactionPopover.expandPopover",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.EXPAND_COLLAPSE_POPOVER_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_POPOVER_GROUP,
      action: "app:keyboardShortcutKeys.interactionPopover.acceptAll",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.ACCEPT_ALL_TASK_KEY,
      role: all
    }
  ],
  interactionControl: [
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.holdOrResumeCall",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.HOLD_RESUME_CALL_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.pauseOrResumeRecording",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.PAUSE_RESUME_RECORDING_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.conferenceRequest",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.CONFERENCE_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.consultRequest",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.CONSULT_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.endAllChannel",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.END_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.transferRequest",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.TRANSFER_KEY,
      role: agent
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.saveEditedCADVariables",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.SAVE_EDITED_CAD_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.revertEditedCADVariables",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.REVERT_EDITED_CAD_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.expandOrCollapse",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.EXPAND_COLLAPSE_INTERACTION_PANEL_KEY,
      role: all
    },
    {
      widgetElement: INTERACTION_CONTROL_ELEMENT,
      group: INTERACTION_CONTROL_GROUP,
      action: "app:keyboardShortcutKeys.interactionControl.wrapUpReason",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_WRAP_UP_KEY,
      role: agent
    }
  ],
  userProfile: [
    {
      widgetElement: AGENT_PROFILE_ELEMENT,
      group: AGENT_PROFILE_GROUP,
      action: "app:keyboardShortcutKeys.userProfile.openUserProfile",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_USER_PROFILE_KEY,
      role: all
    },
    {
      widgetElement: AGENT_PROFILE_ELEMENT,
      group: AGENT_PROFILE_GROUP,
      action: "app:keyboardShortcutKeys.userProfile.signout",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.SIGNOUT_KEY,
      role: all
    },
    {
      widgetElement: AGENT_PROFILE_ELEMENT,
      group: AGENT_PROFILE_GROUP,
      action: "app:keyboardShortcutKeys.userProfile.openKeyboardShortcutList",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_SHORTCUT_KEY_MODAL_KEY,
      role: all
    },
    {
      widgetElement: AGENT_PROFILE_ELEMENT,
      group: AGENT_PROFILE_GROUP,
      action: "app:keyboardShortcutKeys.userProfile.downloadErrorReport",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.DOWNLOAD_ERROR_REPORT_KEY,
      role: all
    }
  ],
  taskList: [
    {
      widgetElement: "agentx-wc-task-list",
      group: "app:keyboardShortcutKeys.taskList.componentName",
      action: "app:keyboardShortcutKeys.taskList.switchBetweenActiveTask",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.SWITCH_TASK_KEY,
      role: all
    },
    {
      widgetElement: "agentx-wc-task-list",
      group: "app:keyboardShortcutKeys.taskList.componentName",
      action: "app:keyboardShortcutKeys.taskList.expandOrCollapseTaskPanel",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.EXPAND_COLLAPSE_TASK_LIST_PANEL_KEY,
      role: all
    }
  ],
  connectorView: [
    {
      widgetElement: "agentx-wc-connector",
      group: "app:keyboardShortcutKeys.conectorView.componentName",
      action: "app:keyboardShortcutKeys.conectorView.openNavigationTab",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.OPEN_NAVIGATION_TAB_KEY,
      role: all
    },
    {
      widgetElement: "agentx-wc-connector",
      group: "app:keyboardShortcutKeys.conectorView.componentName",
      action: "app:keyboardShortcutKeys.conectorView.refresh",
      modifierKeys: MODIFIERS.CTRL_ALT,
      key: REGISTERED_KEYS.REFRESH_KEY,
      role: all
    }
  ],
  headerActionItems: [
    {
      widgetElement: "webex-opener",
      group: "app:keyboardShortcutKeys.headerActionItems.componentName",
      action: "app:keyboardShortcutKeys.headerActionItems.openWebexApplication",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.EXPAND_COLLAPSE_WEBEX,
      role: all
    },
    {
      widgetElement: "webex-minimize",
      group: "app:keyboardShortcutKeys.headerActionItems.componentName",
      action: "app:keyboardShortcutKeys.headerActionItems.minimizeOrRestoreWebex",
      modifierKeys: MODIFIERS.CTRL_SHIFT,
      key: REGISTERED_KEYS.MINIMIZE_RESTORE_WEBEX,
      role: all
    }
  ]
};
