import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";

const privateFieldNames = [
  "emails",
  "email",
  "access_token",
  "userName",
  "AgentName",
  "token",
  "displayName",
  "nickName",
  "firstName",
  "lastName",
  "phoneNumbers",
  "text"
];
interface LogRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}
interface LogResponse {
  reqId: string;
  status?: number;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

const isNotAllowedURL = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const notAllowedURLs = ["secure", "/ab/api/cms"];
  return notAllowedURLs.some(notAllowedURL => url.toLowerCase().indexOf(notAllowedURL) !== -1);
};

export class LogRocketService {
  public init(authorizationKey: string, enableSanitization: boolean): void {
    const { responseSanitizer, requestSanitizer }: any = LogrocketFuzzySanitizer.setup(privateFieldNames);
    LogRocket.init(authorizationKey, {
      dom: {
        inputSanitizer: enableSanitization,
        textSanitizer: enableSanitization
      },
      network: {
        requestSanitizer: (request: LogRequest): LogRequest | null => {
          if (request?.headers["Authorization"]) {
            request.headers["Authorization"] = "*";
          }
          if (request?.headers["authorization"]) {
            request.headers["authorization"] = "*";
          }
          if (isNotAllowedURL(request.url)) {
            request.body = "*";
          }
          return requestSanitizer(request);
        },
        responseSanitizer: (response: LogResponse): LogResponse | null => {
          if (isNotAllowedURL(response.url)) {
            response.body = "*";
            return response;
          } else {
            return responseSanitizer(response);
          }
        }
      }
    });
  }
  public identify(id: string, associatedData: any): void {
    LogRocket.identify(id, associatedData);
  }
}
