import { AgentxServicesConfig, setAgentxServicesConfig, CONF } from "./config";
import { http } from "./core/sdk";
import { FeatureFlagService } from "./services/featureFlag/feature-flag-service";
import { LogRocketService } from "./services/logrocket/log-rocket-service";
import { MixPanelService } from "./services/mixpanel/mix-panel-service";
import { PrometheusService } from "./services/prometheus/prometheus-tracking";
import { AgentxServices } from "./services/services";
import { TelemetryService } from "./services/telemetry/telemetry-service";
export { AgentxServices };
export { RTDTypes } from "./services/rtd-types/rtd-types";
export { CMSTypes } from "./services/cms/cms-types";

/**
 * Through SERVICE a developer is able to acess all AQM calls and subscribe to applicable Notifs responses.
 * Currently includes: `aqm` service
 */
export const SERVICE: AgentxService = new AgentxServices();
(SERVICE as AgentxServices).featureflag = new FeatureFlagService();
(SERVICE as AgentxServices).mixpanel = new MixPanelService();
(SERVICE as AgentxServices).prometheus = new PrometheusService();
(SERVICE as AgentxServices).telemetry = new TelemetryService();
(SERVICE as AgentxServices).logrocket = new LogRocketService();

/**
 * Service namespace allows a developer to access typings for AQM requests, Notifs responses, as well as payload parameters.
 */
export namespace Service {
  // augmented from other files
}

// conceal 'init' and make readonly
export type AgentxService = Omit<{ readonly [k in keyof AgentxServices]: AgentxServices[k] }, "init">;
export async function initAgentxServices(config: AgentxServicesConfig, accessToken: string) {
  setAgentxServicesConfig(config);
  await (SERVICE as AgentxServices).init(accessToken);
}

export function updateAccessToken(accessToken: string) {
  http.accessToken = accessToken;
}

/**
 * Return Tracking ID from Error Object
 * @param errorObj
 * @returns Tracking ID
 */
export const getTrackingIdFromErrorObject = (errorObj: any) => {
  if (errorObj?.id === "Service.aqm.reqs.Timeout") {
    // Fetching Tracking ID from success response header if timeout
    return errorObj?.details?.resAxios?.headers?.trackingid?.split("_")[1];
  }
  return errorObj?.details && errorObj.details.trackingId?.split("_")[1];
};

/**
 * Return Masked string with PII removed
 * @param value
 * @returns Masked string
 */
export const maskSensitiveData = (value: string): string => {
  if (!value) {
    return value;
  }
  const emailRegEx = /([\p{L}\p{N}])([\p{L}\p{N}._%+-]*)(@[\p{L}\p{N}.-]+\.[\p{L}]{2,})/gu;
  if (emailRegEx.test(value)) {
    return value.replace(emailRegEx, (match, p1, p2, p3) => {
      return p1 + "*".repeat(p2.length) + p3;
    });
  }
  const phoneNumberRegEx = /^[\p{N}+()\s-]{4,}$/u;
  if (phoneNumberRegEx.test(value)) {
    return value.replace(/.(?=.{4})/gu, "*");
  }
  return value;
};

/**
 * Gets rolename and forms it as per required display on Mixpanel
 * @param roleName
 * @returns
 */
export const formRoleNameForTelemetry = (roleName: string) => {
  const roleNameArray = roleName.split("_");
  return [...roleNameArray].reverse().join("_");
};

export const getRoutingHost = () => {
  return SERVICE.featureflag.isDesktopAqmEolCX11920Enabled() ? `${CONF.API_GATEWAY}/v1` : CONF.DIALER_HOST;
};
