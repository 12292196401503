import { SplitFactory } from "@splitsoftware/splitio";
import { logger } from "../../core/sdk";

const FEATURE_ON = "on";
const FEATURE_FLAGS = {
  CARE_ATTACHMENT: "care_attachment",
  CARE_DIGITAL_V2: "care_digital_v2",
  WCC_DIGITAL_V3: "wcc_digital_v3",
  CARE_VIDEO_ESCALATE: "care_video_escalate",
  CLOUD_CHERRY_STATS: "cloud_cherry_stats",
  WCC_PCI_COMPLIANCE: "wcc_pci_compliance",
  KAD_ADMIN_PORTAL: "KAD_admin_portal",
  IMI_CHAT_WIDGET: "wcc-imi-digital" /* This FF is commonly used in KAD and Portal as well to controll IMI features */,
  STEERING_DIGITS_APPEND: "steering_digits_append",
  DESKTOP_TAHOE_ENABLED: "wxcc-desktop-tahoe-enabled",
  SUPERVISOR_DESKTOP_PHASE1: "supervisor-desktop-phase1",
  ESD_STATION_LOGIN_FLOW_ENABLED: "ESD-phase1-station-login-flow",
  SECURE_VARIABLES_ENABLED: "flow-control-secure-variables",
  CMS_CONFIG_MIGRATED: "desktop-cms-config-migrated",
  OUTDIAL_ALLOW_SPECIAL_CHARS: "desktop-outdial-allow-special-chars",
  CANARY_ORG: "wxcc_canary_org",
  DESKTOP_CONSUME_WELCOME: "desktop-consume-welcome",
  DESKTOP_NOTES_WIDGET: "desktop-notes-widget",
  DESKTOP_LOGROCKET: "desktop-logrocket",
  DESKTOP_DEFAULT_LANDINGPAGE: "desktop-default-landingpage",
  DESKTOP_CONTACT_CLEANUP: "desktop-contact-cleanup",
  DESKTOP_LOGROCKET_DISABLE_TEXT_MASKING: "desktop-logrocket-disable-text-masking",
  DESKTOP_INTERNATIONAL_DIALLING: "desktop-international-dialling",
  DESKTOP_INVITATION_POPOVER: "desktop-invitation-popover",
  DESKTOP_CONFIGURABLE_TASK_VARIABLES: "desktop-configurable-task-variables",
  IMI_GLOBAL_VARIABLES: "global_variables_imi",
  GLOBAL_VARIABLES: "global_variables",
  DESKTOP_CONSULT_TO_ENTRY_POINT_DN: "wxcc_consult_to_entry_point_dn" /* This FF is used to control EP-DN features */,
  DESKTOP_EP_BLINDTRANSFER: "desktop-ep-blindtransfer",
  NETWORK_CHECK_API: "desktop-network-check-api",
  ESD_PHASE1_MID_CALL_MONITORING: "ESD-phase1-mid-call-monitoring",
  DESKTOP_HEADER_RESPONSIVENESS: "desktop-header-responsiveness",
  ESD_PHASE1_MONITORING_HOLD_UNHOLD: "ESD-phase1-monitoring-hold-unhold",
  DESKTOP_WEB_RTC: "desktop_web_rtc",
  ENABLE_ADD_ONS: "enableAddons",
  DESKTOP_MAX_SESSION_LIMITS: "wxcc-tm-conf-limits",
  ESD_AID_FIXED_VARIABLES: "ESD-aid-fixed-variables",
  WXCC_CALLBACK_RETRY: "wxcc_callback_retry",
  WXCC_WEBRTC: "wxcc_webrtc",
  PROGRESSIVE_CAMPAIGN: "WXCC_PROGRESSIVE_DIALER",
  DESKTOP_REASON_CODE: "wxcc_reasonCode_consistency",
  DESKTOP_STATE_CHANGE_BY_SUPERVISOR: "desktop-change-state-by-supervisor",
  DESKTOP_MAERSK_ENHANCEMENT: "desktop-maersk-CX-11355",
  WXCC_DESKTOP_VIEW_IN_CPD: "WXCC_DESKTOP_VIEW_IN_CPD",
  DESKTOP_MAERSK_ENHANCEMENT_ACQUEON: "desktop-maersk-CX-11865",
  DESKTOP_PROJECTION_OUTDIAL_ANI: "desktop-projection-outdial-ani",
  DESKTOP_BARGE_IN_BY_SUPERVISOR: "desktop-barge-in-by-supervisor",
  DESKTOP_PROGRESSIVE_CAMPAIGN_N: "WxCC_progressive__N",
  ESD_POST_INTERACTION_INSIGHTS: "ESD-post-interaction-insights",
  ADDRESSBOOK_INFINITE_SCROLL: "addressbook-infinite-scroll",
  SDK_CALLING_INFO_LEVEL_LOGS: "sdk-calling-info-level-logs",
  DESKTOP_MSFT_STATE_SYNC: "desktop-msft-State-sync",
  DESKTOP_AUX_CODES_INFINITE_SCROLL: "desktop-aux-codes-infinite-scrolling",
  DESKTOP_WEBEX_STATE_SYNC: "desktop-webex-State-sync",
  DESKTOP_EMERGENCY_NOTIFICATION: "wxcc_e911",
  DESKTOP_AQM_EOL: "desktop-aqm-eol-CX-11764",
  WEBRTC_RINGING: "CX-13578-webrtc-ringtone",
  WEBRTC_ENDCALL: "CX-13579-webrtc-endcall",
  WXCC_QRM_API_CONFERENCE: "dektop-aqm-eol-CX-11782",
  WXCC_QRM_API_AGENT_LIST: "dektop-aqm-eol-CX-11781",
  WXCC_QRM_API_TRANSFER: "dektop-aqm-eol-CX-11762",
  DESKTOP_AQM_EOL_CONSULT_TRANSFER: "dektop-aqm-eol-CX-13722",
  DESKTOP_AQM_EOL_END_WRAPUP: "desktop-aqm-eol-CX-11761",
  DESKTOP_AQM_EOL_ACCEPT: "desktop-aqm-eol-CX-11562",
  WXCC_MULTI_PARTY_CONF: "wxcc-multi-party-conf",
  DESKTOP_AQM_EOL_ADDRESSBOOK_MOCKOUTDIALANI: "desktop-aqm-eol-CX-11771",
  DESKTOP_AQM_EOL_PAUSE_RESUME: "desktop-aqm-eol-CX-11765",
  DESKTOP_AQM_EOL_STATE_CHANGE: "desktop-aqm-eol-CX-14232",
  DESKTOP_AQM_EOL_HOST: "dektop-aqm-eol-CX-11920",
  DESKTOP_ENABLE_CONF_RECORDING: "desktop-enable-conference-recording",
  WXCC_PERSIST_CALL: "wxcc_persist_call",
  QMW_SHOW_CONSULT_RECORDINGS: "show_consult_recordings",
  SHOW_SIGNED_OUT_AGENTS: "show_signed_out_agents",
  DESKTOP_WXCC_AGENT_INTERACTION: "desktop_wxcc_agent_interaction",
  DESKTOP_WXCC_MOMENTUM_V2: "desktop_wxcc_momentum_v2",
  WXCC_ALLOW_OUTDIAL_CALL_ALWAYS: "wxcc_allow_outdial_call_always",
  DESKTOP_WEBEX_DIRECTORY_VIEW: "desktop-webex-directory-view",
  DESKTOP_MASK_SENSITIVE_DATA: "wxcc-security-sensitive-data"
};

const SECONDS_30 = 30;
export class FeatureFlagService {
  private splitio!: SplitIO.ISDK;
  private splitClient!: SplitIO.IClient;
  readonly orgId!: string;

  public async initSplitioSdk(orgId: string, authorizationKey = ""): Promise<SplitIO.IClient> {
    if (authorizationKey) {
      logger.info("Fetched SplitIO Key Successfully");
    } else {
      logger.error("SplitIO Key Fetch failed");
    }
    (this as any).orgId = orgId;
    this.splitio = SplitFactory({
      core: {
        authorizationKey,
        key: orgId
      },
      scheduler: {
        metricsRefreshRate: SECONDS_30,
        impressionsRefreshRate: SECONDS_30,
        eventsPushRate: SECONDS_30
      },
      sync: {
        splitFilters: [
          {
            type: "byName",
            values: Object.values(FEATURE_FLAGS)
          }
        ]
      }
    });

    this.splitClient = this.splitio.client();

    return new Promise((resolve, reject) => {
      this.splitClient &&
        this.splitClient.on(this.splitClient.Event.SDK_READY, () => {
          logger.info("[FeatureFlagService] Split IO SDK is ready");
          return resolve(this.splitClient);
        });

      this.splitClient &&
        this.splitClient.on(this.splitClient.Event.SDK_READY_TIMED_OUT, () => {
          logger.error("[FeatureFlagService] Split IO Failed");
          return reject(this.splitClient);
        });
    });
  }

  public isVideoEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.CARE_VIDEO_ESCALATE,
      this.splitClient.getTreatment(FEATURE_FLAGS.CARE_VIDEO_ESCALATE)
    );
  }
  public isCareAttachmentEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.CARE_ATTACHMENT,
      this.splitClient.getTreatment(FEATURE_FLAGS.CARE_ATTACHMENT)
    );
  }
  public isCloudCherryEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.CLOUD_CHERRY_STATS,
      this.splitClient.getTreatment(FEATURE_FLAGS.CLOUD_CHERRY_STATS)
    );
  }
  public isDigitalFFEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WCC_DIGITAL_V3,
      this.splitClient.getTreatment(FEATURE_FLAGS.WCC_DIGITAL_V3)
    );
  }
  public isPCIComplianceEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WCC_PCI_COMPLIANCE,
      this.splitClient.getTreatment(FEATURE_FLAGS.WCC_PCI_COMPLIANCE)
    );
  }
  public isDynamicDesktopLayoutEnable(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.KAD_ADMIN_PORTAL,
      this.splitClient.getTreatment(FEATURE_FLAGS.KAD_ADMIN_PORTAL)
    );
  }

  public isDesktopReasonCodeEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_REASON_CODE,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_REASON_CODE)
    );
  }

  public isIMIChatEnabled(): boolean {
    const treatment = this.splitClient.getTreatment(FEATURE_FLAGS.IMI_CHAT_WIDGET);
    logger.info(`${FEATURE_FLAGS.IMI_CHAT_WIDGET} feature flag is : ${treatment}`);
    /**
     * `treatment` can be one of: NATIVE / IMI / MIXED_MODE
     * IMI FF will be treated as false only when `treatment` is NATIVE
     */
    return ["IMI", "MIXED_MODE"].includes(treatment);
  }

  public isNativeChat(): boolean {
    const treatment = this.splitClient.getTreatment(FEATURE_FLAGS.IMI_CHAT_WIDGET);
    logger.info(`${FEATURE_FLAGS.IMI_CHAT_WIDGET} feature flag is : ${treatment}`);
    return ["NATIVE", "MIXED_MODE"].includes(treatment);
  }

  public isSteeringDigitsAppendEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.STEERING_DIGITS_APPEND,
      this.splitClient.getTreatment(FEATURE_FLAGS.STEERING_DIGITS_APPEND)
    );
  }

  public isTahoeEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_TAHOE_ENABLED,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_TAHOE_ENABLED)
    );
  }

  public isSupervisorDesktopEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.SUPERVISOR_DESKTOP_PHASE1,
      this.splitClient?.getTreatment(FEATURE_FLAGS.SUPERVISOR_DESKTOP_PHASE1)
    );
  }

  public isESDStationLoginFlowEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ESD_STATION_LOGIN_FLOW_ENABLED,
      this.splitClient?.getTreatment(FEATURE_FLAGS.ESD_STATION_LOGIN_FLOW_ENABLED)
    );
  }

  public isDefaultLandingpageEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_DEFAULT_LANDINGPAGE,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_DEFAULT_LANDINGPAGE)
    );
  }
  public isSecureCADVariablesEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.SECURE_VARIABLES_ENABLED,
      this.splitClient.getTreatment(FEATURE_FLAGS.SECURE_VARIABLES_ENABLED)
    );
  }

  public isCMSConfigMigrated(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.CMS_CONFIG_MIGRATED,
      this.splitClient.getTreatment(FEATURE_FLAGS.CMS_CONFIG_MIGRATED)
    );
  }

  public isDesktopNotesWidgetEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_NOTES_WIDGET,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_NOTES_WIDGET)
    );
  }

  public isAdvancedHeaderEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_HEADER_RESPONSIVENESS,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_HEADER_RESPONSIVENESS)
    );
  }

  public isDesktopContactCleanup(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_CONTACT_CLEANUP,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_CONTACT_CLEANUP)
    );
  }

  public isInternationalDiallingEnabled(): boolean {
    // International Checkbox UI and Preferences will be turned on/off based on this FF, only for non-ESD flow
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_INTERNATIONAL_DIALLING,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_INTERNATIONAL_DIALLING)
    );
  }

  public isInvitationPopoverEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_INVITATION_POPOVER,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_INVITATION_POPOVER)
    );
  }

  public isConfigurableTaskVariablesEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_CONFIGURABLE_TASK_VARIABLES,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_CONFIGURABLE_TASK_VARIABLES)
    );
  }

  private isFeatureEnabled(flag: string, treatment: SplitIO.Treatment): boolean {
    logger.info(`${flag} feature flag is : ${treatment}`);
    return treatment === FEATURE_ON;
  }
  public isOutdialSpecialCharsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.OUTDIAL_ALLOW_SPECIAL_CHARS,
      this.splitClient.getTreatment(FEATURE_FLAGS.OUTDIAL_ALLOW_SPECIAL_CHARS)
    );
  }

  public isDesktopConsumeWelcomeEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_CONSUME_WELCOME,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_CONSUME_WELCOME)
    );
  }

  public isCanaryOrg(): boolean {
    return this.isFeatureEnabled(FEATURE_FLAGS.CANARY_ORG, this.splitClient.getTreatment(FEATURE_FLAGS.CANARY_ORG));
  }

  public isLogRocketEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_LOGROCKET,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_LOGROCKET)
    );
  }

  public isLogRocketMaskingDisabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_LOGROCKET_DISABLE_TEXT_MASKING,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_LOGROCKET_DISABLE_TEXT_MASKING)
    );
  }

  public isIMIGlobalVariablesEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.IMI_GLOBAL_VARIABLES,
      this.splitClient.getTreatment(FEATURE_FLAGS.IMI_GLOBAL_VARIABLES)
    );
  }

  public isGlobalVariablesEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.GLOBAL_VARIABLES,
      this.splitClient.getTreatment(FEATURE_FLAGS.GLOBAL_VARIABLES)
    );
  }

  public isDesktopConsultToEntryPointDnEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_CONSULT_TO_ENTRY_POINT_DN,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_CONSULT_TO_ENTRY_POINT_DN)
    );
  }

  public isDesktopEOLConsultConferenceEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_QRM_API_CONFERENCE,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_QRM_API_CONFERENCE)
    );
  }

  public isNetworkCheckPingAPIEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.NETWORK_CHECK_API,
      this.splitClient?.getTreatment(FEATURE_FLAGS.NETWORK_CHECK_API)
    );
  }

  public isMidCallMonitoringEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ESD_PHASE1_MID_CALL_MONITORING,
      this.splitClient.getTreatment(FEATURE_FLAGS.ESD_PHASE1_MID_CALL_MONITORING)
    );
  }
  public isMonitoringHoldUnHoldEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ESD_PHASE1_MONITORING_HOLD_UNHOLD,
      this.splitClient.getTreatment(FEATURE_FLAGS.ESD_PHASE1_MONITORING_HOLD_UNHOLD)
    );
  }
  public isDesktopAddOnsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ENABLE_ADD_ONS,
      this.splitClient.getTreatment(FEATURE_FLAGS.ENABLE_ADD_ONS)
    );
  }
  public isDesktopMaxSessionLimitsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_MAX_SESSION_LIMITS,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_MAX_SESSION_LIMITS)
    );
  }
  public isAIDFixedVariablesEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ESD_AID_FIXED_VARIABLES,
      this.splitClient.getTreatment(FEATURE_FLAGS.ESD_AID_FIXED_VARIABLES)
    );
  }
  public isCallbackRetryEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_CALLBACK_RETRY,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_CALLBACK_RETRY)
    );
  }
  public isWebRTCEnabled(): boolean {
    return this.isFeatureEnabled(FEATURE_FLAGS.WXCC_WEBRTC, this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_WEBRTC));
  }

  public isProgressiveCampaignEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.PROGRESSIVE_CAMPAIGN,
      this.splitClient.getTreatment(FEATURE_FLAGS.PROGRESSIVE_CAMPAIGN)
    );
  }
  public isProgressiveCampaign1NEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_PROGRESSIVE_CAMPAIGN_N,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_PROGRESSIVE_CAMPAIGN_N)
    );
  }
  public isStateChangeBySupervisorEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_STATE_CHANGE_BY_SUPERVISOR,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_STATE_CHANGE_BY_SUPERVISOR)
    );
  }
  public isDesktopMaerskEnhancementEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_MAERSK_ENHANCEMENT,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_MAERSK_ENHANCEMENT)
    );
  }
  public isESDPostInteractionInsightsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ESD_POST_INTERACTION_INSIGHTS,
      this.splitClient.getTreatment(FEATURE_FLAGS.ESD_POST_INTERACTION_INSIGHTS)
    );
  }
  public isDesktopMaerskAcqueonTeamId(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_MAERSK_ENHANCEMENT_ACQUEON,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_MAERSK_ENHANCEMENT_ACQUEON)
    );
  }
  public isProjectionOutdialANIEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_PROJECTION_OUTDIAL_ANI,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_PROJECTION_OUTDIAL_ANI)
    );
  }
  public isDesktopBargeInEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_BARGE_IN_BY_SUPERVISOR,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_BARGE_IN_BY_SUPERVISOR)
    );
  }
  public isDesktopCpdViewEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_DESKTOP_VIEW_IN_CPD,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_DESKTOP_VIEW_IN_CPD)
    );
  }
  public isAddressBookInfiniteScrollEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.ADDRESSBOOK_INFINITE_SCROLL,
      this.splitClient.getTreatment(FEATURE_FLAGS.ADDRESSBOOK_INFINITE_SCROLL)
    );
  }

  public isSDKCallingInfoLevelLogsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.SDK_CALLING_INFO_LEVEL_LOGS,
      this.splitClient.getTreatment(FEATURE_FLAGS.SDK_CALLING_INFO_LEVEL_LOGS)
    );
  }
  public isDesktopMSStateSyncEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_MSFT_STATE_SYNC,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_MSFT_STATE_SYNC)
    );
  }
  public isDesktopAuxCodesInfiniteScrollEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AUX_CODES_INFINITE_SCROLL,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AUX_CODES_INFINITE_SCROLL)
    );
  }
  public isDesktopWebexStateSyncEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_WEBEX_STATE_SYNC,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_WEBEX_STATE_SYNC)
    );
  }
  public isDesktopEmergencyNotificationEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_EMERGENCY_NOTIFICATION,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_EMERGENCY_NOTIFICATION)
    );
  }
  public isDesktopAqmEolCX11764Enabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL)
    );
  }
  public isWebrtcRingingEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WEBRTC_RINGING,
      this.splitClient.getTreatment(FEATURE_FLAGS.WEBRTC_RINGING)
    );
  }
  public isDesktopQrmApiTransferEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_QRM_API_TRANSFER,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_QRM_API_TRANSFER)
    );
  }
  public isDesktopAqmEolConsultTransferEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_CONSULT_TRANSFER,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_CONSULT_TRANSFER)
    );
  }

  public isDesktopAqmEolCX11920Enabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_HOST,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_HOST)
    );
  }

  public isDesktopAqmEolCX11771Enabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_ADDRESSBOOK_MOCKOUTDIALANI,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_ADDRESSBOOK_MOCKOUTDIALANI)
    );
  }

  public isDesktopAqmEolStateChangeEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_STATE_CHANGE,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_STATE_CHANGE)
    );
  }

  public isDesktopAqmEolEndWrapupEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_END_WRAPUP,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_END_WRAPUP)
    );
  }
  public isDesktopAqmEolAcceptEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_ACCEPT,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_ACCEPT)
    );
  }
  public isWebrtcEndCallEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WEBRTC_ENDCALL,
      this.splitClient.getTreatment(FEATURE_FLAGS.WEBRTC_ENDCALL)
    );
  }

  public isWxccQrmApiAgentListEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_QRM_API_AGENT_LIST,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_QRM_API_AGENT_LIST)
    );
  }

  public isWxccMultiPartyConfEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_MULTI_PARTY_CONF,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_MULTI_PARTY_CONF)
    );
  }

  public isDesktopAqmEolPauseResumeEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_AQM_EOL_PAUSE_RESUME,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_AQM_EOL_PAUSE_RESUME)
    );
  }

  public isDesktopConferenceRecordingEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_ENABLE_CONF_RECORDING,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_ENABLE_CONF_RECORDING)
    );
  }

  public isWxccPersistCallEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_PERSIST_CALL,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_PERSIST_CALL)
    );
  }

  public isQMWShowConsultRecordingsEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.QMW_SHOW_CONSULT_RECORDINGS,
      this.splitClient.getTreatment(FEATURE_FLAGS.QMW_SHOW_CONSULT_RECORDINGS)
    );
  }

  public showSignedOutAgents(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.SHOW_SIGNED_OUT_AGENTS,
      this.splitClient.getTreatment(FEATURE_FLAGS.SHOW_SIGNED_OUT_AGENTS)
    );
  }

  public isWxccAgentInteractionEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_WXCC_AGENT_INTERACTION,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_WXCC_AGENT_INTERACTION)
    );
  }

  public isMomentumV2Enabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_WXCC_MOMENTUM_V2,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_WXCC_MOMENTUM_V2)
    );
  }

  public isWxccAllowOutdialCallAlwaysEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.WXCC_ALLOW_OUTDIAL_CALL_ALWAYS,
      this.splitClient.getTreatment(FEATURE_FLAGS.WXCC_ALLOW_OUTDIAL_CALL_ALWAYS)
    );
  }

  public isWebexDirectoryViewEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_WEBEX_DIRECTORY_VIEW,
      this.splitClient.getTreatment(FEATURE_FLAGS.DESKTOP_WEBEX_DIRECTORY_VIEW)
    );
  }

  public isMaskSensitiveDataFFEnabled(): boolean {
    return this.isFeatureEnabled(
      FEATURE_FLAGS.DESKTOP_MASK_SENSITIVE_DATA,
      this.splitClient?.getTreatment(FEATURE_FLAGS.DESKTOP_MASK_SENSITIVE_DATA)
    );
  }
}

declare module "@uuip/unified-ui-platform-sdk" {
  namespace Err {
    interface Ids {
      "Service.featureflag": "Service.featureflag.fetchFeatureFlags";
    }
  }
}
