/* eslint-disable @typescript-eslint/no-var-requires */
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { CONF } from "../../config";
import { logger } from "../../core/sdk";
import { cacheKeys, eventConstants, MIX_PANEL_CACHE, propsConstants } from "./constants";
import { trackingUtils } from "./mix-panel-tracking";

export class MixPanelService {
  private initialized = false;
  private superProps: any;
  EVENT: ReturnType<typeof eventConstants> = eventConstants();
  PROPS: ReturnType<typeof propsConstants> = propsConstants();
  CACHE_KEYS: ReturnType<typeof cacheKeys> = cacheKeys();
  util: ReturnType<typeof trackingUtils> = trackingUtils();
  init(token: string): MixPanelService {
    if (!this.initialized) {
      logger.info("[MixPanelService] Initializing mixpanel");
      sessionStorage.setItem(MIX_PANEL_CACHE, "{}");
      if (token) {
        mixpanel.init(token, { persistence: "localStorage" });
      }

      this.initialized = true;
      logger.info("[MixPanelService] mixpanel initialized");
    } else {
      logger.warn("[MixPanelService] mixpanel already initialized");
    }

    return this;
  }
  track(name: string, options?: Record<string, string | number | boolean>) {
    try {
      mixpanel.track(name, {
        ...this.superProps,
        ...options,
        BrowserWidth: window.innerWidth,
        BrowserHeight: window.innerHeight,
        DataCenter: this.getDataCenterDetails(),
        DarkModeEnabled: Cookies.get("darkMode") === "true",
        [this.PROPS.TASK_PANE_STATE]: Cookies.get("taskAreaCollapsed") === "true" ? "Minimized" : "Expanded",
        CurrentState: Cookies.get("agentState") === "Available" ? "Available" : "Idle",
        Status: options && options.Status ? options.Status : this.EVENT.STATUS.SUCCESS,
        userRole: localStorage.getItem("userRole"),
        desktopRunningInside: window.self !== window.top ? "Iframe" : "Window"
      });
    } catch (e) {
      logger.error(`[MixPanelService] Mixpanel tracking failed ${name}`, e);
    }
  }

  timeEvent(name: string) {
    mixpanel.time_event(name);
  }

  register(superProps: Record<string, any>) {
    try {
      this.superProps = { ...superProps };
    } catch (e) {
      logger.error("[MixPanelService] Mixpanel tracking failed", e);
    }
  }

  setPeople(options: Record<string, string | number>) {
    mixpanel.people.set(options);
  }

  setIdentity(agentId: string) {
    mixpanel.identify(agentId);
  }
  setGroup(name: string, groups: string[]) {
    mixpanel.set_group(name, groups);
  }

  setUserProfile(agentId: string, profile: Record<string, string>) {
    mixpanel.identify(agentId);
    mixpanel.people.set(profile);
  }

  optOutTracking(): void {
    mixpanel.opt_out_tracking();
  }

  optInTracking(): void {
    mixpanel.opt_in_tracking();
  }

  setOptOut(featureFlagOff: boolean) {
    if (featureFlagOff) {
      logger.info("Opting out mixpanel tracking");
      this.optOutTracking();
    } else {
      logger.info("Opting in mixpanel tracking");
      this.optInTracking();
    }
  }

  hasOptedOutTracking(): boolean {
    return mixpanel.has_opted_out_tracking();
  }

  getInstance(): any {
    if (!this.initialized) {
      logger.error("[MixPanelService] mixpanel not initialized. Initialize mixpanel before getting instance");
    }
    return mixpanel;
  }
  setValueInCache(key: string, value: string | number | boolean) {
    const str = sessionStorage.getItem(MIX_PANEL_CACHE)! || "{}";
    const obj = JSON.parse(str);
    obj[key] = value;
    sessionStorage.setItem(MIX_PANEL_CACHE, JSON.stringify(obj));
  }

  getValueFromCache(key: string): any {
    const str = sessionStorage ? sessionStorage.getItem(MIX_PANEL_CACHE)! : "";
    const obj = JSON.parse(str);
    return obj ? obj[key] : null;
  }

  getDataCenterDetails(): string {
    const dataCenter = CONF.CC_DC;
    if (dataCenter) {
      if (dataCenter?.includes("prod")) {
        return dataCenter.replace("prod", "");
      }
      return dataCenter;
    } else {
      return "";
    }
  }

  removeValueFromCache(key: string) {
    const str = sessionStorage.getItem(MIX_PANEL_CACHE)!;
    const obj = JSON.parse(str);
    obj && delete obj[key];
    sessionStorage.setItem(MIX_PANEL_CACHE, JSON.stringify(obj));
  }
  reset() {
    if (this.initialized) {
      mixpanel.reset();
    }
  }
}
