export const PrometheusConstant = () => {
  return {
    AGENT_LOGIN: "AGENT_LOGIN",
    AGENT_LOGOUT: "AGENT_LOGOUT",
    AGENT_STATE_CHANGE: "AGENT_STATE_CHANGE",
    WEB_SOCKET_DISCONNECT: "WEB_SOCKET_DISCONNECT",
    CUSTOM_LAYOUT_LOAD_FAIL: "CUSTOM_LAYOUT_LOAD_FAIL",
    WEBRTC_REGISTRATION_FAILURE: "WEBRTC_REGISTRATION_FAILURE"
  };
};
